import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Listing, User, ListingsService, ListingApplication, MediaContent } from 'src/app/swagger-generated';
import { SessionService } from 'src/app/services/session.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { getMediaContentUrl } from 'src/app/services/mediaContent.service';

function distance(lat1, lon1, lat2, lon2) {
  var R = 6371; // km (change this constant to get miles)
  var dLat = (lat2 - lat1) * Math.PI / 180;
  var dLon = (lon2 - lon1) * Math.PI / 180;
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  if (d > 1) return Math.round(d) + "km";
  else if (d <= 1) return Math.round(d * 1000) + "m";
  return d;
}

@Component({
  selector: 'app-listing-item',
  templateUrl: './listing-item.component.html',
  styleUrls: ['./listing-item.component.scss']
})
export class ListingItemComponent {
  @Input('listing') listing: Listing;
  @Input('showStatus') showStatus = true;
  @Input('hideDescription') hideDescription = false;
  @Input('noLink') noLink = false;
  @Input('redirectOnClick') redirectOnClick = true;
  @Input('lightVersion') lightDisplay = false;
  @Output() clickSpecialCommitment: EventEmitter<string> = new EventEmitter();
  @Output() clickListingTitle: EventEmitter<Listing> = new EventEmitter();

  currentUser: User;
  favorited: boolean;
  listingApplication: ListingApplication;

  distanceToListing;
  isLoadingFavorite;
  isLoadingApply;

  constructor(
    private listingsService: ListingsService,
    private sessionService: SessionService,
    private router: Router,
    private _matSnackBar: MatSnackBar,
    private _translateService: TranslateService
  ) { }

  ngOnInit() {
    this.sessionService.getCurrentUser().then(currentUser => {
      this.currentUser = currentUser;
      if (currentUser) {
        this.favorited = !!this.listing.usersFavorited.find(user => user.id === this.currentUser.id);
        if (this.listing.listingApplications) {
          this.listingApplication = this.listing.listingApplications.find(listingApplication => listingApplication.user.id === this.currentUser.id);
        }
      }

    }, err => {
      this.currentUser = null;
    });

    this.sessionService.getCurrentLocation().then(location => {
      if (location && this.listing.address && this.listing.address.geolocation) {
        const listingLocation = this.listing.address.geolocation;
        this.distanceToListing = distance(listingLocation.lat, listingLocation.lng, location.coords.latitude, location.coords.longitude);
      }
    });

  }

  onClickFavorite() {
    this.isLoadingFavorite = true;
    if (this.currentUser) {
      if (this.favorited) {
        this.listingsService.listingsIdFavoriteDelete(this.listing.id).subscribe(() => {
          this.favorited = false;
          this._matSnackBar.open(this._translateService.instant('favoriteRemoveConfirm'), null, { duration: 3000 });
          this.isLoadingFavorite = false;
        });
      } else {
        this.listingsService.listingsIdFavoritePost(this.listing.id).subscribe(() => {
          this.favorited = true;
          this._matSnackBar.open(this._translateService.instant('favoriteAddConfirm'), null, { duration: 3000 });
          this.isLoadingFavorite = false;
        });
      }
    } else {
      this.router.navigate(['login']);
    }
  }

  onClickApply() {
    this.isLoadingApply = true;
    if (this.currentUser) {
      if (this.listingApplication) {
        this.listingsService.listingApplicationsIdDelete(this.listingApplication.id).subscribe(() => {
          this.listingApplication = null;
          this._matSnackBar.open(this._translateService.instant('subscribeRemoveConfirm'), null, { duration: 3000 });
          this.isLoadingApply = false;
        });
      } else {
        const listingApplication: ListingApplication = <ListingApplication>{
          listing: this.listing,
          user: this.currentUser
        }
        this.listingsService.listingApplicationsPost(listingApplication).subscribe(listingApplicationResponse => {
          this.listingApplication = listingApplicationResponse;
          this._matSnackBar.open(this._translateService.instant('subscribeAddConfirm'), null, { duration: 3000 });
          this.isLoadingApply = false;
        });
      }

    } else {
      this.router.navigate(['login']);
    }
  }

  getMediaContentUrl(mediaContent: MediaContent, entityType: string, size?: string) {
    return getMediaContentUrl(mediaContent, entityType, size);
  }

  get description(): string {
    if (!this.listing.description) {
      return this.listing.owner.name + ' ' + this.listing.beds + ' bd';
    }
    return this.listing.description.replace(/<[^>]*>?/gm, '');
  }

  get title() {
    if (this.listing.title) {
      return this.listing.title;
    }
    return this.listing.owner.name + ' ' + this.listing.rooms + ' bdr'
  }

  onClickListingTitle() {
    if (this.redirectOnClick) {
      this.router.navigate([`listings/${this.listing.id}`]);
    } else {
      this.clickListingTitle.emit(this.listing);
    }
  }
}
