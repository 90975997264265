/**
 * Homi Server Endpoints
 * Available interactions with the Homi Backend services
 *
 * OpenAPI spec version: 0.0.17
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Company } from './company';
import { MediaContent } from './mediaContent';
import { ModelDate } from './modelDate';


export interface FloorPlan {
    id: string;
    createdAt: ModelDate;
    updatedAt: ModelDate;
    title: string;
    description: string;
    amenities: Array<FloorPlan.AmenitiesEnum>;
    baths: number;
    price: number;
    rooms: number;
    totalUnits: number;
    availableUnits: number;
    beds: number;
    applicationDeadline: ModelDate;
    available: ModelDate;
    floorPlanStatus: FloorPlan.FloorPlanStatusEnum;
    type: FloorPlan.TypeEnum;
    internalNotes: string;
    photos: Array<MediaContent>;
    company: Company;
}
export namespace FloorPlan {
    export type AmenitiesEnum = 'PETS' | 'PETS_SMALL' | 'PETS_LARGE' | 'PETS_DOGS' | 'SMOKING_AREA' | 'PARKING' | 'PARKING_GARAGE' | 'PARKING_STREET' | 'SWIMMING_POOL' | 'PUBLIC_TRASPORTATION_ACCESSIBLE' | 'LAUNDRY' | 'GYM' | 'FURNITURE';
    export const AmenitiesEnum = {
        PETS: 'PETS' as AmenitiesEnum,
        PETSSMALL: 'PETS_SMALL' as AmenitiesEnum,
        PETSLARGE: 'PETS_LARGE' as AmenitiesEnum,
        PETSDOGS: 'PETS_DOGS' as AmenitiesEnum,
        SMOKINGAREA: 'SMOKING_AREA' as AmenitiesEnum,
        PARKING: 'PARKING' as AmenitiesEnum,
        PARKINGGARAGE: 'PARKING_GARAGE' as AmenitiesEnum,
        PARKINGSTREET: 'PARKING_STREET' as AmenitiesEnum,
        SWIMMINGPOOL: 'SWIMMING_POOL' as AmenitiesEnum,
        PUBLICTRASPORTATIONACCESSIBLE: 'PUBLIC_TRASPORTATION_ACCESSIBLE' as AmenitiesEnum,
        LAUNDRY: 'LAUNDRY' as AmenitiesEnum,
        GYM: 'GYM' as AmenitiesEnum,
        FURNITURE: 'FURNITURE' as AmenitiesEnum
    }
    export type FloorPlanStatusEnum = 'AVAILABLE' | 'UNAVAILABLE';
    export const FloorPlanStatusEnum = {
        AVAILABLE: 'AVAILABLE' as FloorPlanStatusEnum,
        UNAVAILABLE: 'UNAVAILABLE' as FloorPlanStatusEnum
    }
    export type TypeEnum = 'APARTMENT' | 'STUDIO';
    export const TypeEnum = {
        APARTMENT: 'APARTMENT' as TypeEnum,
        STUDIO: 'STUDIO' as TypeEnum
    }
}
