import { Component, OnInit } from '@angular/core';
import { CompaniesService } from '../../swagger-generated';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-external-companies',
  templateUrl: './dashboard-external-companies.component.html',
  styleUrls: ['./dashboard-external-companies.component.scss']
})
export class DashboardExternalCompaniesComponent implements OnInit {
  externalCompanies;
  isLoadingSync = false;

  constructor(
    private _companiesService: CompaniesService,
    private _router: Router
  ) { }

  ngOnInit() {
    this._companiesService.companiesExternalGet().subscribe(data => {
      this.externalCompanies = data.sort((c1, c2) => {
        if (c1.homiCompany && c2.homiCompany) return 0;
        if (c1.homiCompany) return -1;
        if (c2.homiCompany) return 1;
      });
    })
  }

  onClickAddCompany(company) {
    this._router.navigate(['/dashboard/companies/add'], {queryParams: {companyData: encodeURI(JSON.stringify(company))}});
  }

}
