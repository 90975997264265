<div class="row m-0">
  <div class="col-lg-5 px-0 input-group-wrapper first">
    <div class="input-group icon-prepend-append">
      <div class="input-group-prepend">
        <span class="input-group-icon">
          <img src="/assets/img/homi_theme/search.png" srcset="/assets/img/homi_theme/search@2x.png 2x,
              /assets/img/homi_theme/search@3x.png 3x">
        </span>
      </div>
      <input (keyup)="onKeyUpInput($event)" type="text" aria-label="Listing Title"
        placeholder="{{'e.g. \'The Pioneer\'' | translate}}" class="form-control" [(ngModel)]="listingTitle">
    </div>
  </div>

  <div class="col-lg-4 px-0 input-group-wrapper">
    <div class="input-group icon-prepend-append">
      <div class="input-group-prepend">
        <span class="input-group-icon">
          <img src="/assets/img/homi_theme/map-marker-alt.png" srcset="/assets/img/homi_theme/map-marker-alt@2x.png 2x,
                /assets/img/homi_theme/map-marker-alt@3x.png 3x">
        </span>
      </div>
      <input type="text" aria-label="Fill Address" placeholder="{{'Location (city, neighbourhood)' | translate}}"
        class="form-control" #inputAddress>
      <a class="input-group-append" (click)="searchNearby()" matTooltip="{{'Search nearby' | translate}}">
        <span class="input-group-icon">
          <mat-spinner *ngIf="isLoadingCurrentLocation" diameter="15"></mat-spinner>
          <img *ngIf="!isLoadingCurrentLocation" src="/assets/img/homi_theme/bullseye.png" srcset="/assets/img/homi_theme/bullseye@2x.png 2x,
                  /assets/img/homi_theme/bullseye@3x.png 3x">
        </span>
      </a>
    </div>
  </div>

  <div class="col-lg-3 px-0 submit-button-wrapper">
    <button type="button" class="btn btn-primary btn-lg" (click)="doSearch()">{{'Search' | translate}}</button>
  </div>
</div>