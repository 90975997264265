<div class="listings-page-wrapper">
  <div class="container">
    <!-- SEARCH  -->
    <div class="row">
      <div class="search-listing-wrapper col-lg-10 offset-lg-1 my-5">
        <app-search-listing (search)="onSearch($event)" [selectedFilters]="selectedFilterOptions"></app-search-listing>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-9 offset-lg-3">
        <div class="results-header mb-3">
          <div class="pagination-label" *ngIf="pagedResponse && !errorMessage">
            <strong>{{pagedResponse.total}}</strong> <span class="pagination-suffix ml-1"> listings found</span>
          </div>
          <div class="sort">
            <select class="custom-select" (change)="onChangeListingSort($event)" [(ngModel)]="sortOrder">
              <option value="MOST_RECENT">Most Recent</option>
              <option value="NEARBY">Nearby</option>
              <!-- <option value="HIGHEST_SALARY">Highest Salary</option> -->
              <option value="NEAR_ADDRESS" *ngIf="sortNearAddress">Near {{sortNearAddressLabel}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 sidebar">
        <div class="selected-filters filter-box-wrapper p-2" *ngIf="selectedFilterOptions.length">
          <div class="selected-filters-label">
            You refined by:
          </div>
          <div class="filters-wrapper mt-2">
            <ul class="filtered-options">
              <li *ngFor="let filterOption of selectedFilterOptions">
                {{filterOption.label}}
                <i-feather class="remove-filter" name="x-circle"
                  (click)="removeFilter(filterOption.filterName, filterOption.filterValue)"></i-feather>
              </li>
            </ul>
          </div>
          <a class="remove-all-filters" (click)="removeFilter()">Clear All</a>
        </div>

        <div class="filter-box-wrapper">
          <div class="card">
            <div class="card-body">
              <div class="filter-title">
                <img src="assets/img/homi_theme/flag-alt.png" srcset="assets/img/homi_theme/flag-alt@2x.png 2x,
                      assets/img/homi_theme/flag-alt@3x.png 3x" class="medal"> Country
              </div>
              <ul class="filter-content">
                <li *ngFor="let address of listingFilterOptions?.address; index as i" class="listing-filter-item">
                  <div class="form-check">
                    <input id="listingFilter-option-{{i}}" class="form-check-input" type="checkbox"
                      (change)="toggleFilter($event, 'address.addressCountry', address.addressCountry, 'eq', address.addressCountry)">
                    <label class="form-check-label" for="listingFilter-option-{{i}}">
                      {{address.addressCountry}}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="filter-box-wrapper">
          <div class="card">
            <div class="card-body">
              <div class="filter-title">
                <img src="assets/img/homi_theme/money-bill-wave.png" srcset="assets/img/homi_theme/money-bill-wave@2x.png 2x,
                        assets/img/homi_theme/money-bill-wave@3x.png 3x" class="medal"> Minimum Budget
              </div>
              <div class="minimumSalaryFilter mb-1">
                <strong>{{minimumSalaryFilter | currency:'EUR'}}</strong> /mo
              </div>
              <input type="range" min="1" max="5000" value="200" (change)="onChangeMinimumSalary($event)" class="slider"
                #rangeInput [(ngModel)]="minimumSalaryFilter">
            </div>
          </div>
        </div>

      </div>

      <div class="col-lg-9">
        <div class="card">
          <mat-spinner *ngIf="isLoading"></mat-spinner>
          <div ngClass="listing-items-wrapper card-body {{isLoading ? 'loading': ''}}">
            <div class="no-results text-center" *ngIf="!pagedResponse?.total && !isLoading">
              <span class="text-muted">Oups! No results found <i-feather name="frown"></i-feather></span>
              <div class="mt-2"><a class="clear-all" (click)="removeFilter()">
                  <i-feather name="x-circle"></i-feather> Clear my filters
                </a></div>
            </div>
            <div class="view-mode mb-3" *ngIf="pagedResponse?.total && !errorMessage">
              <div class="btn-group" role="group">
                <button type="button" [ngClass]="'btn btn-sm ' + ((viewMode === 'list') ? 'btn-primary' : 'btn-light')"
                  (click)="onClickChangeViewMode('list')">
                  <i-feather name="list"></i-feather>
                </button>
                <button type="button" [ngClass]="'btn btn-sm ' + ((viewMode === 'grid') ? 'btn-primary' : 'btn-light')"
                  (click)="onClickChangeViewMode('grid')">
                  <i-feather name="grid"></i-feather>
                </button>
              </div>
            </div>
            <!-- LIST VIEW MODE -->
            <div *ngIf="viewMode === 'list'">
              <div class="card mb-3" *ngFor="let listing of pagedResponse?.data">
                <div class="card-body">
                  <app-listing-item [redirectOnClick]="false" [listing]="listing"
                    (clickListingTitle)="onClickListingTitle($event)">
                  </app-listing-item>
                </div>
              </div>
            </div>

            <!-- GRID VIEW MODE -->
            <div *ngIf="viewMode === 'grid'" class="row">
              <div class="col-lg-4 px-1" *ngFor="let listing of pagedResponse?.data">
                <app-listing-item-grid [redirectOnClick]="false" [listing]="listing"
                  (clickListingTitle)="onClickListingTitle($event)">
                </app-listing-item-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div id="myModal" class="modal fade modal-xl" #listingModal>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body p-0">
        <app-listing *ngIf="currentlySelectedListing" [listing]="currentlySelectedListing" showBack="true"
          (clickBack)="onDismissModal()"></app-listing>
      </div>
    </div>
  </div>
</div>