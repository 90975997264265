import { Component, Input } from '@angular/core';
import { User, MediaContent, Address } from 'src/app/swagger-generated';
import { getMediaContentUrl } from '../../services/mediaContent.service';
import { distance } from '../../shared';

@Component({
  selector: 'app-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss']
})
export class UserItemComponent {
  @Input('user') user: User;
  @Input('showFullName') showFullName: boolean;
  @Input('noLink') noLink: boolean;
  @Input('relativeAddress') relativeAddress: Address;

  constructor() { }

  getMediaContentUrl(mediaContent: MediaContent, entityType: string, size?: string) {
    return getMediaContentUrl(mediaContent, entityType, size, this.user);
  }


  get profilePictureUrl(){
    return getMediaContentUrl(this.user.profilePicture, 'user', 'thumb');
  }

  get nameLabel() {
    if (this.showFullName) {
      return this.user.givenName + ' ' + this.user.familyName;
    } else if (!this.user.nickName && this.user.givenName) {
      return `${this.user.givenName} ${(this.user.familyName) ? this.user.familyName[0] + '.' : ''}`;
    } else if (!this.user.nickName && this.user.email) {
      return this.user.email.substr(0, this.user.email.indexOf('@'));
    }

    return this.user.nickName;
  }

  get candidateLink() {
    if (this.showFullName) {
      return '/listing-candidates/' + this.user.id;
    }
    return '/candidates/' + this.user.id;
  }

  get formattedBudget(): string {
    if (this.user && this.user.budget) {
      const budget = this.user.budget;
      return ((budget.min === budget.max) ? budget.min : budget.min + ' - ' + budget.max).toString() + this.user.budget.currency;
    }
    return '';
  }

  get relativeDistance() {
    if (this.relativeAddress && this.user.address) {
      return distance(this.relativeAddress.geolocation.lat, this.relativeAddress.geolocation.lng, this.user.address.geolocation.lat, this.user.address.geolocation.lng);
    }
  }
}
