<div class="card">
  <div class="card-body">
    <div class="manage-listing-container">
      <div class="basic-info-input">
        <h4>{{activeListings?.length + inactiveListings?.length}} Listings</h4>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>Listing Title</th>
            <th>Price</th>
            <th>Status</th>
            <th class="action">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="listing-items" *ngFor="let listing of activeListings">
            <td class="title">
              <h5><a [routerLink]="'/listings/' + listing.id">{{getListingTitle(listing)}}</a></h5>
              <div class="info">
                <span class="office-location" *ngIf="listing.address">
                  <i-feather name="map-pin"></i-feather>{{listing.address?.addressLocality}}
                </span>
                <span class="listing-type full-time">
                  {{listing.rooms}} Rooms |
                </span>
                <span class="listing-type full-time">
                  {{listing.baths || '?'}} Baths
                </span>
                <span class="office-location">
                  <i-feather name="heart"></i-feather>{{listing.usersFavorited?.length}}
                </span>
              </div>
            </td>
            <td class="deadline">{{listing.price | currency:'USD'}}</td>
            <td class="status active">{{'listing.listingStatuses.' + listing.status | translate}}</td>
            <td class="action">
              <a [routerLink]="'/listings/' + listing.id + '/applications'" *ngIf="listing.listingApplications?.length">
                <i-feather name="users"></i-feather>View {{listing.listingApplications?.length}} applications
              </a>
              <span class="text-muted" *ngIf="!listing.listingApplications?.length">No applications yet.<br></span>
              <br>
              <a [routerLink]="'/dashboard/listings/' + listing.id + '/edit'" class="edit" title="Edit">
                <i-feather name="edit"></i-feather> Edit
              </a><br>
              <a (click)="onClickDelete(listing)" class="remove text-danger" title="Delete">
                <i-feather name="trash-2"></i-feather> Delete
              </a>
            </td>
          </tr>
          <tr class="listing-items listing-inactive" *ngFor="let listing of inactiveListings">
            <td class="title">
              <h5><a [routerLink]="'/listings/' + listing.id">{{getListingTitle(listing)}}</a></h5>
              <div class="info">
                <span class="office-location" *ngIf="listing.address">
                  <i-feather name="map-pin"></i-feather>{{listing.address?.addressLocality}}
                </span>
                <span class="listing-type full-time">
                  {{listing.rooms}} Rooms |
                </span>
                <span class="listing-type full-time">
                  {{listing.baths || '?'}} Baths
                </span>
                <span class="office-location">
                  <i-feather name="heart"></i-feather>{{listing.usersFavorited?.length}}
                </span>
              </div>
            </td>
            <!-- <td class="application"><a [routerLink]="'/listings/' + listing.id + '/applications'">
                    <i-feather name="external-link"></i-feather>{{listing.listingApplications?.length}} Applications
                  </a></td> -->
                  <td class="deadline">{{listing.price | currency:'USD'}}</td>            <td class="status inactive">{{'listing.listingStatuses.' + listing.status | translate}}</td>
            <td class="action">
              <a [routerLink]="'/listings/' + listing.id + '/applications'" *ngIf="listing.listingApplications?.length">
                <i-feather name="users"></i-feather>View {{listing.listingApplications?.length}} applications
              </a>
              <span class="text-muted" *ngIf="!listing.listingApplications?.length">No applications yet.<br></span>
              <br>
              <a [routerLink]="'/dashboard/listings/' + listing.id + '/edit'" class="edit" title="Edit">
                <i-feather name="edit"></i-feather> Edit
              </a><br>
              <a (click)="onClickDelete(listing)" class="remove text-danger" title="Delete">
                <i-feather name="trash-2"></i-feather> Delete
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>