
<div class="thread-messages-container">
  <div class="preloader">
    <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
  </div>
  <div class="thread-header">
    <app-user-item *ngIf="thread" [user]="getThreadUser()" showFullName="true"></app-user-item>
  </div>

  
  <ul class="messages-container m-0 p-0" #messagesContainer>
    <li *ngFor="let message of messages" [ngClass]="getMessageClass(message)">
      <div class="message-body p-2 mt-2" matTooltip="{{message.createdAt | amTimeAgo}}" [matTooltipPosition]="getMessageClass(message) === 'message-out'? 'left' : 'right'">{{message.content}}</div>
    </li>
  </ul>

  <div class="message-input my-3 input-group">
    <input type="text" class="form-control" [(ngModel)]="message" placeholder="Type your message here..." (keyup)="onKeyUpInput($event)">
    <div class="input-group-append">
      <span class="input-group-text">
        <button (click)="onClickSendMessage(message)">Send</button>
      </span>
    </div>
    
  </div>
</div>
