import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { GetManyDefaultResponse } from 'src/app/shared/models/paged-responses';
import { User, UsersService, ListingsService, Listing, ListingApplication } from 'src/app/swagger-generated';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-listing-applications',
  templateUrl: './listing-applications.component.html',
  styleUrls: ['./listing-applications.component.scss']
})
export class ListingApplicationsComponent implements OnInit {
  @ViewChild('listingApplicationStatusSelect') listingApplicationStatusSelect: ElementRef;

  pagedResponse: GetManyDefaultResponse<User>;
  listing: Listing;

  constructor(
    private listingsService: ListingsService,
    private activatedRoute: ActivatedRoute
  ) {
    const joinProps = [
      'listingApplications',
      'listingApplications.user',
      'listingApplications.user.profilePicture'
    ]
    this.activatedRoute.params.subscribe(params => {
      this.listingsService.listingsIdGet(params.id, undefined, joinProps)
        .subscribe(response => {
          this.listing = response;
        });
    })
  }

  ngOnInit() {
  }

  onChangeListingApplicationStatus(ev, listingApplication) {
    this.listingsService.listingApplicationsIdPatch(<ListingApplication><unknown>{ status: ev.target.value }, listingApplication.id).subscribe();
  }

}
