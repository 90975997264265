import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  gtag;

  constructor() {
    if ((<any>window).gtag) {
      this.gtag = (<any>window).gtag;
    }
  }

  sendEvent(eventCategory, eventAction, eventLabel = '(not set)') {
    if (this.gtag) {
      this.gtag('event', eventAction, {
        event_category: eventCategory,
        event_label: eventLabel
      });

      // this.gtag('send', 'event', eventCategory, eventAction);
    } else {
      console.log('Google Analytics Event:', eventCategory, eventAction);
    }
  }

  setPage(page) {
    if (page){
      if (this.gtag) {
        this.gtag('set', 'page', page);
        this.gtag('send', 'pageview');
       } else {
         console.log('Google Analytics Set Page:', page);
       }
    }
  }
}
