import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from '../../swagger-generated';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  emailSent = false;
  errorMessage;

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthService
  ) {
    this.form = this._formBuilder.group({
      email: ''
    })
  }

  ngOnInit() {
  }

  onSubmitForm() {
    this._authService.authResetPasswordPost(this.form.value).subscribe(res => {
      if (res.email) {
        this.emailSent = true;
      }
    }, ({message}) => this.errorMessage = message)
  }

}
