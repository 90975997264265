<div class="alice-bg section-padding-bottom">
  <div class="container py-5">
    <div class="row">
      <div class="col">
        <div class="contact-block">
          <div class="row">
            <div class="col-lg-4">
              <div class="contact-address">
                <h4>Contact Info</h4>
                <ul>
                  <li><i data-feather="map-pin"></i>Ioan V. Socec 22N, Biroul 1, Brașov, Romania</li>
                  <li><i data-feather="mail"></i>contact@homi.house</li>
                  <li><i data-feather="phone-call"></i>+40 722 395 653</li>
                </ul>
              </div>
            </div>
            <div class="col-lg-7 offset-lg-1">
              <div class="contact-form">
                <h4>Keep In Touch</h4>
                <form method="POST" target="_blank" name="contact-form" (ngSubmit)="onSubmit($event)" action="https://docs.google.com/forms/d/e/1FAIpQLSevAY2l1cDqibbrtRkmhZTAqcgLBfeuEGVKavRpYt1oZCaHwQ/formResponse">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="Name" name="entry.1095258661">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="email" class="form-control" placeholder="Email" name="emailAddress">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="Phone" name="entry.1759512155">
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="text" class="form-control" placeholder="Subject" name="entry.1909471881">
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group">
                        <textarea class="form-control" placeholder="Message" name="entry.301757536"></textarea>
                      </div>
                    </div>
                  </div>
                  <input class="btn btn-primary button" type="submit" />
                </form>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div id="map-area" class="contact-map">
                <div class="cp-map" id="location" data-lat="45.672889" data-lng="25.598397" data-zoom="16"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>