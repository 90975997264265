import { Component, OnInit, ViewChild } from '@angular/core';
import { User, UsersService, MediaContent, UserConnection } from 'src/app/swagger-generated';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { getMediaContentUrl } from '../../services/mediaContent.service';
import { SessionService } from '../../services/session.service';
import { ConnectionStatusEnum } from '../../shared/models/connection-status';
@Component({
  selector: 'app-candidate',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.scss']
})
export class CandidateComponent implements OnInit {
  user: User
  userId;
  showFullName = false;
  userConnection: UserConnection;
  connectionStatus: ConnectionStatusEnum;

  constructor(
    private usersService: UsersService,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService
  ) {
    this.activatedRoute
      .data
      .subscribe(data => this.showFullName = data.showFullName).unsubscribe();
  }

  ngOnInit() {
    this.sessionService.getCurrentUser().then(currentUser => {
      const joinQueryString = [
        'profilePicture',
        'cv',
        'mediaContent',
        'address',
        'spokenLanguages',
        'spokenLanguages.language',
        'nationalities',
        'baseSalary',
        'photos',
        'driversLicenseCategories',
        'occupations',
        'occupations.company'
      ];
      this.activatedRoute.params.subscribe(params => {
        this.userId = params.id;
        this.usersService.usersIdGet(params.id, undefined, joinQueryString)
          .subscribe(response => {
            this.user = response;
          });
        this.usersService.usersIdConnectionsGet(params.id).subscribe(userConnections => {
          // Due to permissions restriction, we will receive a array with the users's connection
          // With current user, or empty array.
          this.processConnectionStatus(userConnections[0]);
        });
      })
    })
  }

  get profilePictureUrl() {
    if (this.user) {
      return getMediaContentUrl(this.user.profilePicture, 'user', 'thumb', this.user);
    }
    return getMediaContentUrl(null, 'user', 'thumb', this.user);
  }

  get nameLabel() {
    if (this.user) {
      if (this.showFullName) {
        return this.user.givenName + ' ' + this.user.familyName;
      } else if (!this.user.nickName && this.user.givenName) {
        return `${this.user.givenName} ${(this.user.familyName) ? this.user.familyName[0] + '.' : ''}`;
      } else if (!this.user.nickName) {
        return this.user.email.substr(0, this.user.email.indexOf('@'));
      }

      return this.user.nickName;
    }
  }

  get googleMapsImage() {
    const coords = this.user.address.geolocation;
    return `https://maps.googleapis.com/maps/api/staticmap?center=${coords.lat},${coords.lng}&zoom=13&size=300x300&maptype=roadmap&key=AIzaSyAdQQP16TXbwkzjcrA5DgoGsVSe2Pi3EQo`
  }

  get mediaContents() {
    if (this.user && this.user.photos) {
      return [...this.user.photos];
    }
    return [];
  }

  onClickConnectButton(connectionStatus: ConnectionStatusEnum) {
    if (connectionStatus === ConnectionStatusEnum.NONE) {
      const userConnection = <UserConnection>{
        users: [this.user]
      };
      this.usersService.connectionsPost(userConnection).subscribe(data => {
        this.processConnectionStatus(data);
      })
    } else if (connectionStatus === ConnectionStatusEnum.CONNECTED || connectionStatus === ConnectionStatusEnum.SENT) {
      this.usersService.connectionsIdDelete(this.userConnection.id).subscribe(data => {
        this.processConnectionStatus(undefined);
      })
    } else if (connectionStatus === ConnectionStatusEnum.RECEIVED) {
      const userConnection = <UserConnection>{
        approved: true
      };

      this.usersService.connectionsIdPatch(userConnection, this.userConnection.id).subscribe(data => {
        this.userConnection.approved = userConnection.approved;
        this.processConnectionStatus(this.userConnection);
      })
    }
  }

  processConnectionStatus(userConnection: UserConnection) {
    this.userConnection = userConnection;
    if (userConnection) {
      if (userConnection.approved) {
        this.connectionStatus = ConnectionStatusEnum.CONNECTED;
      } else {
        this.connectionStatus = (userConnection.initiatorUserId !== this.userId) ? ConnectionStatusEnum.SENT : ConnectionStatusEnum.RECEIVED;
      }
    } else {
      this.connectionStatus = ConnectionStatusEnum.NONE;
    }
  }
}
