import { Component, OnInit } from '@angular/core';
import { UsersService, User } from '../../../swagger-generated';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  users: User[];

  constructor(
    private _usersService: UsersService,
  ) { }

  ngOnInit(): void {
    this._usersService.usersGet(undefined, undefined, undefined, ['createdAt,DESC'], ['listingApplications', 'listingApplications.listing', 'listingApplications.listing.owner']).subscribe(data => {
      this.users = data;
    });
  }

}
