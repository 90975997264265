import { HttpUrlEncodingCodec } from '@angular/common/http';

/**
* CustomHttpUrlEncodingCodec - this needs to override swagger's encoder.ts
**/
export class CustomHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
    encodeKey(k: string): string {
        k = super.encodeKey(k);
        k = k.replace(/%5B/gi, '[');
        k = k.replace(/%5D/gi, ']');
        k = k.replace(/%7C/gi, '|');
        return k.replace(/\+/gi, '%2B');
    }
    encodeValue(v: string): string {
        v = super.encodeValue(v);
        v = v.replace(/%26/gi, '&');
        v = v.replace(/%5B/gi, '[');
        v = v.replace(/%5D/gi, ']');
        v = v.replace(/%7C/gi, '|');
        return v.replace(/\+/gi, '%2B');
    }
}

