<div class="card">
  <div class="card-body">
    <div class="manage-listing-container">
      <div class="basic-info-input table-title">
        <h4>{{companies?.length}} Properties</h4>
        <button class="btn btn-success" (click)="onClickSyncCompanies()">Sync <mat-spinner *ngIf="isLoadingSync" diameter="20"></mat-spinner></button>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>Company</th>
            <th>Source</th>
            <th class="action">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="listing-items" *ngFor="let company of companies">
            <td class="title">
              <h5><a [routerLink]="'/companies/' + company.id">{{company.name}}</a></h5>
              <div class="info">
                <span class="office-location">
                  <i-feather name="map-pin"></i-feather> {{company?.address?.addressLocality}}
                </span>
              </div>
            </td>
            <td class="source">
              {{company.externalSource}}
              <div *ngIf="company.externalSource">
                <small *ngIf="company.autoSyncListings" class="text-success">AUTO SYNC</small>
                <small *ngIf="!company.autoSyncListings" class="text-danger">NO SYNC</small>
              </div>
            </td>
            <td class="action">
              <a [routerLink]="'/roommates/' + company.slug" title="View landing page">
                <i-feather name="eye"></i-feather> Landing page
              </a><br>
              <a [routerLink]="'/dashboard/companies/' + company.id + '/edit'" class="edit" title="Edit">
                <i-feather name="edit"></i-feather> Edit
              </a><br>
              <a (click)="onClickDelete(company)" class="remove text-danger" title="Delete">
                <i-feather name="trash-2"></i-feather> Delete
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>