<div class="dashboard-wrapper" *ngIf="currentUser">
  <div class="dashboard-header my-4">
    <div class="media user-header">
      <div [style.background-image]="'url(' + profilePictureUrl + ')'" class="thumb mr-3 rounded-circle"></div>
      <div class="media-body top-user-details-wrapper">
        <div class="curren-user-details">
          <h5 class="m-0 user-name">{{currentUser?.givenName}} {{currentUser?.familyName}}</h5>
          <span class="text-muted">Backoffice Dashboard</span>
        </div>
        <div class="current-user-role">
          <div class="btn-group btn-group-toggle" data-toggle="buttons" *ngIf="!hasRole(RolesEnum.REALESTATE)">
            <label class="btn btn-primary active" (click)="onChangeRole(RolesEnum.USER)">
              <input type="radio" name="options" autocomplete="off" checked> Homi
            </label>
            <label class="btn btn-outline" (click)="onChangeRole(RolesEnum.REALESTATE)">
              <input type="radio" name="options" autocomplete="off"> Realtor
            </label>
          </div>
          <div class="btn-group btn-group-toggle" data-toggle="buttons" *ngIf="hasRole(RolesEnum.REALESTATE)">
            <label class="btn btn-outline" (click)="onChangeRole(RolesEnum.USER)">
              <input type="radio" name="options" autocomplete="off"> Homi
            </label>
            <label class="btn btn-primary active" (click)="onChangeRole(RolesEnum.REALESTATE)">
              <input type="radio" name="options" autocomplete="off" checked> Realtor
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dashboard-main-content container-fluid">
    <div class="row">
      <div class="col-lg-3 p-0">
        <div class="nav-title mb-2">
          <img src="/assets/img/homi_theme/user.png" srcset="/assets/img/homi_theme/user@2x.png 2x,
            /assets/img/homi_theme/user@3x.png 3x" class="pr-2"> User Settings
        </div>
        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <a class="nav-link" routerLinkActive="active" role="tab" routerLink="/dashboard/profile">Edit Profile</a>
        </div>
        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <a class="nav-link" routerLinkActive="active" role="tab" routerLink="/dashboard/users">Manage users</a>
        </div>
        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <a class="nav-link" routerLinkActive="active" role="tab" routerLink="/dashboard/listing-applications">Manage applications</a>
        </div>

        <div class="nav-title mb-2 mt-3">
          <img src="/assets/img/homi_theme/briefcase.png" srcset="/assets/img/homi_theme/briefcase@2x.png 2x,
              /assets/img/homi_theme/briefcase@3x.png 3x" class="pr-2"> Listings
        </div>
        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <a *ngIf="hasRole(RolesEnum.REALESTATE)" class="nav-link" routerLinkActive="active" role="tab"
            routerLink="/dashboard/listings/add">Post New Listing</a>
          <a *ngIf="hasRole(RolesEnum.REALESTATE)" class="nav-link" routerLinkActive="active" role="tab"
            routerLink="/dashboard/listings" [routerLinkActiveOptions]="{exact: true}">Manage Listings</a>
          <a class="nav-link" routerLinkActive="active" role="tab" routerLink="/dashboard/favorite-listings">Favorite
            Listings</a>
          <a *ngIf="hasRole(RolesEnum.USER)" class="nav-link" routerLinkActive="active" role="tab"
            routerLink="/dashboard/applied-listings">Applied Listings</a>
        </div>

        <div class="nav-title mb-2 mt-3" *ngIf="hasRole(RolesEnum.REALESTATE)">
          <img src="/assets/img/homi_theme/briefcase.png" srcset="/assets/img/homi_theme/briefcase@2x.png 2x,
                /assets/img/homi_theme/briefcase@3x.png 3x" class="pr-2"> Properties
        </div>
        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical"
          *ngIf="hasRole(RolesEnum.REALESTATE)">
          <a class="nav-link" routerLinkActive="active" role="tab" routerLink="/dashboard/companies/add">Add Property</a>
          <a class="nav-link" routerLinkActive="active" role="tab" routerLink="/dashboard/companies"
            [routerLinkActiveOptions]="{exact: true}">Manage Properties</a>
          <a class="nav-link" routerLinkActive="active" role="tab" routerLink="/dashboard/external-companies"
            [routerLinkActiveOptions]="{exact: true}">External Properties</a>
        </div>
      </div>
      <div class="col-lg-9 p-0">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>