import { NgModule } from '@angular/core';
import {
  ApiModule,
  Configuration,
  ConfigurationParameters
} from '../swagger-generated';

// This will be specific to the project
import { environment } from '../../environments/environment';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.apiUrl,
    apiKeys: {
      /**
       * TODO - Re-visit authorization logic
       */
      Authorization: ''
    }
  };
  return new Configuration(params);
}

@NgModule({
  imports: [
    ApiModule.forRoot(apiConfigFactory),
  ],
})

export class GeneratedApiModule { }

export * from '../swagger-generated';
