<div class="container forgot-password-wrapper">
  <div class="card my-5 col-md-6">
    <div class="card-body p-4">
      <div class="row">
        <div class="col-md-12 text-center">
          <h2>Forgot password</h2>
        </div>
        <div class="col-md-12">
          <div class="access-form">
            <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorMessage">
              {{errorMessage}}
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="emailSent">
                Please check your email - we sent you the reset password link.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

            <form class="text-center" (ngSubmit)="onSubmitForm()" [formGroup]="form">
              <div class="col-md-12">
                <div class="form-group">
                  <input type="email" placeholder="{{'Email' | translate}}" class="form-control" formControlName="email"
                    required name="email">
                </div>
              </div>
              <button class="btn btn-primary btn-lg" [disabled]="!form.valid">Reset password</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>