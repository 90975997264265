<div class="container reset-password-wrapper">
    <div class="card my-5 col-md-6">
      <div class="card-body p-4">
        <div class="row">
          <div class="col-md-12 text-center">
            <h2>Reset your password</h2>
          </div>
          <div class="col-md-12">
            <div class="access-form">
              <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorMessage">
                {{errorMessage}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
  
              <form class="text-center" (ngSubmit)="onSubmitForm()" [formGroup]="form">
                  <div class="col-md-12">
                    <div class="form-group">
                        <input type="hidden" formControlName="code"
                        required name="code">
                      <input type="password" placeholder="{{'Password' | translate}}" class="form-control" formControlName="password"
                        required name="password">
                      <input type="password" placeholder="{{'Repeat password' | translate}}" class="form-control" formControlName="passwordRepeat"
                        required name="passwordRepeat">
                    </div>
                  </div>
                  <button class="btn btn-primary btn-lg" [disabled]="!form.valid">Update password</button>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </div>