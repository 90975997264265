import { Component, OnInit } from '@angular/core';
import { ListingsService, Listing, MediaContent, MediaContentService, CompaniesService } from 'src/app/swagger-generated';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { SwaggerDocOptimizerService } from 'ngx-swag-form';
import { FormProperty } from 'ngx-schema-form';

@Component({
  selector: 'app-add-listing',
  templateUrl: './add-listing.component.html',
  styleUrls: ['./add-listing.component.scss']
})
export class AddListingComponent implements OnInit {
  listing: Listing = {} as Listing;
  isEditMode: boolean;
  isLoading = false;
  listingId;
  listingSchema;
  companies;

  constructor(
    private _listingsService: ListingsService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public httpClient: HttpClient,
    private _swaggerDocOptimizerService: SwaggerDocOptimizerService,
    private _mediaContentService: MediaContentService,
    private _companiesService: CompaniesService
  ) {
    this.listingSchema = this._swaggerDocOptimizerService.getOptimizedSchema('Listing');

    this.activatedRoute
      .data
      .subscribe(data => {
        this.isEditMode = (data.mode === 'edit');
      });

    this.activatedRoute.params.subscribe(q => {
      if (q.id) {
        this.listingId = q.id;
      }
    });
  }

  ngOnInit() {
    if (this.isEditMode) {
      const join = [
        'listingLogo',
        'address',
        'mediaContent',
        'photos'
      ];
      this._listingsService.listingsIdGet(this.listingId, undefined, join).subscribe(listing => {
        this.listing = listing;
        this.listing.mediaContent = _.sortBy(this.listing.mediaContent, 'weight');
      })
    }
    this.populateCompanies();
  }

  populateCompanies() {
    this._companiesService.companiesGet(undefined, undefined, undefined, undefined, ['address']).subscribe(data => {
      this.companies = data;
      this.listingSchema.properties.owner.properties.id.oneOf = this.companies.map(company => {
        return {
          "enum": [company.id],
          "description": company.name
        }
      })
    })

  }

  onClickSave() {
    this.isLoading = true;

    const savedMediaContents = this.listing.mediaContent.filter(mediaContent => mediaContent.url);

    const promises = this.listing.mediaContent.filter(mediaContent => !mediaContent.url).map(mediaContent => {
      return new Promise((resolve, reject) => {
        this._mediaContentService.mediaContentPost(mediaContent).subscribe(response => {
          if (response.id) {
            resolve(response);
          } else {
            reject(response);
          }
        })
      })
    });

    Promise.all(promises).then(mediaContents => {
      this.listing.mediaContent = [...savedMediaContents, ...mediaContents] as MediaContent[];
      this.createListing();
    });
  }

  createListing() {
    if (this.isEditMode) {
      this._listingsService.listingsIdPatch(this.listing, this.listingId).subscribe(listing => {
        this.isLoading = false;
        this.router.navigate([`/listings/${listing.id}`]);
      })
    } else {
      this._listingsService.listingsPost(this.listing).subscribe(listing => {
        this.isLoading = false;
        this.router.navigate([`/listings/${listing.id}`]);
      })
    }
  }

  formBindings = {
    '/owner': [
      {
        'input': (event, formProperty: FormProperty) => {
          // Company Value format 1: xxxx-xxxx
          const selectedCompanyId = event.target.value.substring(event.target.value.indexOf(' ') + 1);
          const selectedCompany = this.companies.find(company => company.id === selectedCompanyId);
          if (selectedCompany) {
            formProperty.root.setValue({
              title: selectedCompany.name,
              description: selectedCompany.description,
              address: {
                addressCountry: selectedCompany.address.addressCountry,
                addressLocality: selectedCompany.address.addressLocality,
                addressRegion: selectedCompany.address.addressRegion,
                streetAddress: selectedCompany.address.streetAddress,
                formattedAddress: selectedCompany.address.formattedAddress,
                geolocation: {
                  lat: selectedCompany.address.geolocation.lat,
                  lng: selectedCompany.address.geolocation.lng,
                }
              },
              amenities: selectedCompany.amenities
            }, false);
            formProperty.root.updateValueAndValidity(false, true);
          }
        }
      }

    ]
  }
}
