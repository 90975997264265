<!-- Breadcrumb -->
<div class="alice-bg padding-top-70 padding-bottom-70">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="breadcrumb-area">
          <h1>Privacy Policy</h1>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Breadcrumb End -->

<div class="alice-bg section-padding-bottom">
  <div class="container no-gliters">
    <div class="row no-gliters">
      <div class="col">
        <div class="terms-container">
          <div class="terms-content-wrapper">
            <div class="policy-tab">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="privacy-tab" data-toggle="tab" href="#privacy" role="tab"
                    aria-controls="privacy" aria-selected="true">Privacy Policy</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="terms-tab" data-toggle="tab" href="#terms" role="tab" aria-controls="terms"
                    aria-selected="false">Terms of Service</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="legal-tab" data-toggle="tab" href="#legal" role="tab" aria-controls="legal"
                    aria-selected="false">PRELUCRAREA DATELOR</a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="privacy" role="tabpanel" aria-labelledby="privacy-tab">

                  <!-- PRIVACY POLICY -->

                  <p><strong>POLITICA DE CONFIDENȚIALITATE</strong></p>
<p>&nbsp;</p>
<p>Securitatea datelor dumneavoastră cu caracter personal este importantă pentru noi. Astfel, vă prezentăm &icirc;n prezenta politică de confidențialitate informații &icirc;n legătură cu datele pe care le prelucrăm, modul &icirc;n care le prelucrăm și &icirc;n ce scopuri, precum și ce drepturi aveți cu privire la datele dumneavoastră.</p>
<p><strong>CINE SUNTEM</strong></p>
<p>Operatorul datelor dumneavoastră cu caracter personal este societatea 3 MINUTES JOB S.R.L., o societate cu răspundere limitata, cu sediul &icirc;n Brașov, strada Ioan V. Socec, nr. 22N, județul Brașov, &icirc;nregistrată la Oficiul Registrului Comerțului de pe l&acirc;ngă Tribunalul Brașov sub numărul&nbsp; J8/1509/2019, cod unic de &icirc;nregistrare 41018510 (&bdquo;<strong>Societatea</strong>&rdquo;).</p>
<p>Email: contact@3minutesjob.com, Tel: +40 722 395 653</p>
<p>Pagina noastră de internet este: <a href="http://www.3minutesjob.com">www.3minutesjob.com</a> (&rdquo;<strong>Site-ul</strong>&rdquo;).</p>
<p>Această Politică de Confidențialitate se aplică datelor cu caracter personal pe care le colectăm despre dumneavoastră c&acirc;nd utilizați pagina noastră de internet indicată mai sus, precum și c&acirc;nd apelați la serviciile oferite pe Site (&icirc;ncarcarea CV-uri, &icirc;ncărcare fotografii / video de prezentare, publicare anunțuri de recrutare), sau intrați &icirc;n contact cu noi prin intermediul paginilor noastre existente pe rețelele de socializare, precum Facebook, Youtube, LinkedIn.</p>
<p><strong>CE DATE PRELUCRĂM, DE UNDE LE AVEM</strong></p>
<ol>
<li><strong> Date furnizate de dumneavoastră</strong></li>
</ol>
<p>Majoritatea datelor pe care le prelucrăm cu privire la dumneavoastră sunt furnizate de dumneavoastră &icirc;n mod direct, atunci c&acirc;nd vă creați cont pe Site-ul nostru, c&acirc;nd ne contactați prin intermediul formularului de contact disponibil pe Site sau c&acirc;nd apelați la serviciile oferite pe Site. Astfel, prelucrăm următoarele categorii de date:</p>
<ul>
<li><strong>Date de identificare și date de contact</strong>: nume, prenume, e-mail, număr de telefon, cont de youtube, calitatea pe care o aveți &icirc;n cadrul societății (&icirc;n cazul contului de Utilizator Recrutor);</li>
<li><strong>Date personale</strong>: data si locul nasterii, nationalitate, hobby-uri si arii de interes etc.;</li>
<li><strong>Date financiare: </strong>salariul actual, salariul dorit, etc.;</li>
<li><strong>Date din CV-ul &icirc;ncărcat / creat pe Site: </strong>date cu privire la experienta dumneavoastra profesionala (date cu privire la angajatorii anteriori, incetarea contractului individual la ultimului loc de munca si activitatea desfasurata acolo, proiecte speciale etc)<strong>, </strong>poza dumneavoastra, daca in mod voluntar ati furnizat o astfel de poza (e.g.prin includerea in CV sau in alte documente furnizate de catre dumneavoastra), afilieri la diverse grupuri/entitati/cluburi profesioanle, culturale sau de alta natura daca astfel de date sunt incluse in CV sau in alte documente furnizate de catre dumneavoastra, date referitoare la limbile străine cunoscute, date cu privire la pregatirea dumneavoastra profesionala (diplome, certificate, stagii de practica, instruiri speciale etc.), informatii cu privire la detinerea unui permis de munca si/sau cu privire la rezindenta dumneavoastra in Spatiul Economic European (SEE), informatii cu privire la detinerea unui permis de conducere, orice alta informatie pe care o furnizati ca parte din CV sau din cererea de angajare, rezultatele testelor de evaluare efectuate de centrele de evaluare, daca ne-au fost furnizate de catre dumneavoastra in CV si celelate documente furnizate de catre dumneavoastra, orice alte date furnizate de dumneavoastră prin intermediul Site-ului;</li>
<li><strong>Date de geolocalizare: </strong>date referitoare la poziția dumneavostră fizică, &icirc;n funcție de coordonatele GPS ale terminalului cu care accesați Site-ul, orașul / țara &icirc;n care vă situați;</li>
<li><strong>Date referioare la preferințele dumneavostră:</strong> disponibilitate de a lucra &icirc;n week-end, disponibilitate de a lucra part-time, disponibilitate de a lucra &icirc;n timpul nopții;</li>
<li><strong>Date medicale:</strong> date referitoare la eventualele dizabilități, etc.</li>
<li><strong>Date referitoare la cazierul judiciar:</strong> informații despre existența unor fapte &icirc;nscrise &icirc;n cazierul judiciar fără a avea informații despre natura respectivelor fapte;</li>
<li><strong>Conținut</strong> pe care &icirc;l generati prin intermediul Site-ului nostru, respectiv mesajele pe care ni le transmiteți prin intermediul formularului de contact existent pe Site sau pe e-mail, conținut mesajelor de feedback pentru serviciile oferite de Site / feedback pentru experiența cu alți utilizatori intermediată de Site, conținut pe care &icirc;l generați prin intermediul paginilor noastre existente pe rețelele de socializare, precum Facebook, Youtube, LinkedIn. &Icirc;n situații precum atunci c&acirc;nd dați &ldquo;<em>like</em>&rdquo; paginii 3 Minutes Job sau postărilor noastre, c&acirc;nd ne transmiteți mesaje sau c&acirc;nd postați comentarii, date precum numele dumneavoastră de utilizator, imaginea/poza sau conținutul mesajului vor face obiectul prelucrării. Totodată, este posibil ca rețelele de socializare &icirc;n cauză să ne permită accesul &icirc;n mod automat la anumite informații pe care acestea le colectează cu privire la dumneavastră (ex: conținut pe care l-ați vizualizat sau la care ați dat &rdquo;like&rdquo; sau informații despre clipurile publicitare pe care le-ați vizualizat, etc.). &nbsp;</li>
</ul>
<ol start="2">
<li><strong> Date pe care le prelucram automat atunci cand accesați Site-ul</strong></li>
</ol>
<p>Atunci c&acirc;nd accesați Site-ul, utilizăm serviciul Google Analytics pentru a colecta informații privind interactiunea dumneavoastră cu Site-ul. Aceste informații ne permit să vă oferim accesul la Site-ul nostru, dar și să primim informații cu privire la modul &icirc;n care vizitatorii utilizează Site-ul nostru, astfel &icirc;nc&acirc;t să ne imbunătățim serviciile. Astfel, prelucrăm următoarele categorii de date:</p>
<ul>
<li><strong>Date de logare</strong> &ndash; asemeni majoritătii paginilor de internet, folosim date pe care browser-ul de internet sau dispozitivul mobil le furnizează de regulă, precum tipul de browser, adresa IP, sistemul de operare sau data și ora accesului.</li>
<li><strong>Date cu privire la utilizare</strong> &ndash; prelucrăm date cu privire la modul de utilizare a Site-ului, pentru a afla informații precum numărul de vizitatori pe diversele pagini de pe Site, dacă v-ați actualizat sau nu CV-ul, dacă ați aplicat la un job, etc;</li>
<li><strong>Date oferite prin cookies și alte tehnologii</strong> - un cookie este un identificator alfanumeric instalat pe hard drive-ul dumneavoastră la momentul la care vizitați un website, o aplicație de mobil sau o reclamă. Acesta stochează informații cu privire la vizita dumneavoastră pe Site (paginile vizitate, data și durata vizitelor, etc.). Puteti seta browserul sa refuze toate sau unele cookies sau să vă avertizeze atunci c&acirc;nd paginile de internet setează sau acceseaza cookie-uri.Pentru mai multe informații despre cookie-urile pe care le folosim, va rugăm sa consultati&nbsp;</li>
</ul>
<p><strong>&Icirc;N CE SCOPURI ȘI &Icirc;N CE TEMEI PRELUCRĂM DATELE DUMNEAVOASTRĂ </strong></p>
<p>Vom prelucra datele dumneavoastră doar atunci c&acirc;nd legea ne permite și doar pentru scopurile declarate sau pentru alte scopuri, dacă acestea sunt compatibile cu scopurile inițiale. Cel mai frecvent, vom folosi datele dumneavoastră &icirc;n următoarele situații:&nbsp;&nbsp;</p>
<ol>
<li><strong>Pentru a vă permite crearea contului pe Site</strong></li>
</ol>
<p>Temeiul care stă la baza prelucrării de date &icirc;n scopul de mai sus, conform Regulamentului General privind Protecția Datelor, este <em>acordul dumneavoastră</em>. Puteți oric&acirc;nd să vă retrageți acordul exprimat la momentul creării contului pe Site prin ștergerea acestui cont &ndash; oricare date care au fost furnizate către Societate la momentul creării contului vor fi automat șterse.</p>
<p>Prin excepție, &icirc;n cazul &icirc;n care ați CV-ul dumneavoastră a fost făcut public, Utilizatorii Recrutori care au accesat facilitatea de acces la CV-uri, au avut posibilitatea de a descarcă sau transmite datele dumneavoastră cu caracter personal pe propriile medii de stocare. &Icirc;n acest caz, chiar dacă Societatea va șterge datele dumneavoastră din baza de date, Utilizatorii Recrutori vor putea avea acces &icirc;n continuare la datele dumneavoastră &icirc;n cazul &icirc;n care le-au descarcat, copiat sau extras din baza de date disponibilă pe Site. &Icirc;n calitatea lor de operatori, Utilizatorii Recrutori sunt &icirc;n mod independent responsabili de modul &icirc;n care utilizează datele cu caracter personal ale dumneavoastră pe care le accesează prin intermediul Site-ului.&nbsp;&nbsp;&nbsp;&nbsp;</p>
<ol start="2">
<li><strong>Pentru a răspunde cererilor dumneavoastră atunci c&acirc;nd ne contactați prin intermediului formularului de contact disponibil pe Site fără a avea un cont pe Site</strong></li>
</ol>
<p>Astfel de prelucrări se vor realiza &icirc;n baza<em> acordului dumneavoastră expres </em>de a vă fi prelucrate datele &icirc;n acest scop.&nbsp;&nbsp;&nbsp;</p>
<ol start="3">
<li><strong>Utilizarea serviciilor de către Utilizatorii Candidați</strong></li>
</ol>
<p>Prelucrăm datele cu caracter personal ale Utilizatorilor Candidați pentru a le permite utilizarea serviciilor (cum ar fi să vă creați un CV, să aplicați la un job, să vizualizați anunțuri de angajare, să contactați Utilizatorii Recrutori, să primiți anunțuri și alerte &icirc;n legătura cu anunțurile de recrutare publicate de Utilizatorii Recrutori etc.)</p>
<p><em>Temeiul juridic: art. 6(1)(b) GDPR &ndash; &icirc;ncheierea și executarea contractului</em>.</p>
<p>De asemenea, Site-ul poate fi accesat de Utilizatori Recrutori din străinătate, inclusiv din afara Spațiului Economic European, către jurisdicții cărora nu li s-a recunoscut un nivel de protecție adecvat. &Icirc;n cazul &icirc;n care aplicați pentru un post publicat de un asemenea Utilizator Recrutor, datele dvs. pot fi transferate către o astfel de jurisdicție, iar temeiul juridic al transferului este <em>consimțăm&acirc;ntul dumneavoastră </em>exprimat la momentul &icirc;n care ați aplicat la postul respectiv.</p>
<p>Putem de asemenea să folosim o serie de date pe care ni le furnizați pentru a optimiza vă facem sugestii de locuri de munca sau sugestii pentru a vă completa CV-ul. De asemenea, folosim datele pe care le primim de la Utilizatorii Candidați pentru a crea facilități de filtrare a Utilizatorilor Candidați pe diverse criterii de interes pentru Utilizatorii Recrutori. Temeiul juridic: art. 6(1)(f) GDPR &ndash; <em>interes legitim</em>. Avem un interes legitim pentru a ne &icirc;mbunătăți serviciile și de furniza sugestii / sfaturi Utilizatorilor Candidați pentru a maximiza succesul serviciilor noastre. Totodată, dorim să asiguram un serviciu de căutare a indicatorilor cat mai eficient pentru Utilizatorii Recrutori.</p>
<ol start="4">
<li><strong>Utilizarea serviciilor de către Utilizatorii Recrutori</strong></li>
</ol>
<p>Prelucram datele cu caracter personal &icirc;n scopul gestiunii relației contractuale sau pre-contractuale cu Utilizatorii Recrutori.</p>
<p>&Icirc;n cazul Utilizatorilor Recrutori (persoane fizice autorizate) temeiul juridic este: art. 6(1)(b) GDPR &ndash; &icirc;ncheierea și executarea contractului sau efectuarea formalităților precontractuale. &Icirc;n cazul datelor cu caracter personal ale reprezentanților Utilizatorilor Recrutori, temeiul juridic este art. 6(1)(f) GDPR &ndash; interes legitim.</p>
<p>Avem un interes legitim să ne desfășuram &icirc;n mod adecvat raporturile contractuale cu clienții noștri persoane juridice. Drepturile și interesele persoanelor fizice (i.e. reprezentanții Utilizatorilor Recrutori) nu sunt prejudiciate &icirc;ntruc&acirc;t din perspectiva clientului persoana juridica, prelucrarea este necesara &icirc;n contextul desfășurării relațiilor de munca / colaborare pe care aceștia o au cu persoanele fizice ale căror date sunt furnizate / asociate contului de Utilizator Recrutor.</p>
<ol start="5">
<li><strong>Gestiunea relațiilor cu Utilizatorii și suport</strong></li>
</ol>
<p>Prelucram o serie de date cu caracter personal pentru scopul gestiunii relațiilor cu Utilizatorii Site-ului spre exemplu atunci c&acirc;nd ne contactează cu diverse &icirc;ntrebări sau solicitări privind funcționalitățile Site-ului, disfuncționalități ale Site-ului sau erori. Temeiul juridic: art. 6(1)(f) GDPR &ndash; interes legitim const&acirc;nd &icirc;n interesul nostru de a asigura servicii adecvate tuturor Utilizatorilor.</p>
<ol start="6">
<li><strong>Furnizarea de comunicări privind Serviciile</strong></li>
</ol>
<p>Utilizatorii pot primi diverse comunicări de la Societate care țin de furnizarea serviciilor (e.g. dacă CV-ul a fost vizualizat de vreun Utilizator Recrutor, joburi salvate care expira, alerte privind necesitatea de actualizare a CV-ului, alerte privind interviuri programate, etc.). Prelucram aceste date pe baza interesului nostru legitim art. 6(1)(f) GDPR. Consideram ca interesul nostru legitim nu aduce atingere drepturilor și intereselor persoanelor vizate &icirc;ntruc&acirc;t comunicările sunt legate str&acirc;ns de furnizarea serviciilor solicitate. Totodată, oferim posibilitatea Utilizatorilor să dezactiveze oric&acirc;nd aceste comunicări din setările aferente contului de Utilizator.</p>
<ol start="7">
<li><strong>Utilizarea serviciilor de geolocalizare</strong></li>
</ol>
<p>Atunci c&acirc;nd folosiți funcția de geolocalizare a terminalului cu care accesați Site-ul, vă vom cere acordul. Temeiul juridic al prelucrării: art. 6(1)(f) GDPR &ndash; consimțăm&acirc;nt</p>
<ol start="8">
<li><strong>Conectare prin retele de socializare</strong></li>
</ol>
<p>Utilizatorii Candidați se pot loga sau crea cont prin intermediul Facebook / Google/ LinkedIn care permite conectarea prin conturile aferente. Atunci cand Utilizatorii Candidați utilizeaza aceasta facilitate, vom utiliza datele publice (e.g. nume, prenume, adresa de e-mail, numar de telefon). Utilizarea acestor date este necesara pentru crearea contului de Utilizator.</p>
<p>De asemenea, am implementat diverse mecanisme de interconectare cu paginile de socializare, cum ar fi Facebook și Youtube si astfel sa aveti posiblitatea sa accesati mai facil continutul postat de noi pe conturile noastre asociate acelor retele sociale.</p>
<p>Daca accesati contintul postat sau comentati pe acele retele de socializare, o serie de date publice din profilul dvs. de pe acele retele de socializare ne vor fi transmise si noua de catre operatorii acelor retele de socializare.</p>
<p>Temeiul juridic: art. 6 (1) (b) din GDPR, incheierea si executarea contractului (in cazul datelor de login Facebook / Google / LinkedIn) sau art. 6(1)(a) GDPR - consimtamant (in celelalte situatii).</p>
<ol start="9">
<li><strong>Pentru organizarea de campanii de promovare</strong></li>
</ol>
<p>Din c&acirc;nd &icirc;n c&acirc;nd, vom organiza campanii de promovare a produselor și serviciilor noastre prin derularea de diverse operațiuni de marketing, cum ar fi oferirea de produse sau servicii cu titlu promoțional.</p>
<p>&Icirc;n funcție de timpul de campanie desfășurată, este posibil ca pentru anumite campanii să prelucrăm datele dumneavoastră, cum ar fi nume și prenume, pentru a putea derula campania &icirc;n condiții conforme.</p>
<p>Astfel de prelucrări se vor realiza &icirc;n baza <em>interesului nostru legitim</em> de promovare a activității comerciale pe care o desfășurăm și nu vom prelucra date care exced scopului prelucrării respective.</p>
<ol start="10">
<li><strong>Pentru transmiterea de comunicări comerciale </strong></li>
</ol>
<p>De asemenea, orice vizitator de pe Site are posibilitatea de a opta pentru primirea pe email de newslettere privind produsele și serviciile noastre, caz &icirc;n care vom prelucra datele dumneavoastră <em>&icirc;n baza acordului dumneavoastră expres de a vă abona</em> la astfel de newslettere și de a vă fi prelucrate datele &icirc;n acest scop.</p>
<p>Vă puteți dezabona oric&acirc;nd de la primirea de astfel de comunicări comerciale prin simpla accesare a butonului de dezabonare inclus &icirc;n fiecare dintre e-mail-urile noastre prin care transmitem comunicări comerciale.</p>
<p><strong>INEXISTENȚA UNUI PROCES DECIZIONAL BAZAT EXCLUSIV PE PRELUCRAREA AUTOMATĂ, INCLUSIV CREAREA DE PROFILURI</strong></p>
<p>Societatea NU utilizează datele dumneavoastră pentru a crea profiluri prin mijloace exclusiv automate sau pentru a lua decizii ce vă pot afecta, exclusiv &icirc;n baza unor prelucrări automate a datelor dumneavoastră.</p>
<p><strong>CU CINE &Icirc;MPĂRTĂȘIM DATELE DUMNEAVOASTRĂ</strong></p>
<p>Datele dumneavoastră cu caracter personal vor fi divulgate angajatilor Societății, departamentului IT precum și reprezentanților legali ai Societății, exclusiv in limita necesară activităților și scopurilor pentru care prelucrăm datele cu caracter personal ce vă vizează.</p>
<p>Putem dezvălui datele dvs cu caracter personal către (i) entitățile și/sau persoanele &icirc;mputernicite de noi (din EEA sau din state terțe) implicate &icirc;n furnizarea serviciilor inclusiv &icirc;n furnizarea comunicărilor comerciale (cum ar fi furnizorilor de centre de date, furnizori de servicii de plata pentru diverse facilități de plata &icirc;n Site,; (ii) Utilizatorilor Recrutori inclusiv dacă este cazul celor situați &icirc;n afara EEA. De asemenea, datele dvs cu caracter personale pot fi dezvăluite terților furnizori de cookie-uri și tehnologii similare conform celor descrise &icirc;n Politica privind Cookie-urile.</p>
<p><strong>Autorități publice și de aplicare a legii </strong></p>
<p>Autorități publice centrale sau locale, precum și organele de poliție și alte agenții sau instituții publice vor putea uneori să ne solicite să le punem la dispoziție diverse date, inclusiv date ce vă privesc. Aceste solicitări pot fi pentru o serie de scopuri, precum verificarea &icirc;ndeplinirii obligațiilor fiscale de către noi, investigarea pl&acirc;ngerilor, precum și &icirc;n scopul de prevenire sau detectare a infracțiunilor, fraudei sau operațiunilor de spălare a banilor.</p>
<p><strong>Conținut disponibil public</strong></p>
<p>Conținutul pe care &icirc;l generați prin intermediul paginilor noastre existente pe rețelele de socializare, precum Facebook, Youtube, LinkedIn, &icirc;n situații precum atunci c&acirc;nd dați &ldquo;<em>like</em>&rdquo; paginii 3 Minutes Job sau postărilor noastre sau c&acirc;nd postați comentarii, date precum numele dumneavoastră de utilizator, imaginea/poza sau locul &icirc;n care vă situați vor fi disponibile și vor putea fi vizualizate de oricine vizitează paginile Societatii existente pe rețelele de socializare. Dumneavoastră sunteți cel/cea care controlați la orice moment datele cu caracter personal la care putem avea acces prin intermediul setărilor existente pe rețeaua de socializare &icirc;n cauză.</p>
<p>Orice transmitere de date &icirc;n condițiile de mai sus se va face &icirc;n situații concrete, limitat la obiectivul prelucrării și &icirc;n conformitate cu prevederile privind prelucrarea datelor cu caracter personal.</p>
<p><strong>C&Acirc;T TIMP STOCĂM DATELE DUMNEAVOASTRĂ</strong></p>
<p>Păstrăm datele dvs cu caracter personal cat este necesar pentru &icirc;ndeplinirea scopurilor pentru care au fost colectate, cu respectarea procedurilor interne privind retenția datelor, inclusiv a regulilor de arhivare aplicabile.</p>
<p>&Icirc;n general, Datele Utilizatorilor Candidați sunt păstrate at&acirc;t timp cat acesta are un cont de Utilizator &icirc;n Site. Datele cu caracter personal vor fi șterse dacă Utilizatorul Candidat solicita dezactivarea și ștergerea contului, sau după o perioada de minim 5 ani de la data la care Utilizatorul Candidat devine inactiv (i.e. de la data ultimei interacțiuni &icirc;n Site).</p>
<p>Datele Utilizatorilor Recrutori (persoane fizice autorizate și reprezentanți ai persoanelor juridice) vor fi păstrate pe &icirc;ntreaga durata a raporturilor contractuale cu Utilizatorul Recrutor plus pe &icirc;ntreaga perioada prevazuta de lege pentru activitatea de arhivare și păstrare a documentelor financiar -contabile.</p>
<p>Datele aferente cookie-urilor și tehnologiilor similare sunt păstrate conform termenelor specifice setate pentru respectivele tehnologii, termenul de stocare put&acirc;nd fi intre durata sesiunii de navigare (pentru cookie-uri de sesiune) și o durata de pana la 2 ani (pentru cookie-uri de analiza).</p>
<p><strong>CE DREPTURI AVEȚI</strong></p>
<p><strong>Drept de acces</strong> &ndash; aveți dreptul ca, &icirc;n orice moment, să știți ce date prelucrăm cu privire la dumneavoastră precum și informații cu privire la modul &icirc;n care vă prelucrăm datele.</p>
<p><strong>Dreptul la rectificare</strong> &ndash; &icirc;n cazul &icirc;n care constatați că datele pe care le avem cu privire la dumneavoastră sunt incorecte sau incomplete, aveți dreptul, &icirc;n orice moment, să ne solicitați rectificarea, respectiv completarea, datelor inexacte sau incomplete care vă privesc.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p><strong>Dreptul la ștergerea datelor (&rdquo;Dreptul de a fi uitat&rdquo;)</strong> &ndash; aveți dreptul de a ne solicita ștergerea datelor cu caracter personal pe care le avem cu privire la dumeavoatră. Menționăm, &icirc;nsă, că dreptul de a fi uitat nu este un drept absolut, acesta aplic&acirc;ndu-se doar &icirc;n anumite situații.&nbsp; Astfel, &icirc;nainte de a da curs cererii dumneavoastră, o vom analiza și vă vom informa cu privire la rezultat.</p>
<p><strong>Dreptul la restricționare a prelucrării</strong> &ndash; &icirc;n anumite circumstanțe, ne puteți cere să restricționăm modul &icirc;n care prelucrăm datele dumneavoastră. Aveți dreptul de a solicita restricționarea prelucrării atunci c&acirc;nd aveți un motiv anume pentru a dori restricționarea. Acesta poate fi, spre exemplu, &icirc;ntruc&acirc;t contestați exactitatea datelor pe care le prelucrăm cu privire la dumneavoastră sau contestați modul &icirc;n care am efectuat prelucrarea.</p>
<p><strong>Drept de portabilitate</strong> <strong>a datelor</strong> - &icirc;n anumite situații, ne puteți solicita să transmitem datele ce vă aparțin către o entitate indicată de dumneavoastră.</p>
<p><strong>Aveți dreptul a vă opune prelucrării datelor care vă privesc</strong></p>
<p>&Icirc;n orice moment, aveți dreptul de a vă opune prelucrării datelor dumneavoastră cu caracter personal realizată &icirc;n baza unui interes legitim, din motive legate de situația dumneavoastră particulară. Menționăm că exercitarea dreptului de opoziție nu va determina &icirc;n mod automat &icirc;ncetarea prelucrării. O analiză va fi realizată cu privire la motivele pentru care vă exprimați opoziția, raportat la motivele care ar putea justifica continuarea prelucrării. Vă vom comunica rezultatul analizei noastre și măsurile pe care urmează să le luăm ca urmare a analizei realizate.</p>
<p>Atunci c&acirc;nd prelucrarea se va realiza &icirc;n baza acordului dumneavoastră expres, veți avea <strong>dreptul de a vă retrage</strong> <strong>la orice moment consimțăm&acirc;ntul</strong> cu privire la prelucrarea datelor care vă privesc.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>PENTRU A VĂ EXERCITA ORICARE DINTRE DREPTURILE MENȚIONATE MAI SUS, inclusiv dreptul de acces la date, dreptul la opoziție sau la retragerea consimțăm&acirc;ntului, după caz, dreptul la rectificarea, la ștergerea datelor sau la restricționare prelucrării</strong>, vă rugăm să ne contactați prin intermediul formularului de contact existent pe Site, pe email, la adresa [&bull;] sau printr-o solicitarea transmisă la sediul nostru din Brașov, strada Ioan V. Socec, nr. 22N, județul Brașov. Formularele de exercitare a drepturilor persoanelor vizate recunoscute de GDPR sunt disponibile pe Site.</p>
<p><strong>&nbsp;</strong></p>
<p><strong>AVEȚI DREPTUL DE A DEPUNE PL&Acirc;NGERE &Icirc;N FAȚA UNEI AUTORITĂȚI DE SUPRAVEGHERE</strong></p>
<p>&Icirc;n cazul &icirc;n care considerați că prelucrarea datelor cu caracter personal care vă vizează &icirc;ncalcă prevederile legale &icirc;n vigoare, fără a fi afectate orice alte căi de atat administrative sau judiciare, aveși dreptul de a depune o pl&acirc;ngere la autoritatea de supraveghere, &icirc;n special &icirc;n statul membru &icirc;n care aveți reședința obișnuită, &icirc;n care se află locul de muncă sau &icirc;n care a avut loc presupusa &icirc;ncălcare.</p>
<p>&nbsp;</p>
<p>Datele de contact ale <strong>Autorității de Supraveghere a Prelucrării Datelor cu Caracter Personal</strong> din Rom&acirc;nia sunt următoarele:</p>
<table>
<tbody>
<tr>
<td width="72">
<p>Adresa:</p>
</td>
<td width="385">
<p>B-dul G-ral. Gheorghe Magheru 28-30 Sector 1, cod postal 010336, Bucuresti,&nbsp;Romania &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
</td>
</tr>
<tr>
<td width="72">
<p>Email:</p>
</td>
<td width="385">
<p><a href="mailto:anspdcp@dataprotection.ro">anspdcp@dataprotection.ro</a></p>
</td>
</tr>
<tr>
<td width="72">
<p>Telefon</p>
</td>
<td width="385">
<p>+40.318.059.211</p>
</td>
</tr>
<tr>
<td width="72">
<p>Fax</p>
</td>
<td width="385">
<p>+40.318.059.602</p>
</td>
</tr>
<tr>
<td width="72">
<p>Website</p>
</td>
<td width="385">
<p>https://www.dataprotection.ro/</p>
</td>
</tr>
</tbody>
</table>
<p><strong>&nbsp;</strong></p>
<p><strong>LINK-URI CĂTRE ALTE SITE-URI</strong></p>
<p>Site-ul nostru poate conține trimiteri către pagini de internet sau aplicații ale terților. Dacă accesați unul dintre aceste link-uri, rețineți că fiecare dintre ele va avea propria politică de confidențialitate. Nu controlăm aceste pagini de internet/aplicații și nu suntem responsabili pentru aceste politici. C&acirc;nd părăsiți Site-ul nostru, vă &icirc;ncurajăm să citiți politica de confidențialitate a fiecărei pagini de internet pe care o vizitați.</p>
<p>&nbsp;</p>
<p><strong>MODIFICAREA POLITICII DE CONFIDENȚIALITATE</strong></p>
<p>Pentru asigurarea transparenței și pentru o informare c&acirc;t mai corectă și completă cu privire la modul &icirc;n care Societatea prelucrează datele dumneavoastră, vom revizui prezenta Politică de Confidențialitate constant, pentru a ne asigura că aceasta este actualizată și corectă.</p>
<p>Prezenta Politică de Confidențialitate a fost redactată la data de <em>04.10.2019</em>.</p>
                </div>
                <div class="tab-pane fade" id="terms" role="tabpanel" aria-labelledby="terms-tab">
                  <p><strong>TERMENI ȘI CONDITII</strong></p>
<ol>
<li><strong>Informații generale </strong></li>
</ol>
<p>Prezentul document (&bdquo;<strong>Termeni și Condiți</strong>i&rdquo;) guvernează raporturile dintre societatea <strong>3 MINUTES JOB S.R.L.</strong>, o societate cu răspundere limitata, cu sediul &icirc;n Brașov, strada Ioan V. Socec, nr. 22N, județul Brașov, &icirc;nregistrată la Oficiul Registrului Comerțului de pe l&acirc;ngă Tribunalul Brașov sub numărul &nbsp;J8/1509/2019, cod unic de &icirc;nregistrare 41018510 (&bdquo;<strong>Societatea</strong>&rdquo;) și persoanele care utilizează (&bdquo;<strong>Utilizatorul</strong>&rdquo;) pagina de internet www.3minutesjob.com (&bdquo;<strong>Site-ul</strong>&rdquo;) și/sau serviciile oferite de Societate prin intermediul Site-ului (&bdquo;<strong>Serviciile</strong>&rdquo;).&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>Accesarea și utilizarea Site-ului de către Utilizatori este condiționată de respectarea, de către aceștia, a prezentului document și a prevederilor legale aplicabile. Folosirea &icirc;n orice mod, accesarea, vizitarea și/sau vizualizarea conținutului Site-ului, respectiv contractarea Serviciilor, implică aderarea Utilizatorului la acești &rdquo;Termeni și Condiții&rdquo;, cu excepția cazului &icirc;n care Utilizatorul a fost &icirc;n prealabil informat că, pentru conținutul respectiv, se aplică condiții de utilizare distincte.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>DACĂ NU SUNTEȚI DE ACORD CU PREVEDERILE DIN ACEST DOCUMENT, VĂ RUGĂM SĂ NU UTILIZAȚI, SAU DUPĂ CAZ, SĂ &Icirc;NCETAȚI UTILIZAREA SITE-ULUI SAU A SERVICIILOR OFERITE DE SOCIETATE, DUPĂ CAZ.</p>
<p>Societatea poate revizui acest document oric&acirc;nd, prin acutalizarea acestei pagini. Vă recomandăm să vizitați această pagina periodic pentru a citi "Termenii și Condițiile" aplicabili. Folosirea &icirc;n continuare a serviciilor Site-ului reprezintă acceptarea modificarilor aduse prezentului document.</p>
<ol start="2">
<li><strong>Servicii</strong></li>
</ol>
<p>Serviciile oferite de Societate se adresează (i) Utilizatorilor, persoane fizice, care vizitează Site-ul, (ii) Utilizatorilor care caută un loc de muncă (&bdquo;<strong>Utilizatori Candidați</strong>&rdquo;) și (iii) Utilizatorilor, persoane juridice sau persoane fizice autorizate, care postează locuri de muncă vacante sau caută Utilizatori Candidați pentru anumite locuri de munca vacante (&bdquo;<strong>Utilizatori Recrutori</strong>&rdquo;).&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>La acest moment, Serviciile sunt oferite cu titlu gratuit. Disponibilitatea Serviciilor cu titlu gratuit este limitată &icirc;n timp, obiectivul Societății fiind acela de a permite Utilizatorilor acomodarea cu aceste Servicii și cu modul de funcționare a Site-ului. Societatea &icirc;și rezerva dreptul unilateral de a limita oric&acirc;nd posibilitatea folosirii gratuite a Serviciilor / a unei categorii de Servicii.</p>
<ul>
<li><strong>Crearea Contului</strong></li>
</ul>
<p>Anumite Servicii sunt disponibile pe Site fără a fi necesară crearea unui cont de utilizator (&rdquo;<strong>Contul</strong>&rdquo;).</p>
<p>Pentru a avea acces la toate Serviciile oferite de Societate prin intermediul Site-ului, Utilizatorii (at&acirc;t Utilizatorii Candidați c&acirc;t și Utilizatorii Recrutori) trebuie să &icirc;și configureze un Cont, prin completarea formularului disponibil pe Site. &nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>Pentru crearea Contului, Societatea va solicita Utilizatorilor să &icirc;și exprime acordul cu privire la acești Termeni și Condiții precum și să confirme citirea și luarea la cunoștință a Politicii de confidențialitate privind protecția datelor cu caracter personal. Acceptul Utilizatorilor se va realiza prin bifarea căsuțelor specifice, respectiv:</p>
<ul>
<li>Am citit și accept Termenii și Condițiile</li>
<li>Am citit și am luat cunoștință de Politica de confidențialitate</li>
</ul>
<p>Prin bifarea căsuței referitoare la Termeni și Condiții, Utilizatorii &icirc;nțeleg că au &icirc;ncheiat un contract de prestări servicii cu societatea <strong>3 MINUTES JOB S.R.L. (&bdquo;Contractul&rdquo;), </strong>care va fi guvernat de prezentul document, Societatea av&acirc;nd calitatea de prestator iar Utilizatorii av&acirc;nd calitatea de beneficiari.</p>
<p>Prin bifarea căsuței referitoare la Politica de confidențialitate, Utilizatorii &icirc;nțeleg că Societatea le-a respectat dreptul acestora de a fi informați &icirc;n legătură cu datele lor cu caracter personal prelucrate de Societate, &icirc;n conformitate cu prevederile articolelor 13 și 14 din Regulamentul General privind Protecția Datelor.</p>
<p>&Icirc;n cazul Utilizatorilor Candidați, crearea Contului se poate realiza și prin intermediul Facebook / LinkedIn / Google, caz &icirc;n care Site-ul vă prelua anumite date publice ale Utilizatorului Candidat aferente contului său de Facebook / LinkedIn / Google.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>.</p>
<ul>
<li><strong>Responsabilitățile Utilizatorilor</strong></li>
</ul>
<p>Prin crearea Contului, Utilizatorii confirmă că: i) au furnizat date adevarate, exacte și complete așa cum este cerut de formularul de &icirc;nregistrare pentru crearea Contului și ii) vor actualiza, atunci cand este necesar, datele de &icirc;nregistrare pentru a fi adevarate, exacte și complete.</p>
<p>Totodată, Utilizatorii se obligă să nu &icirc;ntreprindă una sau mai multe dintre următoarele acțiuni:</p>
<ul>
<li>publicarea pe Site a unui material cu drept de autor, daca nu sunt autorul sau daca nu au permisiunea autorului de a publica materialul respectiv;</li>
<li>publicarea pe Site a unor materiale obscene, defăimatoare, de amenințare sau răuvoitoare față de un alt Utilizator, persoană fizica sau juridică;</li>
<li>publicarea pe Site a unei imagini sau afirmații explicit sexuală;</li>
<li>publicarea pe Site a unor materiale care conțin virusi, sau alte programe cu intenția de a distruge orice sistem sau informație;</li>
<li>&icirc;ncărcarea, postarea, difuzarea sau transmiterea &icirc;n alt mod a oricărui conținut pentru care nu au dreptul legal de transmitere sau difuzare &icirc;n orice condiții;</li>
<li>&icirc;ncărcarea, difuzarea sau transmiterea &icirc;n alt mod, a oricărui tip de publicitate, materiale promoționale, "junk mail", "spam", scrisori &icirc;n lanț, scheme piramidale, sau orice altă formă de solicitări;</li>
<li>crearea sau deținerea de adrese multiple &icirc;n cadrul Site-ului care se aseamăna sau care sunt create pe aceeali tema;</li>
<li>promovarea sau furnizarea de informații despre modul de derulare a activitătilor ilegale, promovarea actele de rănire fizică sau verbală &icirc;mpotriva oricărui grup sau persoană sau promovarea oricărui act de cruzime față de animale;</li>
<li>postarea, difuzarea sau transmiterea &icirc;n alt mod de date cu caracter personal (nume, adresa, numere de telefon) sau &icirc;nregistrarea acestora &icirc;n rubricile care pot fi vizualizate de catre ceilalți Utilizatori;</li>
<li>oricare alte acțiuni / fapte care ar putea prejudicia Societatea / angajații, colaboratorii sau partenerii de afaceri ai acesteia.</li>
</ul>
<p>Utilizatorul este unic răspunzător pentru toate activitățile care decurg din folosirea Site-ului/conținutului acestuia. Utilizatorul va răspunde pentru &icirc;ncălcarea prezentului document și utilizarea Site-ului cu nerespectarea acestuia, pentru &icirc;ncălcarea drepturilor Societatii și pentru orice daune provocate Site-ului, conținutului acestuia, societății 3 Minutes Job S.R.L. sau oricărui colaborator sau partener contractual al acesteia, indiferent de natura daunelor astfel produse, fiind obligat la acoperirea &icirc;ntregului prejudiciu astfel produs. &Icirc;n cazul &icirc;n care Societatea descoperă neregularități și/sau &icirc;ncălcări de orice fel a prevederilor din prezentul document care conduc la prejudicierea sau perturbarea &icirc;n orice fel a activității Societății (cum ar fi furnizarea de informații care nu sunt conforme cu realitatea, inexacte sau incomplete, publicarea unui conținut pornografic/erotic, furt de identitate / profil fals, spam și limbaj inadecvat, etc.), societatea 3 Minutes Job S.R.L. &icirc;și rezervă dreptul, la libera sa alegere, de a anula, limita, restricționa, suspenda sau bloca accesul Utilizatorului pe Site. &Icirc;n acest caz, Utilizatorul se poate adresa Societatii, la datele de contact indicate pe Site, pentru a primi justificările necesare &icirc;n legătură cu măsurile adoptate &icirc;n sensul prezentului articol și data de la care acestea au fost sau vor fi aplicate.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<ul>
<li><strong>Utilizatori Candidați </strong></li>
</ul>
<p>Utilizatorii Candidați pot vizualiza informațiile publicate pe Site fără a fi necesară crearea unui Cont &icirc;n acest sens. Pentru Servicii ca &icirc;ncărcarea unui CV sau pentru generarea CV-ului pe Site, Utilizatorii Candidați trebuie să &icirc;și configureze &icirc;n prealabil un Cont, prin completarea formularului disponibil pe Site. Utilizatorii Candidați &icirc;și pot crea Cont doar dacă au la data creării Contului cel puțin v&acirc;rsta de 16 ani. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>Prin intermediul Site-ului, Utilizatorii Candidați &icirc;și pot &icirc;ncărca CV-ul sau &icirc;și pot crea CV-ul prin completarea formularului disponibil pe Site la rubrica &bdquo;Editare CV&rdquo;. Pentru &icirc;ncărcarea prezentărilor video precum și a fotografiilor, Utilizatorii Candidați trebuie să &icirc;și exprime consimțăm&acirc;ntul pentru prelucrarea de către Societate a acestor categorii de date. &Icirc;n cazul &icirc;n care Utilizatorii Candidați nu &icirc;și exprimă consimțăm&acirc;ntul prin bifarea căsuțelor specifice, &icirc;ncărcarea prezentărilor video / fotografiilor nu va putea fi permisă.</p>
<p>CV-urile &icirc;ncărcate &icirc;n Site / generate pe Site, precum și datele de contact ale Utilizatorilor pot apoi fi făcute disponibile Utilizatorilor Recrutori pentru scop de recrutare. &Icirc;n acest sens, Utilizatorii Candidați au două opțiuni:&nbsp;&nbsp;</p>
<p><strong><em>Pot &icirc;ncărca / genera CV-uri pe Site fără ca datele cuprinse &icirc;n acestea să fie disponibile Utilizatorilor Recrutori </em></strong></p>
<p>La momentul creării Contului, &icirc;n mod automat, datele din CV-urile Utilizatorilor Candidați sunt setate ca fiind confidențiale, aceștia put&acirc;nd ulterior să le faca publice prin bifarea căsuței &bdquo;Profil public&rdquo;. &Icirc;n cazul &icirc;n care setarea răm&acirc;ne ca CV-ul sa fie confidențial, Utilizatorii Candidați pot aplica pentru job-uri, &icirc;nsă CV-ul acestora (inclusiv datele de contact ale Utilizatorilor Candidați) nu va putea fi vizualizat dec&acirc;t de Utilizatorii Recrutori la ale căror anunțuri au aplicat&nbsp; / vor aplica. &nbsp;</p>
<p><strong><em>Pot face public CV-ul pe Site</em></strong></p>
<p>&Icirc;n cazul &icirc;n care Utilizatorul Candidat a optat pentru varianta ca CV-ul său să fie public prin bifarea căsuței &bdquo;Profil public&rdquo;, Utilizatorii Recrutori vor avea acces la CV-ul respectivului Utilizator Candidat (fără a avea acces și la date de contact). Dacă vor dori, Utilizatorii Recrutori vor putea solicita Societății deblocarea datelor de contact ale Utilizatorilor Candidați care au optat pentru publicitatea CV-ului și vor putea contacta direct respectivii Utilizatori Candidați.</p>
<p>Utilizatorii Candidați pot oric&acirc;nd să modifice opțiunea și să &icirc;și facă CV-ul confidențial prin debifarea căsutei &bdquo;Profil public&rdquo;. De asemenea, Utilizatorii Candidați pot oric&acirc;nd să &icirc;și dezactiveze CV-ul din baza noastră de date sau, dacă doresc, să isi ștearga Contul. &Icirc;n cazul &icirc;n care Utilizatorii Candidati &icirc;și setează CV-ul ca fiind confidențial, &icirc;și dezactivează CV-ul din baza de date sau &icirc;și șterg Contul, Utilizatorii Recrutori nu vor mai avea acces la datele acestora. Totuși, ca parte a Serviciilor furnizate de Site, Utilizatorii Recrutori care au accesat facilitatea de acces la CV-uri pot descarcă sau transmite informația disponibilă pe Site pe propriile medii de stocare. &Icirc;n acest caz, chiar dacă Societatea va șterge datele Utilizatorilor Candidati din baza de date, Utilizatorii Recrutori vor putea avea acces &icirc;n continuare la datele acestora &icirc;n cazul &icirc;n care le-au descarcat, copiat sau extras din baza de date disponibilă pe Site. &Icirc;n calitatea lor de operatori, Utilizatorii Recrutori sunt &icirc;n mod independent responsabili de modul &icirc;n care utilizează datele cu caracter personal ale Utilizatorlor Candidadti pe care le accesează prin intermediul Site-ului.&nbsp;&nbsp;&nbsp;</p>
<p>Utilizatorii Candidați trebuie să &icirc;nțeleagă că unele societăți care au ales să &icirc;și facă publice anunțurile de recrutare prin intermediul Site-ului pot prezenta o realitate &icirc;nșelătoare, incompleta, incorecta sau agresiv orientată spre alte categorii sociale. Societatea, prin intermediul prezentelor reguli, impune corectitudine din partea Utilizatorilor, &icirc;nsă este posibil ca nu toți Utilizatorii Recrutori să &icirc;nțeleagă să respecte aceste reguli. Astfel, Utilizatorii Candidați trebuie să discearnă &icirc;nainte de a folosi informația cuprinsă &icirc;n anunțurile de recrutare &icirc;nainte a merge la un interviu, de a se angaja sau de a realiza o investiție sau orice altă acțiune care implică folosirea de fonduri sau resurse proprii sau ale unor terți. Vă rugam să ne contactați &icirc;n situația &icirc;n care aflați de existenta unor astfel de anunțuri.</p>
<ul>
<li><strong>Utilizatori Recrutori </strong></li>
</ul>
<p>Utilizatorii Recrutori pot vizualiza CV-urile publice de pe Site, fără a avea acces la datele de contact ale Utilizatorilor Candidați, fără a fi necesară crearea unui Cont &icirc;n acest sens. Pentru Servicii ca publicarea anunțurilor de recrutare și vizualizarea datelor de contact ale Utilizatorilor Candidați, Utilizatorii Recrutori trebuie să &icirc;și configureze &icirc;n prealabil un Cont, prin completarea formularului disponibil pe Site.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p><strong><em>Publicarea anunțurilor de recrutare&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </em></strong></p>
<p>La acest moment, anunțurile de recrutare pot fi publicate pe Site &icirc;n mod gratuit. La momentul la care Societatea va decide ca publicarea anunțurilor de recrutare să fie condiționată de plata unui preț / achiziționarea unui abonament, Utilizatorii Recrutori vor fi obligați să achite respectivul preț / abonament &icirc;n termen de 5 zile lucrătoare, sub sancțiunea ștergerii / eliminării anunțului de recrutare de pe Site.</p>
<p>Decizia de a condiționa publicarea anunțurilor de recrutare de plata unui preț aferent / abonament aparține &icirc;n totalitate Societății, prezentul document să fie modificat &icirc;n consecință iar Utilizatorii Recrutori vor fi informați &icirc;n legătură cu această modificare prin intermediul informațiilor disponibile pe Site. Termenul de 5 zile lucrătoare menționat mai sus curge de la data la care forma actualizată a prezentului document (cu informații referitoare la preț și modalitate de plată pentru Servicii) este disponibil pe Site. Utilizatorii Recrutori nu pot invoca faptul că nu au avut cunoștință de obligația de achitare a prețului, av&acirc;nd obligația continua de a verifica conținutul prezentului document, conform celor menționate &icirc;n partea introductivă a acestuia.</p>
<p>De asemenea, Societatea &icirc;și rezervă dreptul de a refuza spre publicare sau de a cenzura &icirc;n orice alt fel anunțurile de recrutare care sunt contrare legii sau bunelor moravuri, sau care conțin un limbaj licențios. Spre exemplu, Societate nu va accepta spre publicare anunțuri de operatoare video-chat, masaj erotic sau altele asemănătoare.</p>
<p>&Icirc;n acest sens, Utilizatorii Recrutori acceptă și sunt de acord cu faptul ca vor respecta regula bunei credințe atunci c&acirc;nd concepep și publică anunțul de recrutare. Astfel, vă obligați să nu publicați anunțuri incomplete, incorecte sau discriminatorii și să nu condiționați ulterior selecția Utilizatorilor Candidați dec&acirc;t de acele reguli prezentate &icirc;n anunțul de recrutare publicat de respectarea legislației aplicabile incidente.</p>
<p>&Icirc;n situația &icirc;n care nu veți respecta aceste reguli, &icirc;nțelegeți și sunteți de acord cu faptul ca Societatea are dreptul de a vă restricționa accesul la Serviciile sale.</p>
<p><strong><em>Căutarea CV-urilor postate pe Site&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </em></strong></p>
<p>Utilizatorii Recrutori pot căuta prin toate CV-urile publice postate pe Site. Aceștia pot vizualiza un număr nelimitat de CV-uri făra date de contact ale Utilizatorilor Candidați. &Icirc;n ceea ce privește vizualizarea datelor de contact ale Utilizatorilor Candidați, Utilizatorii Recrutori pot vizualiza datele de contact aferente unui anumit număr de CV-uri, conform solicitărilor adresate &icirc;n acest sens Societății.</p>
<p>La acest moment, datele de contact ale Utilizatorilor Candidați pot fi vizualizate &icirc;n mod gratuit. La momentul la care Societatea va decide ca vizualizarea datelor de contact ale Utilizatorilor Candidați să fie condiționată de plata unui preț / achiziționarea unui abonament, Utilizatorii Recrutori nu vor mai putea avea acces la date de contact ale ale Utilizatorilor Candidați, dec&acirc;t după achitarea respectivul preț / abonament &icirc;n contul Societății.</p>
<p>Decizia de a condiționa vizualizarea datelor de contact ale Utilizatorilor Candidați de plata unui preț aferent / abonament aparține &icirc;n totalitate Societății, prezentul document să fie modificat &icirc;n consecință iar Utilizatorii Recrutori vor fi informați &icirc;n legătură cu această modificare prin intermediul informațiilor disponibile pe Site. Utilizatorii Recrutori nu pot invoca faptul că nu au avut cunoștință de obligația de achitare a prețului aferent serviciului de vizualizare a datelor de contact ale Utilizatorilor Candidați, av&acirc;nd obligația continua de a verifica conținutul prezentului document, conform celor menționate &icirc;n partea introductivă a acestuia.</p>
<p>&Icirc;n toate cazurile, &icirc;nțelegeți și sunteți de acord cu faptul ca nu veți folosi datele cuprinse &icirc;n CV-urile Utilizatorilor Candidați dec&acirc;t pentru scopul unic de recrutare de personal pentru societatea care a publicat anunțul de recrutare.</p>
<p>Utilizatorii Recrutori trebuie să &icirc;nțeleagă că unii candidați care au ales să &icirc;și introducă CV-urile &icirc;n baza de date a Site-ului pot prezenta o realitate &icirc;nșelătoare, incompleta, incorectă sau agresiv orientată spre alte categorii sociale. Societatea, prin intermediul prezentelor reguli, impune corectitudine din partea Utilizatorilor, &icirc;nsă este posibil ca nu toți Utilizatorii Candidați să &icirc;nțeleagă să respecte aceste reguli. Astfel, Utilizatorii Recrutori trebuie să discearnă &icirc;nainte de a folosi informația cuprinsă &icirc;n aceste CV-uri &icirc;nainte a face o angajare, o investiție sau orice altă acțiune care implica folosirea de fonduri sau resurse proprii sau ale unor terți. Vă rugam să ne contactați &icirc;n situația &icirc;n care aflați de existenta unor astfel de CV-uri.</p>
<ol start="3">
<li><strong>Limitarea răspunderii</strong></li>
</ol>
<p>Societatea nu garantează credibilitatea, acuratețea informațiilor publicate de către Utilizatori sau nu gireaza vreo parere exprimată de către Utilizatori.&nbsp;Societatea&nbsp;acționează ca un factor pasiv &icirc;n distribuția online și publicarea informațiilor furnizate de către Utilizatori. Dacă verificarea anumitor informații publicate pe Site va fi solicitată de către un Utilizator, Societatea va investiga și verifica afirmațiile respective și va hotăr&icirc;, după cum va crede de cuviință, dacă informațiile respective trebuie &icirc;ndepărtate de pe Site sau se impune adoptarea vreunei alte măsuri.</p>
<p>Utilizatorii iau la cunoștință și sunt de acord cu faptul ca aceștia sunt singurii responsabili pentru forma, conținutul sau exactitatea informațiilor plasat de aceștia pe Site precum și pentru verificarea exactității acestor informații prin mijloace proprii. Societatea nu verifică anunțurile / CV- urile &icirc;n ceea ce privește corectitudinea sau caracterul complet al acestora și nu garantează pentru corectitudinea sau caracterul complet al acestora / opiniilor exprimate de Utilizatori.</p>
<p>Societatea nu este implicată &icirc;n relația dintre Utilizatorii Candidați și Utilizatorii Recrutori, nici &icirc;n calitate de intermediar, nici &icirc;n calitate de parte contractuală sau reprezentant al unei părti contractuale. Contractele individuale de muncă &icirc;ncheiate ca urmare a anunțurilor de recrutare publicate de Site de Utilizatori Recrutori&nbsp; / CV-urilor &icirc;ncărcate de Utilizatorii Candidați vor fi &icirc;ncheiate și puse &icirc;n executare fără participarea sau responsabilitatea Societății. Părțile contractelor individuale de muncă astfel &icirc;ncheiate vor respecta prevederile legale aplicabile acestor raporturi de muncă, fără ca Societatea să poarte vreo responsabilitate sau să aibă vreo obligație &icirc;n acest sens.</p>
<p>Deoarece identificarea Utilizatorilor pe internet este dificilă, Societatea nu confirmă faptul că fiecare Utilizator este ceea ce se pretinde. Totodată, deoarece Societatea nu poate controla comportamentul Utilizatorilor pe Site, &icirc;n eventualitatea unei dispute cu unul sau mai multi Utilizatori, Societatea (prin administratorii și angajații săi) este absolvită de orice răspundere sau de plata vreunei despăgubiri directă sau indirectă, de orice fel și de orice natură, cunoscută sau necunoscută, suspectată sau nu, dezvaluită sau nu, ivită &icirc;n vreun mod care are legatură cu disputele.</p>
<p>Totodată Utilizatorii au cunoștință și sunt de acord că Societatea nu va fi răspunzatoare pentru nici un fel de pagube directe, indirecte, accidentale, speciale, inclusiv, dar fără a se limita la nerealizare de profit, imposibilitatea folosirii informațiilor de pe Site sau alte pagube (chiar dacă Societatea a fost &icirc;nainte informată de posibilitatea apariției unor asemenea pierderi), rezult&acirc;nd din: i) utilizarea sau imposibilitatea utilizarii serviciilor Site-ului; ii) acces neautorizat la datele dumneavoastra; iii) declarații sau acțiuni a oricărei terțe părti asupra Serviciilor oferite pe Siteș iv) orice altă problemă legată de serviciile Site-ului.</p>
<p>&Icirc;n același timp, Utilizatorii &icirc;nșeleg și sunt de acord cu următoarele afirmații:</p>
<ol>
<li>utilizarea Serviciilor oferite pe Site se face pe propria raspundere;</li>
<li>Serviciile sunt furnizate pe principiul "asa cum sunt" sau "asa cum sunt disponibile";</li>
<li>Societatea nu ofera nici o garantie că:</li>
</ol>
<ul>
<li>Serviciile vor implini toate cerințele Utilizatorilor;</li>
<li>Serviciile vor fi ne&icirc;ntrerupte, la timp, sigure sau fără erori;</li>
<li>Rezultatele ce se vor putea obține din folosirea Site-ului și a serviciilor puse la dispoziție vor fi corecte sau de &icirc;ncredere;</li>
<li>orice eroare de program va fi corectată.</li>
</ul>
<ol>
<li>Orice material descărcat sau obținut &icirc;n alt fel prin utilizarea Serviciilor puse la dispoziție de Societate prin intermediul Site-ului se află la discreția Utilizatorilor, poate fi folosit doar pe riscul propriu iar Utilizatorii vor fi singurii responsabili de eventualele distrugeri cauzate echipaentului / terminaluilui de pe care accesează Site-ul sau de alte pierderi de date ce pot rezulta din descarcărea acestui tip de conținut.</li>
</ol>
<p>&Icirc;n limita prevederilor din prezentul document - Termeni și Condiții, societatea 3 Minutes Jobs S.R.L. nu va putea fi trasă la răspundere pentru eventualele erori apărute pe Site, indiferent de cauză, inclusiv dar fără a se limita la modificări, setări, etc., care nu sunt făcute de către administratorul Site-ului. Societatea nu răspunde pentru prejudiciile produse ca urmare a nefuncționării integrale sau parțiale a Site-ului, nici pentru cele rezult&acirc;nd din imposibilitatea accesării anumitor link-uri publicate pe Site. Totodată, Societatea nu va răspunde de conținutul și/sau calitatea altor website-uri la care se poate ajunge prin diverse legături cu Site-ul Societății. Pentru respectivele website-uri răspunderea integrală va reveni deținătorilor website-urilor respective.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<ol start="4">
<li><strong>Protecția Contului </strong></li>
</ol>
<p>Accesul Utilizatorilor &icirc;n Cont este protejat de un nume de utilizator și o parola. Societatea recomandă Utilizatorilor să nu dezvăluie nimănui numele de utilizator și parola și să iasa din Contul (i.e. prin log off / sign out), la sf&acirc;rșitul fiecărei sesiuni de utilizare.</p>
<p>Utilizatorii sunt răspunzători de păstrarea confidențialității asupra informațiilor și a numelui de utilizator / parolei aferente Contului. Utilizatorii vor fi singurii răspunzători de utilizarea &icirc;nregistrării, indiferent dacă utilizarea s-a realizat cu sau fără voia acestora. Societatea nu va fi răspunzătoare pentru nici o pagubă morală sau materială provocată prin nerespectarea de către Utilizatori a măsurilor de securitate a datelor utilizate pentru accesarea Site-ului.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<ol start="5">
<li><strong>Drepturi de autor </strong></li>
</ol>
<p>Conţinutul Site-ului, incluz&acirc;nd dar fără a se limita la texte, logo-uri, simboluri comerciale, imagini statice sau dinamice, conţinut multimedia, programe, scripturi și orice alte date prezentate pe Site-ul este proprietatea exclusivă a societatii 3 Minutes Job S.R.L., fiind protejat &icirc;n conformitate cu prevederile Legii nr. 8/1996 privind drepturile de autor și drepturile conexe. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>Copierea, distribuirea, publicarea, transferul către terţe părţi, modificarea sau alterarea, utilizarea, expunerea, includerea oricărui conţinut &icirc;n orice alt context dec&acirc;t cel original intenţionat de Societate, includerea oricărui conţinut &icirc;n afara Site-ului, &icirc;ndepărtarea semnelor care semnifică dreptul de autor al Societatii asupra conţinutului precum şi participarea la transferul, v&acirc;nzarea, distribuţia unor materiale realizate prin reproducerea, modificarea sau afişarea conţinutului Site-ului, fără acordul expres scris al Societății, reprezintă o &icirc;ncălcare a drepturilor de autor ale societății 3 Minutes Jobs S.R.L. și va da dreptul acesteia la obligarea persoanelor &icirc;n culpă la plata de despăgubiri, precum și la acoperirea &icirc;ntregului prejudiciu produs Societății.&nbsp;&nbsp;&nbsp;</p>
<p>&Icirc;n ceea ce privește materialele &icirc;ncărcate de Utilizatori pe Site (orice tip de informație furnizată de către Utilizatori prin intermediul Site-ului, cum ar fi dar fără a se limita la date de contact, studii urmate, experiența deținută, condiții de muncă, salarizare, descriere posturilor vacante, inclusiv observațiile, sugestiile, feed-back-ul Utilizatorilor etc.), Societatea nu va pretinde un drept de proprietate asupra acestora. Totuși, prin publicarea acestor materiale pe Site, Utilizatorii sunt de acord să transmită Societății 3 Minutes Job S.R.L. permisiunea de a utiliza materialele, respectiv dreptul de a le copia, distribui, transmite, publica, reproduce, modifica si traduce, de menționa numele Utilizatorilor &icirc;n legătură cu materialui transmis / &icirc;ncărcat pe Site. Utilizatorii nu vor pretinde iar Societatea nu va acorda niciun fel de compensații pentru utilizarea materialului &icirc;n felul arătat mai sus.</p>
<ol start="6">
<li><strong>Dispoziții finale </strong></li>
</ol>
<p>Prezentul document, conţinutul acestuia, Site-ul, Serviciile precum şi orice conflict care ar putea apărea &icirc;ntre Utilizator și Societate vor fi interpretate &icirc;n conformitate cu legislaţia din Rom&acirc;nia. Toate disputele vor fi soluţionate mai &icirc;nt&acirc;i pe cale amiabilă, de comun acord. Dacă aceasta nu va fi posibil, conflictul va fi soluţionat de către instanţa competentă de la sediul Societatii.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p>&Icirc;n cazul &icirc;n care orice dispoziţie de mai sus este declarată nulă sau inaplicabilă de către o instanţă, un tribunal arbitral sau de orice altă autoritate competentă, dispoziţia nulă sau inaplicabilă va fi eliminată din document, celelalte dispoziţii urm&acirc;nd a răm&acirc;ne &icirc;n vigoare.&nbsp;&nbsp;</p>
<p>Societatea &icirc;și rezervă dreptul de a modifica &icirc;n mod unilateral Site-ul/structura acestuia oric&acirc;nd va considera de cuviință, fără notificarea și/sau acordul prealabil al Utilizatorilor, precum și de a introduce bannere publicitare de orice natură și/sau legături pe oricare pagină a Site-ului, cu respectarea legislației &icirc;n vigoare.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
                </div>
                <div class="tab-pane fade" id="legal" role="tabpanel" aria-labelledby="legal-tab">
                  <h4>Prelucrarea datelor</h4>
                  <ul>
                    <li><a href="/assets/documents/Formular_drept acces.docx" download>Exercitare Drept Acces</a></li>
                    <li><a href="/assets/documents/Formular_drept rectificare.docx" download>Exercitare Drept Rectificare</a></li>
                    <li><a href="/assets/documents/Formular_drept stergere.docx" download>Exercitare Drept Stergere Date</a></li>
                    <li><a href="/assets/documents/Formular_drept opozitie.docx" download>Exercitare Drept Opozitie</a></li>
                    <li><a href="/assets/documents/Formular_drept restrictionare.docx" download>Exercitare Drept Restrictionare Date</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>