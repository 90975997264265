import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Configuration } from './configuration';

import { ApartmentscomService } from './api/apartmentscom.service';
import { AuthService } from './api/auth.service';
import { BozzutoService } from './api/bozzuto.service';
import { CommonService } from './api/common.service';
import { CompaniesService } from './api/companies.service';
import { EquityApartmentsService } from './api/equityApartments.service';
import { ListingsService } from './api/listings.service';
import { MediaContentService } from './api/mediaContent.service';
import { MillcreekService } from './api/millcreek.service';
import { NotificationsService } from './api/notifications.service';
import { ThreadsService } from './api/threads.service';
import { UsersService } from './api/users.service';

@NgModule({
  imports:      [ CommonModule, HttpClientModule ],
  declarations: [],
  exports:      [],
  providers: [
    ApartmentscomService,
    AuthService,
    BozzutoService,
    CommonService,
    CompaniesService,
    EquityApartmentsService,
    ListingsService,
    MediaContentService,
    MillcreekService,
    NotificationsService,
    ThreadsService,
    UsersService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        }
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import your base AppModule only.');
        }
    }
}
