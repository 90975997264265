import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../../swagger-generated';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  errorMessage;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _authService: AuthService
  ) {
    this.form = this._formBuilder.group({
      password: '',
      passwordRepeat: '',
      code: '',
    });
    this._activatedRoute.queryParams.subscribe(q => {
      if (q.code) {
        this.form.get('code').setValue(q.code);
      }
    });
  }

  ngOnInit() {
  }

  onSubmitForm() {
    this._authService.authUpdatePasswordPost(this.form.value)
    .subscribe(token => this.handleLogin(token), ({message}) => this.errorMessage = message);
  }

  handleLogin({token}) {
    localStorage.setItem('token', token);
    window.location.reload();
  }

}
