<div class="jobs-page-wrapper">
  <div class="container">
    <!-- SEARCH  -->
    <div class="row hidden">
      <div class="search-job-wrapper col-lg-10 offset-lg-1 my-5">
        <app-search-listing (search)="onSearch($event)" [selectedFilters]="selectedFilterOptions"></app-search-listing>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-9 offset-lg-3">
        <div class="results-header mb-3">
          <div class="pagination-label" *ngIf="pagedResponse && !errorMessage">
            <strong>{{pagedResponse.total}}</strong> <span class="pagination-suffix ml-1"> candidates found</span>
            <div class="connections-only">
              <input class="mr-2" type="checkbox" id="connections-only" name="connections-only"
                (change)="toggleFilter($event, 'connections.users.id', currentUser.id, 'in', 'Only connections')">
              <label for="connections-only">Connections only</label>
            </div>
          </div>
          <div class="sort">
            <select class="custom-select" (change)="onChangeSort($event)" [(ngModel)]="sortOrder">
              <option value="MOST_RECENT">Most Recent</option>
              <option *ngFor="let company of currentUserCompanies" [ngValue]="company">Near {{company.name}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 sidebar">
        <div class="selected-filters filter-box-wrapper p-2" *ngIf="selectedFilterOptions.length">
          <div class="selected-filters-label">
            You refined by:
          </div>
          <div class="filters-wrapper mt-2">
            <ul class="filtered-options">
              <li *ngFor="let filterOption of selectedFilterOptions">
                {{filterOption.label}}
                <i-feather class="remove-filter" name="x-circle"
                  (click)="removeFilter(filterOption.filterName, filterOption.filterValue)"></i-feather>
              </li>
            </ul>
          </div>
          <a class="remove-all-filters" (click)="removeFilter()">Clear All</a>
        </div>

        <div class="filter-box-wrapper">
          <div class="card">
            <div class="card-body">
              <div class="filter-title">
                <img src="assets/img/homi_theme/medal.png" srcset="assets/img/homi_theme/medal@2x.png 2x,
                      assets/img/homi_theme/medal@3x.png 3x" class="medal"> Candidates
              </div>
            </div>
          </div>
        </div>

        <div class="filter-box-wrapper">
          <div class="card">
            <div class="card-body">
              <div class="filter-title">
                <img src="assets/img/homi_theme/flag-alt.png" srcset="assets/img/homi_theme/flag-alt@2x.png 2x,
                        assets/img/homi_theme/flag-alt@3x.png 3x" class="medal"> Country
              </div>
              <ul class="filter-content">
                <li *ngFor="let address of filterOptions?.address; index as i" class="job-filter-item">
                  <div class="form-check">
                    <input id="jobFilter-option-{{i}}" class="form-check-input" type="checkbox"
                      (change)="toggleFilter($event, 'address.addressCountry', address.addressCountry, 'eq', address.addressCountry)">
                    <label class="form-check-label" for="jobFilter-option-{{i}}">
                      {{address.addressCountry}}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="filter-box-wrapper">
          <div class="card">
            <div class="card-body">
              <div class="filter-title">
                <img src="assets/img/homi_theme/globe-copy.png" srcset="assets/img/homi_theme/globe-copy@2x.png 2x,
                          assets/img/homi_theme/globe-copy@3x.png 3x" class="medal"> Language
              </div>
              <ul class="filter-content">
                <li *ngFor="let spokenLanguage of filterOptions?.spokenLanguages; index as i" class="job-filter-item">
                  <div class="form-check">
                    <input id="spokenLanguage-option-{{i}}" class="form-check-input" type="checkbox"
                      (change)="toggleFilter($event, 'spokenLanguages.language', spokenLanguage.id, 'eq', spokenLanguage.name)">
                    <label class="form-check-label" for="spokenLanguage-option-{{i}}">
                      {{spokenLanguage.name}}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="filter-box-wrapper">
          <div class="card">
            <div class="card-body">
              <div class="filter-title">
                <img src="assets/img/homi_theme/user.png" srcset="assets/img/homi_theme/user@2x.png 2x,
                        assets/img/homi_theme/user@3x.png 3x" class="medal"> Driving License
              </div>
              <ul class="filter-content">
                <li *ngFor="let driversLicenseCategory of filterOptions?.driversLicenseCategories; index as i"
                  class="job-filter-item">
                  <div class="form-check">
                    <input id="driversLicenseCategory-option-{{i}}" class="form-check-input" type="checkbox"
                      (change)="toggleFilter($event, 'driversLicenseCategories.id', driversLicenseCategory.id, 'cont', getDriversLiceseLabel(driversLicenseCategory.name))">
                    <label class="form-check-label" for="driversLicenseCategory-option-{{i}}">
                      {{'skills.' + driversLicenseCategory.name | translate}}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="filter-box-wrapper">
          <div class="card">
            <div class="card-body">
              <div class="filter-title">
                <img src="assets/img/homi_theme/money-bill-wave.png" srcset="assets/img/homi_theme/money-bill-wave@2x.png 2x,
                          assets/img/homi_theme/money-bill-wave@3x.png 3x" class="medal"> Minimum Budget
              </div>
              <div class="minimumSalaryFilter mb-1">
                <strong>{{minimumSalaryFilter | currency:'EUR'}}</strong> /mo
              </div>
              <input type="range" min="1" max="5000" value="200" (change)="onChangeMinimumSalary($event)" class="slider"
                #rangeInput [(ngModel)]="minimumSalaryFilter">
            </div>
          </div>
        </div>

        <div class="filter-box-wrapper">
          <div class="card">
            <div class="card-body">
              <div class="filter-title">
                <img src="assets/img/homi_theme/graduation-cap.png" srcset="assets/img/homi_theme/graduation-cap@2x.png 2x,
                          assets/img/homi_theme/graduation-cap@3x.png 3x" class="medal"> Studies
              </div>
              <ul class="filter-content">
                <li *ngFor="let filterValue of filterOptions?.educationLevel; index as i" class="job-filter-item">
                  <div class="form-check">
                    <input id="educationLevel-option-{{i}}" class="form-check-input" type="checkbox"
                      (change)="toggleFilter($event, 'educationLevel', filterValue)">
                    <label class="form-check-label" for="educationLevel-option-{{i}}">
                      {{'filterValues.educationRequirements.' + filterValue | translate}}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="filter-box-wrapper">
          <div class="card">
            <div class="card-body">
              <div class="filter-title">
                <img src="assets/img/homi_theme/clock.png" srcset="assets/img/homi_theme/clock@2x.png 2x,
                          assets/img/homi_theme/clock@3x.png 3x" class="clock"> Availability
              </div>
              <ul class="filter-content">
                <li *ngFor="let filterValue of filterOptions?.candidateAvailability; index as i"
                  class="job-filter-item">
                  <div class="form-check">
                    <input id="candidateAvailability-option-{{i}}" class="form-check-input" type="checkbox"
                      (change)="toggleFilter($event, 'candidateAvailability', filterValue)">
                    <label class="form-check-label" for="candidateAvailability-option-{{i}}">
                      {{'filterValues.candidateAvailability.' + filterValue | translate}}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- <div class="filter-box-wrapper">
          <div class="card">
            <div class="card-body">
              <div class="filter-title">
                <img src="assets/img/homi_theme/clock.png" srcset="assets/img/homi_theme/clock@2x.png 2x,
                          assets/img/homi_theme/clock@3x.png 3x" class="medal"> Preffered Work Hours
              </div>
              <ul class="filter-content">
                <li *ngFor="let filterValue of SHARED.MODELS.WORK_HOURS; index as i" class="job-filter-item">
                  <div class="form-check">
                    <input id="workHoursFilter-option-{{i}}" class="form-check-input" type="checkbox"
                      (change)="toggleFilter($event, 'workHours', filterValue, 'cont')">
                    <label class="form-check-label" for="workHoursFilter-option-{{i}}">
                      {{'filterValues.workHours.' + filterValue | translate}}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div> -->

        <!-- <div class="filter-box-wrapper">
          <div class="card">
            <div class="card-body">
              <div class="filter-title">
                <img src="assets/img/homi_theme/clock.png" srcset="assets/img/homi_theme/clock@2x.png 2x,
                          assets/img/homi_theme/clock@3x.png 3x" class="medal"> Benefits
              </div>
              <ul class="filter-content">
                <li *ngFor="let filterValue of SHARED.MODELS.BENEFITS; index as i" class="job-filter-item">
                  <div class="form-check">
                    <input id="benefitsFilter-option-{{i}}" class="form-check-input" type="checkbox"
                      (change)="toggleFilter($event, 'benefits', filterValue, 'cont')">
                    <label class="form-check-label" for="benefitsFilter-option-{{i}}">
                      {{'filterValues.benefits.' + filterValue | translate}}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div> -->
      </div>

      <div class="col-lg-9">
        <div class="card">
          <mat-spinner *ngIf="isLoading"></mat-spinner>
          <div ngClass="job-items-wrapper card-body {{isLoading ? 'loading': ''}}">
            <div class="no-results text-center" *ngIf="!pagedResponse?.total && !isLoading">
              <span class="text-muted">Oups! No results found <i-feather name="frown"></i-feather></span>
              <div class="mt-2"><a class="clear-all" (click)="removeFilter()">
                  <i-feather name="x-circle"></i-feather> Clear my filters
                </a></div>
            </div>
            <div class="row">
              <div class="card mb-3 col-lg-6" *ngFor="let user of pagedResponse?.data">
                <div class="card-body">
                  <app-user-item [user]="user" [relativeAddress]="relativeAddress"></app-user-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>