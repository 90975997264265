<div class="row m-0">
  <div class="col-lg-8 p-0">
    <div class="listing-header py-4">
      <button class="show-back-button mx-2 btn btn-link" *ngIf="showBack" (click)="onClickBack($event)">
        <img src="/assets/img/homi_theme/chevron-left.png" srcset="/assets/img/homi_theme/chevron-left@2x.png 2x,
          /assets/img/homi_theme/chevron-left@3x.png 3x">
      </button>
      <div class="media">
        <div class="mr-3 img-fluid thumb"
          [style.background-image]="'url(' + getMediaContentUrl(listing?.mediaContent[0], 'company', 'thumb') + ')'"></div>
        <div class="media-body">
          <div class="listing-title editable-item">
            <span *ngIf="listing?.autoSync" class="badge badge-success autosync-badge" title="Auto Sync"><i-feather name="refresh-cw" style="color: white;"></i-feather></span> {{title}} <a *ngIf="canEdit" class="edit"
              [routerLink]="'/dashboard/listings/' + listing?.id + '/edit'">
              <i-feather class="edit-icon" name="edit2"></i-feather>
            </a></div>
          <div class="employer-salary-wrapper my-1">
            <span class="employer" *ngIf="listing?.showOwner">
              <a [routerLink]="'/companies/' + listing?.owner.id"><span
                  class="employer-name">{{listing?.owner.name}}</span></a><span> - </span>
            </span>
            <span class="salary">
              <span class="amount">{{listing?.price | currency:'USD'}}</span>/month
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="listing-info px-3">
      <app-media-contents-gallery [mediaContents]="mediaContents"></app-media-contents-gallery>
      <hr class="my-3">

      <div class="description details-section">
        <h4 class="editable-item info-title">Listing Description <a *ngIf="canEdit" class="edit"
            [routerLink]="'/dashboard/listings/' + listing?.id + '/edit'">
            <i-feather class="edit-icon" name="edit2"></i-feather>
          </a>
        </h4>
        <p class="info-detail" [innerHtml]="listing?.description"></p>
        <p>{{listing?.amenities.join(',')}}</p>
      </div>

      <hr class="my-4">


    </div>
  </div>

  <div class="col-lg-4 listing-details-sidebar p-0">
    <div class="listing-details-top-block text-center py-4">
      <button *ngIf="!canEdit && !listingApplication" (click)="onClickApply()" class="btn btn-primary btn-lg mb-3">
        {{'Apply Now' | translate}}
      </button>
      <button *ngIf="!canEdit && listingApplication" (click)="onClickApply()" class="btn btn-danger btn-lg mb-3">
        {{'Cancel Application' | translate}}
      </button>
      <a *ngIf="canEdit && listing?.listingApplications?.length" class="btn btn-primary btn-lg mb-3"
        [routerLink]="'/listings/' + listing?.id + '/applications'">View {{listing?.listingApplications?.length}}
        applications</a>
      <br />
      <button *ngIf="!isFavorited" (click)="onClickFavorite()" class="btn btn-light btn-lg mb-3 favorite-button">
        <i-feather name="heart"></i-feather> {{'Save listing' | translate}}
      </button>
      <button *ngIf="isFavorited" (click)="onClickFavorite()" class="btn btn-light btn-lg mb-3 favorite-button">
        <i-feather name="heart" class="favorited"></i-feather> {{'Saved' | translate}}
      </button>


      <div class="posted-by" *ngIf="listing?.postedAsCompany || listing?.userLabel">
        <div *ngIf="listing?.postedAsCompany" class="listing-summary">
          <a [routerLink]="'/companies/' + listing?.companyLabel?.id">
            <div [style.background-image]="'url(' + getMediaContentUrl(listing?.thumbnail, 'company', 'thumb') + ')'"
              class="posted-by-thumbnail rounded-circle"></div>
            {{'listingPostedBy' | translate}} {{listing?.companyLabel?.name}}
          </a>
        </div>

        <div *ngIf="!listing?.postedAsCompany" class="listing-summary">
          <a [routerLink]="'/candidates/' + listing?.userLabel?.id">
            <div [style.background-image]="'url(' + getMediaContentUrl(listing?.thumbnail, 'user', 'thumb') + ')'"
              class="posted-by-thumbnail rounded-circle"></div>
            {{'listingPostedBy' | translate}} {{listing?.userLabel?.givenName}} {{listing?.userLabel?.familyName}}
          </a>
        </div>
      </div>
    </div>

    <div class="listing-additional-details px-3 py-4">
      <h4 class="info-title">Listing Summary</h4>
      <div class class="listing-summary-details">
        <div *ngIf="listing?.createdAt" class="listing-detail mb-3">
          <h6 class="mb-0">Published on</h6>
          {{listing?.createdAt | date:'dd-MMM-yyyy'}}
        </div>

        <div *ngIf="listing?.applicationDeadline" class="listing-detail mb-3">
          <h6 class="mb-0">Application Deadline</h6>
          {{listing?.applicationDeadline | date:'dd-MMM-yyyy'}}
        </div>
        
      </div>
      <h4 class="info-title mt-4">Listing Location</h4>
      <div class="info-detail mb-2">{{address?.formattedAddress}}</div>
      <div id="map-area" *ngIf="address?.geolocation">
        <img class="img-fluid" [src]="googleMapsImage" />
      </div>
    </div>
  </div>
</div>