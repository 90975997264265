import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, Input } from '@angular/core';
// import 'google-maps-api-typings';
import { Address } from '../../swagger-generated';
import { SessionService } from '../../services/session.service';

declare const google;

@Component({
  selector: 'app-search-listing',
  templateUrl: './search-listing.component.html',
  styleUrls: ['./search-listing.component.scss']
})
export class SearchListingComponent implements OnInit {
  @Output() changeListingTitle: EventEmitter<string> = new EventEmitter();
  @Output() search: EventEmitter<object> = new EventEmitter();
  @ViewChild('inputAddress') inputAddress: ElementRef;
  @Input('selectedFilters') selectedFilters;
  listingTitle;
  address: Address = {} as Address;
  autocompleteListener;
  isLoadingCurrentLocation = false;

  constructor(
    private _sessionService: SessionService
  ) { }

  ngOnInit() {
    this.initializeAutocomplete();
    if (this.selectedFilters) {
      const queryFilter = this.selectedFilters.find(filter => filter.filterName === 'title');
      if (queryFilter) {
        this.listingTitle = queryFilter.filterValue;
      }
      const addressFilter = this.selectedFilters.find(filter => filter.filterName === 'distance');
      if (addressFilter) {
        this.inputAddress.nativeElement.value = addressFilter.label;
      }
    }
  }

  onKeyUpInput(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.doSearch()
    }
  }

  doSearch() {
    if (!this.inputAddress.nativeElement.value) {
      this.address = {} as Address;
    }

    this.search.emit({
      query: this.listingTitle,
      address: this.address
    });
  }

  private _googlePlaceToAddress(place: any): Address {
    let streetAddress = '';
    let address: Address = {} as Address;
    address.streetAddress = '';
    if (place.address_components) {
      place.address_components.forEach(address_component => {
        if (address_component.types.includes('country')) {
          address.addressCountry = address_component['long_name'];
        } else if (address_component.types.includes('administrative_area_level_1')) {
          address.addressRegion = address_component['long_name'];
        } else if (address_component.types.includes('locality')) {
          address.addressLocality = address_component['long_name'];
        } else if (address_component.types.includes('route')) {
          address.streetAddress = address_component['long_name'] + streetAddress;
        } else if (address_component.types.includes('street_number')) {
          address.streetAddress += ' ' + address_component['long_name'];
        }
      });
      address.formattedAddress = place['formatted_address'];
      address.geolocation = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      }
    }

    return address;
  }

  initializeAutocomplete() {
    if (typeof google !== 'undefined') {
      const autocomplete = new google.maps.places.Autocomplete(this.inputAddress.nativeElement);
      this.autocompleteListener = google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        this.address = this._googlePlaceToAddress(place);
        this.doSearch();
      });
    }
  }

  searchNearby() {
    this.isLoadingCurrentLocation = true;
    this._sessionService.getCurrentLocation().then(location => {
      if (location) {
        new google.maps.Geocoder().geocode({
          location: {
            lat: location.coords.latitude,
            lng: location.coords.longitude
          }
        }, (results, status) => {
          if (status === 'OK') {
            const place = results[0];
            this.address = this._googlePlaceToAddress(place);
            this.inputAddress.nativeElement.value = this.address.formattedAddress;
            this.isLoadingCurrentLocation = false;
            this.doSearch();
          }
        });
      }
    })
      .catch(err => {
        this.isLoadingCurrentLocation = false;
      });
  }

}
