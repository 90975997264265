import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule as AngularFormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { SchemaFormModule } from 'ngx-schema-form';
import { NgxSwagFormModule } from 'ngx-swag-form';
import * as apiDocs from '../../../assets/api-docs.json';
import { environment } from '../../../environments/environment';
import { FormCompanyComponent } from './components/form-company/form-company.component';
import { FormGenericComponent } from './components/form-generic/form-generic.component';
import schemaExcludedProps from './models/schema-excluded-props';
import schemaExtensions from './models/schema-extensions';
import { FormControlCompanyComponent } from './widgets/form-control-company/form-control-company.component';
import { FormControlMediaContentHomiComponent } from './widgets/form-control-media-content-homi/form-control-media-content-homi.component';

@NgModule({
  declarations: [
    FormControlCompanyComponent,
    FormCompanyComponent,
    FormGenericComponent,
    FormControlMediaContentHomiComponent
  ],
  entryComponents: [
    FormControlCompanyComponent,
    FormControlMediaContentHomiComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SchemaFormModule,
    BrowserModule,
    AngularFormsModule,
    ReactiveFormsModule,
    NgxSwagFormModule.forRoot({
      googleMapsApiKey: environment.googleMapsApiKey,
      modelDefinitions: apiDocs.definitions,
      schemaExtensions,
      schemaExcludedProps
    }),
  ]
})
export class FormsModule { }
