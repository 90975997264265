import { Component, OnInit, Input } from '@angular/core';
import { ListingsService, ListingApplication } from 'src/app/swagger-generated';

@Component({
  selector: 'app-candidate-application',
  templateUrl: './candidate-application.component.html',
  styleUrls: ['./candidate-application.component.scss']
})
export class CandidateApplicationComponent {
  @Input('listingApplication') listingApplication: ListingApplication;
  ListingApplicationOptions = Object.values(ListingApplication.StatusEnum);

  constructor(
    private _listingsService: ListingsService
  ) { }

  onChangeListingApplicationStatus(ev, listingApplication) {
    this._listingsService.listingApplicationsIdPatch(<ListingApplication><unknown>{status: ev.target.value}, listingApplication.id).subscribe();
  }

}
