<div class="media-content-container">
  <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorMessage">
    {{errorMessage}}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="thumbnail-media-content" *ngIf="viewMode === mediaContentViewModesEnum.THUMBNAIL">
    <div class="photo-wrapper mr-2">
      <div class="update-photo rounded-circle" (click)="fileUpload.click()">
        <div class="user-photo rounded-circle" [style.background-image]="'url(' + mediaContentThumbUrl + ')'"></div>
        <div class="new-photo">{{'New Photo' | translate}}</div>
        <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
      </div>
      <div class="file-upload hidden">
        <input name="file" accept="image/*" type="file" class="file-input" (change)="onChangeMediaContent($event)"
          #fileUpload>Upload
      </div>
    </div>
    <button class="delete-media-content btn btn-light btn-sm" type="button" *ngIf="mediaContentUrl"
      (click)="onClickDeleteMediaContent()">
      <img class="logo-color" src="/assets/img/homi_theme/trash-alt-copy.png"
        srcset="/assets/img/homi_theme/trash-alt-copy@2x.png 2x, /assets/img/homi_theme/trash-alt-copy@3x.png 3x">
    </button>
  </div>

  <div class="video-media-content" *ngIf="viewMode === mediaContentViewModesEnum.VIDEO">
    <div *ngIf="mediaContent && !mediaContent?.id">
      <div class="form-group row">
        <div class="col-lg-6 video-cv-field">
          <mat-form-field>
            <mat-label>Video Type</mat-label>
            <mat-select [(ngModel)]="mediaContent.title" #videoTypeSelect>
              <mat-option *ngFor="let cvVideoType of cvVideoTypes" [value]="cvVideoType">
                {{'cvVideoTypes.' + cvVideoType | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-6 video-cv-field">
          <mat-form-field>
            <input type="hidden" />
            <input type="text" [value]="mediaContent?.userVideoCvLanguage2?.name" matInput [matAutocomplete]="auto"
              (keyup)="onKeyUpLanguagesAutocomplete($event, i)" placeholder="Language">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="languageDisplayFn" placeholder="Language"
              #languageAutocomplete (optionSelected)="onOptionSelectedLanguage($event)">
              <mat-option *ngFor="let languageOption of languageOptions" [value]="languageOption">
                {{languageOption.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <!-- <mat-form-field>
            <mat-label>Language</mat-label>
            <mat-select #languageSelect required>
              <mat-option *ngFor="let language of languages" [value]="language.id">{{language.name}}</mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-12">
          <input name="file" accept="video/*" type="file" (change)="onChangeMediaContent($event)" #fileUpload>
          <mat-spinner diameter="20" *ngIf="isLoading"></mat-spinner>
        </div>
      </div>
    </div>
    <div class="video-media-content" *ngIf="mediaContent && mediaContent?.id">
      <mat-card>
        <mat-card-header>
          <mat-card-title *ngIf="mediaContent?.title">{{'cvVideoTypes.' + mediaContent?.title | translate}}</mat-card-title>
          <mat-card-subtitle>{{mediaContent?.userVideoCvLanguage2?.name | translate}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <video controls>
            <source [src]="mediaContentUrl" [type]="mediaContent?.mimeType">
          </video>
        </mat-card-content>
        <mat-card-actions>
          <button type="button" mat-button (click)="onClickDeleteMediaContent()">DELETE</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <div class="listing-video-media-content" *ngIf="viewMode === mediaContentViewModesEnum.JOB_VIDEO && mediaContent">
    <div class="card">
      <div class="card-body">
        <div *ngIf="!mediaContent?.id" class="video-name">
          <input name="title" type="text" class="form-control" placeholder="Video Title"
            [(ngModel)]="mediaContent.title">
          <hr />
          <input name="url" type="text" class="form-control" placeholder="Video URL" [(ngModel)]="videoUrl">
          <button class="btn btn-light btn-sm" *ngIf="videoUrl" type="button" (click)="onClickUrlVideoUpload($event)">
            <i-feather name="save"></i-feather> Save
          </button>
        </div>

        <div *ngIf="!videoUrl && !mediaContent?.id">
          <input name="file" accept="video/*" type="file" class="form-control video-upload-input"
            placeholder="Job video" #fileUpload (change)="onChangeMediaContent($event)">
          <button class="btn btn-link" (click)="fileUpload.click()" type="button">
            <i-feather name="upload"></i-feather> Upload video
          </button>
        </div>

        <div *ngIf="mediaContent?.id" class="video-name">
          {{mediaContent?.title || mediaContent?.fileName || mediaContent?.url}}
          <div plyr [plyrSources]="sources" [plyrOptions]="{controls: ['play-large']}">
          </div>
          <button class="delete-media-content btn btn-light btn-sm" type="button" *ngIf="mediaContentUrl"
            (click)="onClickDeleteMediaContent()">
            <img class="logo-color" src="/assets/img/homi_theme/trash-alt-copy.png"
              srcset="/assets/img/homi_theme/trash-alt-copy@2x.png 2x, /assets/img/homi_theme/trash-alt-copy@3x.png 3x">
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="photo-media-content" *ngIf="viewMode === mediaContentViewModesEnum.PHOTO">
    <div *ngIf="mediaContent?.id">
      <div class="card">
        <div class="card-body" [style.background-image]="'url(' + mediaContentUrl + ')'">
          <button class="delete-media-content btn btn-light btn-sm" type="button" *ngIf="mediaContentUrl"
            (click)="onClickDeleteMediaContent()" [disabled]="disableDelete">
            <img class="logo-color" src="/assets/img/homi_theme/trash-alt-copy.png"
              srcset="/assets/img/homi_theme/trash-alt-copy@2x.png 2x, /assets/img/homi_theme/trash-alt-copy@3x.png 3x">
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="!mediaContent?.id">
      <div class="card card-upload-photo">
        <div class="card-body">
          <input name="file" accept="image/*" type="file" (change)="onChangeMediaContent($event)" #fileUpload>
          <button class="btn btn-link" (click)="fileUpload.click()" type="button">
            <i-feather name="upload"></i-feather> Upload photo
          </button>
        </div>
      </div>
    </div>

  </div>
</div>