import { Component, OnInit } from '@angular/core';
import { Thread, UsersService, User } from '../../swagger-generated';
import { SessionService } from '../../services/session.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent {

  threads: Thread[];
  currentUser: User;
  isLoading = true;

  constructor(
    private _sessionService: SessionService,
    private _usersService: UsersService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    this._sessionService.getCurrentUser().then(currentUser => {
      this.currentUser = currentUser;
      this.updateUserThreads(currentUser.id);

      this._activatedRoute.queryParams.subscribe(q => {
        if (q.userId) {
          this._usersService.usersIdThreadGet(q.userId).subscribe(threads => {
            const userThread = threads[0];
            if (!this.threads || !this.threads.find(thread => thread && thread.id === userThread.id)) {
              this.updateUserThreads(currentUser.id);
            }
            this._router.navigate([`/messages/${userThread.id}`]);
          })
        }
      })
    });
  }

  updateUserThreads(userId: string) {
    this.isLoading = true;
    this._usersService.usersIdThreadGet(userId).subscribe(threads => {
      this.threads = _.uniqBy([...threads, ...this.threads], 'id');
      this.isLoading = false;
    });
  }

  getThreadUser(thread: Thread) {
    return thread.users.find(user => user.id !== this.currentUser.id);
  }

}
