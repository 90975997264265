<table class="table">
    <thead>
      <tr>
        <th>Full name</th>
        <th>Email</th>
        <th>Phone</th>
        <th>Created at</th>
        <th>Listing applications</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of users">
        <td>{{user.givenName}} {{user.familyName}}</td>
        <td>{{user.email}}</td>
        <td>{{user.phone}}</td>
        <td>{{user.createdAt | date:'longDate'}}</td>
        <td>
            <ul>
                <li *ngFor="let listingApplication of user?.listingApplications">
                    <a [routerLink]="'/listings/' + listingApplication.listing?.id">{{listingApplication.listing?.owner?.name}} ({{listingApplication.createdAt | date:'longDate'}})</a>
                </li>
            </ul>
        </td>
      </tr>
    </tbody>
  </table>