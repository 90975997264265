import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SessionService } from '../services/session.service';
import { User } from '../swagger-generated';

@Injectable({
  providedIn: 'root'
})
export class AnonymousGuard implements CanActivate  {
  constructor(
    private sessionService: SessionService,
    private router: Router
  ) { }

  canActivate(): any {
    return new Promise((resolve, reject) => {
      this.sessionService.getCurrentUser()
        .then((user: User) => {
          if (user) {
            reject();
            this.router.navigate(['dashboard']);
          } else {
            resolve(true);
          }
        }, (err) => {
          this.router.navigate(['/']);
          reject(err);
        })
        .catch(err => {
          debugger;
        })
    });
  }
}
