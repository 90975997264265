<div class="card content-wrapper-card">
  <div class="card-header py-4">
    <div class="top-title">My Profile</div>
    <a [routerLink]="'/listing-candidates/' + currentUser?.id" class="view-as">View my profile as others see it</a>
  </div>
  <div class="card-body">

    <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorMessage">
      {{errorMessage}}
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <form action="#" class="dashboard-form" [formGroup]="form">
      <input type="hidden" formControlName="id">
      <div class="media">
        <div class="user-profile-picture mr-3">
          <div class="dashboard-section upload-profile-photo">
            <div formGroupName="profilePicture">
              <app-media-content formControlName="id" viewMode="{{mediaContentViewModesEnum.THUMBNAIL}}"
                defaultThumbType="user"></app-media-content>
            </div>
          </div>
        </div>
      </div>

      <div class="dashboard-section basic-info-input mt-5">
        <h4 class="section-title">
          PERSONAL DATA
        </h4>
        <hr class="mt-0">

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-group-title" for="firstNameInput">First Name <span class="required">*</span></label>
              <input type="text" class="form-control" id="firstNameInput" placeholder="e.g. John"
                formControlName="givenName" required>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-group-title" for="lastNameInput">Last Name <span class="required">*</span></label>
              <input type="text" class="form-control" id="lastNameInput" placeholder="e.g. Doe"
                formControlName="familyName" required>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label class="form-group-title" for="birthDateInput">Date of birth <span class="required">*</span></label>
              <input type="text" matInput [matDatepicker]="picker"
                class="form-control" id="birthDateInput" placeholder="MM/DD/YYYY" formControlName="birthDate"
                (focus)="picker.open()" required>
              <mat-datepicker startView="multi-year" #picker></mat-datepicker>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group-title" class="form-group">
              <label for="emailAddressInput">Email Address <span class="required">*</span></label>
              <input type="email" class="form-control" id="emailAddressInput" placeholder="example@gmail.com"
                formControlName="email" required readonly>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group-title" class="form-group">
              <label for="phoneInput">Phone</label>
              <input type="text" class="form-control" id="phoneInput" placeholder="e.g. 0862-XXXX-XXXX"
                formControlName="phone">
            </div>
          </div>
        </div>

        <!-- <div class="row current-location py-4 my-4 rounded">
          <div class="col-lg-12">
            <div class="form-group" formGroupName="address">
              <label class="form-group-title" for="formattedAddressSearch">Current Address <span
                  class="required">*</span></label>
              <div class="text-muted"> <small>Please search for your exact address to be able to calculate job
                  distances.</small></div>

              <input type="search" class="form-control" id="formattedAddressSearch"
                placeholder="e.g. 51 Chandler Street, Boston" required #inputAddress>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="form-group" formGroupName="address">
              <label class="form-group-title" for="formattedAddressInput">Formatted Address <span
                  class="required">*</span></label>
              <input type="text" class="form-control" id="formattedAddressInput" formControlName="formattedAddress"
                required readonly>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group" formGroupName="address">
              <label class="form-group-title" for="addressCountryInput">Country <span class="required">*</span></label>
              <input type="text" class="form-control" id="addressCountryInput" formControlName="addressCountry" required
                readonly>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group" formGroupName="address">
              <label class="form-group-title" for="addressRegion">Region <span class="required">*</span></label>
              <input type="text" class="form-control" id="addressRegion" formControlName="addressRegion" required
                readonly>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group" formGroupName="address">
              <label class="form-group-title" for="addressLocalityInput">City <span class="required">*</span></label>
              <input type="text" class="form-control" id="addressLocalityInput" formControlName="addressLocality"
                required readonly>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group" formGroupName="address">
              <label class="form-group-title" for="streetAddressInput">Street</label>
              <input type="text" class="form-control" id="streetAddressInput" formControlName="streetAddress" readonly>
            </div>
          </div>
        </div> -->
      </div>

      <div class="dashboard-section basic-info-input mt-5">
        <h4 class="section-title">
          EXPERIENCE
        </h4>
        <hr class="mt-0">
        <div class="form-group row">
          <div class="col-lg-6">
            <label class="form-group-title" for="title">Current Title</label>
            <input type="text" class="form-control" id="title" placeholder="e.g. Software Engineer"
              formControlName="title">
          </div>
        </div>

        <div class="form-group">
          <label class="form-group-title" for="aboutMeInput">About me</label>
          <textarea id="aboutMeInput" class="form-control" name="aboutMe" formControlName="aboutMe" rows="6"
            placeholder="Short description about yourself"></textarea>
        </div>

        <div class="form-group">
          <label class="form-group-title">Spoken Languages</label>
          <br>
          <div formArrayName="spokenLanguages" *ngFor="let spokenLanguage of spokenLanguages.controls; index as i">
            <div [formGroupName]="i" class="row">
              <div class="col-lg-3">
                <div formGroupName="language">
                  <mat-form-field>
                    <input type="hidden" formControlName="id" />
                    <input type="text" [value]="spokenLanguage.get('language')?.get('name')?.value" matInput
                      [matAutocomplete]="auto" (keyup)="onKeyUpLanguagesAutocomplete($event, i)" placeholder="Language">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="languageDisplayFn"
                      (optionSelected)="onOptionSelectedLanguage($event, spokenLanguage.get('language'))">
                      <mat-option *ngFor="let languageOption of spokenLanguagesLanguageOptions[i]"
                        [value]="languageOption">
                        {{languageOption.name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-3">
                <mat-form-field>
                  <mat-label>{{'Proficiency' | translate}}</mat-label>
                  <mat-select formControlName="proficiency" required>
                    <mat-option *ngFor="let proficiencyValue of proficiencyEnum" [value]="proficiencyValue">
                      {{proficiencyValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-lg-2">
                <button class="btn btn-light btn-sm rounded-circle" type="button"
                  (click)="onClickRemoveSpokenLanguage($event, spokenLanguage, i)">
                  <img class="logo-color" src="/assets/img/homi_theme/trash-alt-copy.png"
                    srcset="/assets/img/homi_theme/trash-alt-copy@2x.png 2x, /assets/img/homi_theme/trash-alt-copy@3x.png 3x">
                </button>
              </div>
            </div>
          </div>
          <button type="button" class="button btn btn-link p-0" (click)="addSpokenLanguage()"><img
              src="/assets/img/homi_theme/plus-circle.png"
              srcset="/assets/img/homi_theme/plus-circle@2x.png 2x, /assets/img/homi_theme/plus-circle@3x.png 3x" />
            Add</button>
        </div>

        <div class="form-group">
          <label class="form-group-title">Nationalities</label>
          <div formArrayName="nationalities" *ngFor="let nationality of nationalities.controls; index as i">
            <div [formGroupName]="i" class="row">
              <div class="col-lg-3">
                <mat-form-field>
                  <input type="hidden" formControlName="id" />
                  <input type="text" [value]="nationality.get('name')?.value" aria-label="Number" matInput
                    [matAutocomplete]="auto" (keyup)="onKeyUpNationalitiesAutocomplete($event, i)"
                    placeholder="Nationality">
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="nationalityDisplayFn"
                    (optionSelected)="onOptionSelectedNationality($event, nationality)" placeholder="e.g. Italian">
                    <mat-option *ngFor="let nationalityOption of nationalitiesOptions[i]" [value]="nationalityOption">
                      {{nationalityOption.name}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-lg-2">
                <button class="btn btn-light btn-sm rounded-circle" type="button"
                  (click)="onClickRemoveNationality($event, i)">
                  <img class="logo-color" src="/assets/img/homi_theme/trash-alt-copy.png"
                    srcset="/assets/img/homi_theme/trash-alt-copy@2x.png 2x, /assets/img/homi_theme/trash-alt-copy@3x.png 3x">
                </button>
              </div>
            </div>
          </div>
          <div>
            <button type="button" class="button btn btn-link p-0" (click)="addNationality()"><img
                src="/assets/img/homi_theme/plus-circle.png"
                srcset="/assets/img/homi_theme/plus-circle@2x.png 2x, /assets/img/homi_theme/plus-circle@3x.png 3x" />
              Add</button>
          </div>
        </div>

        <div class="form-group">
          <label class="form-group-title">Education</label>
          <div class="row">
            <div class="col-lg-3">
              <mat-form-field>
                <mat-label>{{'Education level' | translate}}</mat-label>
                <mat-select formControlName="educationStatus">
                  <mat-option *ngFor="let educationStatus of EducationStatuses" [value]="educationStatus">
                    {{'filterValues.educationRequirements.' + educationStatus | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field>
                <mat-label>{{'Education field' | translate}}</mat-label>
                <mat-select formControlName="educationField">
                  <mat-option *ngFor="let educationField of getEnumValues(UserEntity.EducationFieldEnum)"
                    [value]="educationField">
                    {{'filterValues.educationFields.' + educationField | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <div class="dashboard-section basic-info-input mt-5">
        <h4 class="section-title">
          RENT AVAILABILTIY
        </h4>
        <hr class="mt-0">
        <div class="form-group">
          <label class="form-group-title">{{'Excluded listings' | translate}}</label>
          <div class="col-lg-9" formArrayName="excluded">
            <div *ngFor="let field of getFormData('excluded').controls; index as i">
              <div class="form-check mt-2">
                <input name="excluded[]" id="excluded-{{i}}" class="form-check-input" type="checkbox"
                  [formControl]="field">
                <label class="form-check-label" for="excluded-{{i}}">
                  {{'filterValues.excluded.' + DEFAULT_EXCLUDED_LISTINGS[i] | translate}}
                </label>
              </div>
            </div>
            <div class="form-group mt-2">
              <label for="excludedSelectize" class="text-muted">Others (comma-separated): </label>
              <input type="text" class="form-control" id="excludedSelectize" #excludedSelectize>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-lg-6">
            <label class="form-group-title">Employment Status</label>
            <select formControlName="employmentStatus" class="form-control">
              <option value="EMPLOYED">Employed</option>
              <option value="UNEMPLOYED">Unemployed</option>
            </select>
          </div>
        </div>
      </div>

      <div class="dashboard-section basic-info-input mt-5" formGroupName="budget">
        <h4 class="section-title">
          BUDGET
        </h4>
        <hr class="mt-0">
        <div class="form-group row">
          <div class="col-lg-6">
            <label class="form-group-title">Rental budget / month (USD)</label>
            <div class="form-group">
              <input name="budget[min]" formControlName="min" class="form-control" placeholder="Minimum Rent Budget">
            </div>
            <div class="form-group">
              <input name="budget[max]" formControlName="max" class="form-control" placeholder="Maximum Rent Budget">
            </div>
          </div>
        </div>
      </div>

      <div class="dashboard-section basic-info-input mt-5">
        <h4 class="section-title">
          ACCOUNT SETTINGS
        </h4>
        <hr class="mt-0">
        <div class="row">
          <div class="col-lg-12">
            <div class="mb-3">
              <button class="btn delete-user-profile" type="button" data-toggle="modal"
                data-target="#deleteAccountModal">DELETE ACCOUNT</button>
            </div>
          </div>
        </div>
      </div>

      <div class="save-container py-2 text-center">
        <button class="btn btn-lg btn-primary save-user-profile" (click)="saveUser()" [disabled]="!form.valid">Save
          Changes
          <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner></button>
      </div>
    </form>
  </div>
</div>

<div id="deleteAccountModal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4>Delete account</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Deleting your account will permanently remove all your data! Are you sure you want to proceed?</p>
      </div>
      <div class="modal-footer">
        <button data-dismiss="modal" routerLink="/contact" (click)="onClickDeleteUser()" class="btn btn-danger">Yes,
          delete all my data</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>