<div id="home-layout">
  <div class="version2">
    <section id="slider-container">
      <h2 class="introducing-homi diffused-shadow">Introducing Homi<small>
            <span class="popup-vimeo"><button class="play-video-icon" data-toggle="modal"
                data-target="#showVideoModal" (click)="videoEl.play()"></button></span></small></h2>

      <!-- Carousel -->
      <div id="carousel" class="carousel slide" data-interval="false">
        <div class="carousel-inner">
          <div class="carousel-item active slick-current slick-active" aria-hidden="true"
            tabindex="-1">
            <div class="landing-page-intro-text">
              <h1 class="top-main-title diffused-shadow">Love your home</h1>
            </div><img src="/assets/img/homi/Stocksy_txpd9016bf6252200_Large_1460940.jpg">
          </div>
          <div class="carousel-item slick-current slick-active" aria-hidden="false" tabindex="-1" role="option"
            aria-describedby="00">
            <div class="landing-page-intro-text">
              <h1 class="top-main-title diffused-shadow">Find great appartments</h1>
            </div><img src="/assets/img/homi/Stocksy_txpd9016bf6252200_Large_986047.jpg">
          </div>
          <div class="carousel-item" aria-hidden="true" tabindex="-1" role="option" aria-describedby="01">
            <div class="landing-page-intro-text">
              <h1 class="top-main-title diffused-shadow">Meet awesome roommates</h1>
            </div><img src="/assets/img/homi/Stocksy_txpd9016bf6252200_Large_1181881.jpg">
          </div>
          <div class="carousel-item" aria-hidden="true" tabindex="-1" role="option" aria-describedby="02">
            <div class="landing-page-intro-text">
              <h1 class="top-main-title diffused-shadow">Love your home</h1>
            </div><img src="/assets/img/homi/Stocksy_txpd9016bf6252200_Large_1460940.jpg">
          </div>
          <div class="carousel-item slick-cloned" data-slick-index="3" aria-hidden="true" tabindex="-1">
            <div class="landing-page-intro-text">
              <h1 class="top-main-title diffused-shadow">Find great appartments</h1>
            </div><img src="/assets/img/homi/Stocksy_txpd9016bf6252200_Large_986047.jpg">
          </div>
        </div>
        <a class="carousel-control carousel-control-prev" href="#carousel" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control carousel-control-next" href="#carousel" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <!-- End carousel -->
    </section>
    <section id="subscribe-homi" class="big-carousel-item text-center subscribe-form-container">
      <h2>Homi is officially in Boston! Download the app below to get started.</h2>
      <div class="row download-app-container">
        <div class="col-md-4 offset-md-2 store-logo-container"><a
            href="http://itunes.apple.com/app/id1451069927"><img src="/assets/img/homi/app-store-badge.png"
              class="store-logo"></a>
        </div>
        <div class="col-md-4 store-logo-container"><a
            href="https://play.google.com/store/apps/details?id=com.homiv2"><img
              src="/assets/img/homi/google-play-badge.png" class="store-logo"></a></div>
      </div>
      <form class="subscribe-form hidden">
        <div class="form-group email-input-container"><input placeholder="Enter email address" name="email" type="email"
            required="required" class="rounded-input subscribe-email form-control"></div><button type="submit"
          class="btn btn-primary btn-homi subscribe-button-container">Notify me</button>
      </form><br>
      <div class="server-alert-container"><span class="alert alert-default hidden">Registering...</span></div>
    </section>
    <section class="categories-slide row">
      <div class="col-md-12">
        <h2>Lock in your perfect apartment.</h2>
      </div>
      <div class="categories-slide-block col-md-4">
        <div class="categories-slide-block-number">1</div>
        <h4 class="categories-slide-block-title">PICK A SWEET CRIB</h4>
        <p>Browse hundreds of local listings that fit your style. Discover your ideal home right through the app.</p>
      </div>
      <div class="categories-slide-block col-md-4">
        <div class="categories-slide-block-number">2</div>
        <h4 class="categories-slide-block-title">FIND YOUR CREW</h4>
        <p>Rent too high? Check out roommate profiles of other peeps interested in the same place.</p>
      </div>
      <div class="categories-slide-block col-md-4">
        <div class="categories-slide-block-number">3</div>
        <h4 class="categories-slide-block-title">LOCK IT IN</h4>
        <p>Go in on the place together—and sign a lease. Our real estate pros make it happen fast.</p>
      </div>
    </section>
    <section class="row categories-carousel-item expert-help-section">
      <div class="col-md-6">
        <h1 class="diffused-shadow">Get paired with a real estate pro.</h1><br>
        <h4>We'll set up everything:</h4><br>
        <ul>
          <li>Apartment showings</li>
          <li>Roommate interviews</li>
          <li>Lease prep and signing</li>
        </ul>
      </div>
    </section>
    <section class="signup-slide">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <h2 class="need-more-info-title">Want to find out more or share ideas?</h2>
          <p>Call<a href="tel:6179342409" class="text-homi"> 617-934-2409</a>&nbsp;or e-mail us at<a
              href="mailto:contact@homi.house" class="text-homi"> contact@homi.house</a></p>
        </div>
      </div>
      <div class="row download-app-container">
        <div class="col-md-4 offset-md-2 store-logo-container"><a href="http://itunes.apple.com/app/id1451069927"><img
              src="/assets/img/homi/app-store-badge.png" class="store-logo"></a></div>
        <div class="col-md-4 store-logo-container"><a href="https://play.google.com/store/apps/details?id=com.homiv2"><img src="/assets/img/homi/google-play-badge.png"
              class="store-logo"></a></div>
      </div>
    </section>
  </div>
</div>

<div id="showVideoModal" class="modal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="videoEl.pause()">
          <span aria-hidden="true" (click)="videoEl.pause()">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <video class="intro-video" controls #videoEl>
          <source src="/assets/Homi_App_Product_Video.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
</div>