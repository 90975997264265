import { Component, AfterContentInit, ChangeDetectorRef } from '@angular/core';
import { User } from '../../modules/generated-api.module';
import { SessionService } from 'src/app/services/session.service';
import { getMediaContentUrl } from '../../services/mediaContent.service';

declare let $;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterContentInit {
  isAuthenticated = false;
  isLoaded = false;
  currentUser: User;
  RolesEnum = User.RolesEnum;
  queryParams: any;

  constructor(
    private sessionService: SessionService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngAfterContentInit() {
    this.sessionService.currentUserUpdated$.subscribe((currentUser) => {
      if (currentUser) {
        this.isAuthenticated = true;
        this.currentUser = currentUser;
      } else {
        this.isAuthenticated = false;
        this.currentUser = null;
      }
      this.isLoaded = true;
      // Tweak - isLoaded change is not detected
      this._changeDetectorRef.detectChanges();
    });
    this.pullCurretUser();
    // TODO - doesn't seem right
    $(document).ready(function () {
      $(document).click(function (event) {
        var clickover = $(event.target);
        var _opened = $(".navbar-collapse").hasClass("showing") || $(".navbar-collapse").hasClass("show");
        if (_opened === true && !clickover.hasClass("navbar-toggler")) {
          $("button.navbar-toggler").click();
        }
      });
    });
  }

  get profilePictureUrl() {
    return getMediaContentUrl(this.currentUser.profilePicture, 'user', 'thumb');
  }

  pullCurretUser() {
    // Check if user is logged in
    this.sessionService.getCurrentUser().then((user: User) => {
      if (!user || !user.id) {
        localStorage.removeItem('token');
      }
    }, () => {
      localStorage.removeItem('token');
    });
  }

  onDropdownMenuClick() {
    $('.account-card').toggleClass('show');
    $('.account-card').fadeToggle();
  }

  hasRole(role) {
    return this.sessionService.hasRole(role);
  }

}
