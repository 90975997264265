import { Component, OnInit } from '@angular/core';
import { Listing, ListingsService, UsersService } from 'src/app/swagger-generated';
import { SessionService } from 'src/app/services/session.service';

declare const feather;

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.scss']
})
export class DashboardListingsComponent implements OnInit {
  activeListings: Listing[];
  inactiveListings: Listing[];

  constructor(
    private listingsService: ListingsService,
    private _sessionService: SessionService,
    private _usersService: UsersService
  ) { }

  ngOnInit() {
    this.pullListings();
  }

  async pullListings() {
    const joinQueryString = [
      'usersFavorited',
      'monetaryAmount',
      'listingApplications',
      'owner'
    ]
    // const currentUser = await this._sessionService.getCurrentUser();
    this.listingsService.listingsGet(undefined, undefined, undefined, undefined, joinQueryString).subscribe(response => {
      this.activeListings = response.filter(listing => [Listing.StatusEnum.CLOSED, Listing.StatusEnum.EXPIRED].indexOf(listing.status) === -1);
      this.inactiveListings = response.filter(listing => [Listing.StatusEnum.CLOSED, Listing.StatusEnum.EXPIRED].indexOf(listing.status) !== -1);
    })
    // this._usersService.usersIdGet(currentUser.id, undefined, joinQueryString).subscribe(response => {
    //   this.activeListings = response.listingsOwned.filter(listing => [Listing.StatusEnum.CLOSED, Listing.StatusEnum.EXPIRED].indexOf(listing.status) === -1);
    //   this.inactiveListings = response.listingsOwned.filter(listing => [Listing.StatusEnum.CLOSED, Listing.StatusEnum.EXPIRED].indexOf(listing.status) !== -1);
    // });
  }

  getListingTitle(listing: Listing) {
    if (listing.title) {
      return listing.title;
    }
    if (listing.type === Listing.TypeEnum.STUDIO) {
      return listing.owner.name + ' Studio'
    }
    if (listing.rooms && listing.owner) {
      return listing.owner.name + ' ' + listing.rooms + ' bdr'
    }
    return listing.owner ? listing.owner.name : 'unknown title';
  }

  onClickDelete(listing: Listing): void {
    this.listingsService.listingsIdDelete(listing.id).subscribe(data => {
      this.pullListings();
    })
  }
}
