import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/modules/generated-api.module';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {

  constructor(
    private authService: AuthService,
    private sessionService: SessionService
  ) {
    this.authService.authLogoutPost(localStorage.getItem('token')).subscribe( data => {
      localStorage.removeItem('token');
      this.sessionService.currentUserUpdated(false);
      window.location.href = '/';
    });
  }

}
