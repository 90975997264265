import { Component, AfterContentChecked, OnInit } from '@angular/core';
import { ListingsSearchPayload } from '../../interfaces/listings-search-payload';
import { Router, ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

declare const jQuery;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterContentChecked, OnInit {
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _googleAnalyticsService: GoogleAnalyticsService
  ) {
    
  }

  ngOnInit() {
    this._activatedRoute.queryParams.subscribe(params => {
      if (params.utm_source && params.utm_source==='onescreen') {
        // OneScreen Ref
        this._googleAnalyticsService.sendEvent('OneScreen', params.utm_medium);
      }
  });
  }

  ngAfterContentChecked() {
    jQuery('#showVideoModal').on('hidden.bs.modal', function (e) {
      jQuery('#showVideoModal video').get(0).pause();
    })
  }

  onSearch(payload: ListingsSearchPayload) {
    let filter = [];
    if (payload.query) {
      filter.push(`title||cont||${payload.query}`);
    }

    if (payload.address.geolocation) {
      filter.push(`distance||eq||${JSON.stringify(payload.address)}`);
    }

    this._router.navigate(['/listings'], { queryParams: { filter } });
  }

  onClickVideoNav() {
    const ytIframes: HTMLIFrameElement[] = Array.from(document.getElementsByTagName('iframe'));
    ytIframes.forEach(ytIframe => {
      ytIframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    })
  }

}
