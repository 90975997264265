/**
 * Homi Server Endpoints
 * Available interactions with the Homi Backend services
 *
 * OpenAPI spec version: 0.0.17
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Language } from './language';
import { ModelDate } from './modelDate';


export interface SpokenLanguage {
    id: string;
    createdAt: ModelDate;
    updatedAt: ModelDate;
    proficiency: SpokenLanguage.ProficiencyEnum;
    language: Language;
}
export namespace SpokenLanguage {
    export type ProficiencyEnum = 'A' | 'B' | 'C' | 'NATIVE';
    export const ProficiencyEnum = {
        A: 'A' as ProficiencyEnum,
        B: 'B' as ProficiencyEnum,
        C: 'C' as ProficiencyEnum,
        NATIVE: 'NATIVE' as ProficiencyEnum
    }
}
