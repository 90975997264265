import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { SessionService } from '../../../../app/services/session.service';
import { UsersService, User, MediaContentService, MediaContent, SpokenLanguage, Language, CommonService, Nationality, Company, CompaniesService } from '../../../../app/swagger-generated';
import { MediaContentViewModesEnum } from '../../../../app/shared/models/media-content-view-modes';
import { SHARED } from '../../../../app/shared';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { __values } from 'tslib';
import { Router } from '@angular/router';

declare const google;
declare const jQuery;

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit, OnDestroy {
  autocompleteListener;
  @ViewChild('inputAddress') inputAddress: ElementRef;
  @ViewChild('avatarFileInput') avatarFileInput: ElementRef;
  @ViewChild('cvFileInput') cvFileInput: ElementRef;
  @ViewChild('excludedSelectize') excludedSelectize: ElementRef;
  currentUser: User;
  mediaContentViewModesEnum = MediaContentViewModesEnum;
  proficiencyEnum = _.values(SpokenLanguage.ProficiencyEnum);
  languages: Language[] = [];
  nationalitiesOptions: Nationality[][] = [];
  allNationalitiesOptions: Nationality[] = [];
  spokenLanguagesLanguageOptions: Language[][] = [];
  allLanguagesOptions: Language[] = [];
  allCompanyOptions: Company[] = [];
  isLoading = false;
  EducationStatuses = _.values(User.EducationStatusEnum);
  UserEntity = User;
  SHARED = SHARED;
  errorMessage;
  DEFAULT_EXCLUDED_LISTINGS = ['PETS', 'SMOKING'];
  RolesEnum = User.RolesEnum;


  form = new FormGroup({
    id: new FormControl(''),
    givenName: new FormControl(''),
    familyName: new FormControl(''),
    title: new FormControl(''),
    birthDate: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    gender: new FormControl(''),
    employmentStatus: new FormControl(''),
    address: new FormGroup({
      addressCountry: new FormControl(''),
      addressRegion: new FormControl(''),
      addressLocality: new FormControl(''),
      streetAddress: new FormControl(''),
      formattedAddress: new FormControl(''),
      geolocation: new FormGroup({
        lat: new FormControl(''),
        lng: new FormControl('')
      })
    }),
    profilePicture: new FormGroup({
      id: new FormControl('')
    }),
    spokenLanguages: new FormArray([]),
    nationalities: new FormArray([]),
    aboutMe: new FormControl(''),
    education: new FormControl(''),
    educationStatus: new FormControl(''),
    educationField: new FormControl(''),
    roles: new FormControl(''),
    budget: new FormGroup({
      type: new FormControl('MONTH'),
      currency: new FormControl('USD'),
      min: new FormControl(900),
      max: new FormControl('')
    }),
    photos: new FormArray([]),
    excluded: new FormArray(this.createItemsFromArray(this.DEFAULT_EXCLUDED_LISTINGS)),
    aboutMeInThreeWords: new FormArray(this.createItemsFromArray(Object.values(User.AboutMeInThreeWordsEnum))),
  });

  constructor(
    private _sessionService: SessionService,
    private _usersService: UsersService,
    private _commonService: CommonService,
    private _translateService: TranslateService,
    private _companyService: CompaniesService,
    public httpClient: HttpClient,
    public router: Router,
  ) { }

  ngOnInit() {
    this._sessionService.getCurrentUser(true).then(userResponse => {
      let user = Object.assign({}, userResponse) as User;
      this.currentUser = user;
      let excludedSelectizeOptions = [];
      if (user.excluded) {
        excludedSelectizeOptions = _.difference(user.excluded, this.DEFAULT_EXCLUDED_LISTINGS).map(customExcludedListing => {
          return {
            value: customExcludedListing,
            text: customExcludedListing
          }
        });
        user.excluded = this.form.get('excluded').value.map((value, index) => user.excluded.indexOf(this.DEFAULT_EXCLUDED_LISTINGS[index]) !== -1);
      }
      if (user.aboutMeInThreeWords) {
        user.aboutMeInThreeWords = this.form.get('aboutMeInThreeWords').value.map((value, index) => user.aboutMeInThreeWords.indexOf(Object.values(User.AboutMeInThreeWordsEnum)[index]) !== -1);
      }
      let payload = this.getSanitizedUserPayload(user);

      this.form.patchValue(payload);
      if (user.spokenLanguages && user.spokenLanguages.length) {
        this.addSpokenLanguage(user.spokenLanguages);
      }
      if (user.nationalities && user.nationalities.length) {
        this.addNationality(user.nationalities);
      }

      jQuery(this.excludedSelectize.nativeElement).selectize({
        delimiter: ',',
        persist: false,
        options: excludedSelectizeOptions,
        items: excludedSelectizeOptions.map(option => option.value),
        create: function (input) {
          return {
            value: input,
            text: input
          }
        }
      });
    });
    this._commonService.languagesGet().subscribe(response => this.allLanguagesOptions = response);
    this._commonService.nationalitiesGet().subscribe(response => this.allNationalitiesOptions = response);
    this._companyService.companiesGet().subscribe(response => this.allCompanyOptions = response);
    // this.initializeAutocomplete();

    this.form.valueChanges.subscribe(_.throttle(data => {
      if (!_.isEqual(this.getSanitizedUserPayload(data), this.getSanitizedUserPayload(this.currentUser))) {
        this.saveUser(data)
      }
    }, 3000));
  }

  initializeAutocomplete() {
    if (typeof google !== 'undefined') {
      const autocomplete = new google.maps.places.Autocomplete(this.inputAddress.nativeElement);
      const addressFormGroup = this.form.get('address');
      this.autocompleteListener = google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        let streetAddress = '';
        place.address_components.forEach(address_component => {
          if (address_component.types.includes('country')) {
            addressFormGroup.get('addressCountry').setValue(address_component['long_name']);
          } else if (address_component.types.includes('administrative_area_level_1')) {
            addressFormGroup.get('addressRegion').setValue(address_component['long_name']);
          } else if (address_component.types.includes('locality')) {
            addressFormGroup.get('addressLocality').setValue(address_component['long_name']);
          } else if (address_component.types.includes('route')) {
            streetAddress = address_component['long_name'] + streetAddress;
          } else if (address_component.types.includes('street_number')) {
            streetAddress = streetAddress + ' ' + address_component['long_name'];
          }
        });
        addressFormGroup.get('formattedAddress').setValue(place['formatted_address']);
        addressFormGroup.get('streetAddress').setValue(streetAddress);
        addressFormGroup.get('geolocation').setValue({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        });
        this.inputAddress.nativeElement.focus();
      })
    }

  }

  onClickDeleteUser() {
    this._usersService.usersIdDelete(this.currentUser.id).subscribe(u => this.router.navigate([`/logout`]));
  }

  async saveUser(payload?: any) {
    if (this.form.valid) {
      this.errorMessage = undefined;
      this.isLoading = true;
      let dynamicallyUpdated = false;
      if (payload && !_.isEqual(this.getSanitizedUserPayload(payload), this.getSanitizedUserPayload(this.currentUser))) {
        dynamicallyUpdated = true;
      }
      payload = Object.assign({}, this.form.value, payload) as User;
      if (!payload.profilePicture.id) {
        delete payload.profilePicture;
      }

      if (payload.spokenLanguages.filter(entity => { return (entity.language.id && _.values(entity).some(x => x !== undefined)); })) {
        await Promise.all(payload.spokenLanguages.map(async (spokenLanguage, index) => {
          if (!spokenLanguage.id && spokenLanguage.language.id && spokenLanguage.proficiency) {
            delete spokenLanguage.id;
            payload.spokenLanguages[index] = await this._commonService.spokenlanguagesPost(spokenLanguage).toPromise();
          }
          return spokenLanguage;
        }))
      }
      /**
       * Prepare benefits and other multiple value fields
       */
      payload.excluded = this.DEFAULT_EXCLUDED_LISTINGS.filter((value, index) => payload.excluded[index]);
      payload.excluded = [...payload.excluded, ...this.excludedSelectize.nativeElement.value.split(',')];
      payload.aboutMeInThreeWords = Object.values(User.AboutMeInThreeWordsEnum).filter((value, index) => payload.aboutMeInThreeWords[index]);

      /**
       * Prepare price
       */
      payload.budget.max = payload.budget.min;

      if (payload.budget.max === null) {
        payload.budget.max = payload.budget.min;
      }

      if (!payload.address.geolocation && !payload.address.geolocation.lat) {
        delete payload.address.geolocation;
      }

      if (payload.spokenLanguages.length) {
        payload.spokenLanguages = payload.spokenLanguages.filter(entity => {
          return (entity.language.id && _.values(entity).some(x => x !== undefined));
        });
      }

      if (payload.photos && payload.photos.length) {
        payload.photos = payload.photos.filter(entity => entity.id);
      }

      if (payload.mediaContent && payload.mediaContent.length) {
        payload.mediaContent = payload.mediaContent.filter(entity => entity.id);
      }

      if (payload.nationalities.length) {
        payload.nationalities = payload.nationalities.filter(entity => entity.id);
      }

      payload.address = _.pickBy(payload.address, value => value);
      if (!payload.address.addressCountry) delete payload.address;
      payload = _.pickBy(payload, value => value || (value === false));
      payload.birthDate = moment(payload.birthDate).format('YYYY-MM-DD');
      // lodash reduce maybe, but we should not patch user with the whole payload on every single change
      this._usersService.usersIdPatch(this.getSanitizedUserPayload(payload), this.currentUser.id).subscribe(response => {
        this._usersService.usersMeGet().subscribe(user => {
          this._sessionService.currentUserUpdated(user);
          this.currentUser = user;
          if (user.excluded) {
            user.excluded = this.form.get('excluded').value.map((value, index) => user.excluded.indexOf(this.DEFAULT_EXCLUDED_LISTINGS[index]) !== -1);
          }
          if (user.aboutMeInThreeWords) {
            user.aboutMeInThreeWords = this.form.get('aboutMeInThreeWords').value.map((value, index) => user.aboutMeInThreeWords.indexOf(Object.values(User.AboutMeInThreeWordsEnum)[index]) !== -1);
          }
          if (!dynamicallyUpdated) { // leave this condition be, really... it's the dam between this ( whatever this refers to ) and Mr. Infinite Loop
            this.form.patchValue(this.getSanitizedUserPayload(user));
          }
          this.isLoading = false;
        })
      }, (error) => {
        this.isLoading = false;
        this.errorMessage = error.message;
        window.scrollTo(0, 0);
      })
    } else {
      this.errorMessage = this._translateService.instant('formHasErrors');
    }
  }

  getSanitizedUserPayload(user: User) {
    const sanitizedUserPayload: User = _.pickBy(user, (value) => (_.isArray(value)) ? !!value.length : value || (value === false));
    sanitizedUserPayload.address = _.pickBy(user.address, value => value);
    if (_.isEmpty(sanitizedUserPayload.address)) {
      delete sanitizedUserPayload.address;
    }
    return sanitizedUserPayload;
  }

  ngOnDestroy() {
    if (typeof google !== 'undefined') {
      google.maps.event.removeListener(this.autocompleteListener);
    }
    if (this.excludedSelectize.nativeElement.selectize) {
      this.excludedSelectize.nativeElement.selectize.destroy();
    }
  }

  addSpokenLanguage(userSpokenLanguages?: SpokenLanguage[]) {
    const spokenLanguages = this.form.controls.spokenLanguages as FormArray;
    if (userSpokenLanguages) {
      userSpokenLanguages.forEach(userSpokenLanguage => {
        spokenLanguages.push(new FormGroup({
          id: new FormControl(userSpokenLanguage.id),
          language: new FormGroup({
            id: new FormControl(userSpokenLanguage.language.id),
            name: new FormControl(userSpokenLanguage.language.name),
          }),
          proficiency: new FormControl(userSpokenLanguage.proficiency)
        }));
      })
    } else {
      spokenLanguages.push(new FormGroup({
        id: new FormControl(''),
        language: new FormGroup({
          id: new FormControl(''),
          name: new FormControl('')
        }),
        proficiency: new FormControl('')
      }));
    }
  }

  onKeyUpNationalitiesAutocomplete(event, i) {
    this.nationalitiesOptions[i] = this._filter(event.currentTarget.value, this.allNationalitiesOptions);
  }

  onKeyUpLanguagesAutocomplete(event, i) {
    this.spokenLanguagesLanguageOptions[i] = this._filter(event.currentTarget.value, this.allLanguagesOptions);
  }

  private _filter(value: any, allOptions: any[]): any[] {
    const filterValue = value.toLowerCase();

    return allOptions.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  addNationality(userNationalities?: Nationality[]) {
    const nationalities = this.form.controls.nationalities as FormArray;
    if (userNationalities) {
      userNationalities.forEach(userNationality => {
        let newNationalityControl = new FormGroup({
          id: new FormControl(userNationality.id),
          name: new FormControl(userNationality.name)
        });
        this.nationalitiesOptions.push(this.allNationalitiesOptions);
        nationalities.push(newNationalityControl);
      })
    } else {
      let newNationalityControl = new FormGroup({
        id: new FormControl(''),
        name: new FormControl('')
      });
      this.nationalitiesOptions.push(this.allNationalitiesOptions);
      nationalities.push(newNationalityControl);
    }
  }

  onClickRemoveSpokenLanguage(event: MouseEvent, removedSpokenLanguage: FormControl, i: number) {
    if (!removedSpokenLanguage.get('id').value) {
      this.spokenLanguages.removeAt(i);
    } else {
      this._commonService.spokenlanguagesIdDelete(removedSpokenLanguage.get('id').value).subscribe(() => {
        this.spokenLanguages.removeAt(i);
      });
    }
  }

  onClickRemoveNationality(event: MouseEvent, i: number) {
    this.nationalitiesOptions.splice(i, 1);
    this.nationalities.removeAt(i);
  }

  isRoleChecked(userRole: User.RolesEnum): boolean {
    if (this.form.get('roles').value.indexOf(userRole) !== -1) {
      return true;
    }

    return false;
  }

  createItemsFromArray(itemsArray): FormControl[] {
    return itemsArray.map(item => {
      return new FormControl(false);
    });
  }

  getFormData(property): FormArray {
    return <FormArray>this.form.get(property);
  }

  getEnumKeyAtIndex(enumObject, index) {
    return Object.values(enumObject)[index];
  }

  getEnumValues(enumObject) {
    return Object.values(enumObject);
  }

  get spokenLanguages(): FormArray { return this.form.get('spokenLanguages') as FormArray; }
  get nationalities(): FormArray { return this.form.get('nationalities') as FormArray; }
  get photos(): FormArray { return this.form.get('photos') as FormArray; }

  nationalityDisplayFn(nationality?: Nationality): string | undefined {
    return nationality ? nationality.name : undefined;
  }

  languageDisplayFn(language?: Language): string | undefined {
    return language ? language.name : undefined;
  }

  companyDisplayFn(company?: Company): string | undefined {
    return company ? company.name : undefined;
  }

  onOptionSelectedNationality(event: MatAutocompleteSelectedEvent, control: FormControl) {
    control.setValue({
      id: event.option.value.id,
      name: event.option.value.name
    });
  }

  onOptionSelectedLanguage(event: MatAutocompleteSelectedEvent, control: FormControl) {
    control.setValue({
      id: event.option.value.id,
      name: event.option.value.name
    });
  }

  hasRole(role) {
    return this._sessionService.hasRole(role);
  }

  onChangeRole(userRole: User.RolesEnum) {
    this._usersService.usersIdPatch(<User><unknown>{
      roles: [User.RolesEnum.USER, userRole]
    }, this.currentUser.id).subscribe(response => {
      this.currentUser = Object.assign(this.currentUser, {
        roles: response.roles
      });
      this._sessionService.currentUserUpdated(this.currentUser);
    });
  }
}
