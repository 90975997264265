import { Component, OnInit } from '@angular/core';
import { User, UsersService } from 'src/app/swagger-generated';
import { SessionService } from 'src/app/services/session.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import * as _ from "lodash";
import { getMediaContentUrl } from 'src/app/services/mediaContent.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  currentUser: User;
  RolesEnum = User.RolesEnum;

  constructor(
    private sessionService: SessionService,
    private _translateService: TranslateService,
    private _userService: UsersService,
    private _router: Router
  ) {
    this._router.events.subscribe(val => {
      // see also 
      if (val instanceof NavigationEnd) {
        if (val.url === '/dashboard') {
          if (this.hasRole(User.RolesEnum.USER)) {
            this._router.navigate(['/dashboard/applied-listings']);
          } else if (this.hasRole(User.RolesEnum.REALESTATE)) {
            this._router.navigate(['/dashboard/listings']);
          }
        }
      }
    })
  }

  ngOnInit() {
    this.sessionService.getCurrentUser().then((user: User) => {
      this.currentUser = user;
    });
    
    this.sessionService.currentUserUpdated$.subscribe(currentUser => this.currentUser = currentUser);
  }

  get profilePictureUrl() {
    return getMediaContentUrl(this.currentUser.profilePicture, 'user', 'thumb');
  }

  hasRole(role) {
    return this.sessionService.hasRole(role);
  }

  get userRoleLabel() {
    if (this.currentUser) {
      if (this.currentUser.roles.indexOf(User.RolesEnum.USER) !== -1) {
        return this._translateService.instant('candidate');
      } else if (this.currentUser.roles.indexOf(User.RolesEnum.REALESTATE) !== -1) {
        return this._translateService.instant('recruiter');
      }
    }
  }

  profilePercentage() {
    const nonProfileFields = ['administratorOf', 'createdAt', 'employeeOf', 'id', 'isBlocked', 'isFacebookUser', 'isGoogleUser', 'isPublic', 'roles', 'updatedAt'];
    
    const nonNullFieldsLength = _.values(_.omit(_.pickBy(this.currentUser, (value) => (_.isArray(value)) ? !!value.length : value || (value === false)), nonProfileFields)).length;
    const totalFieldsLength = _.values(_.omit(this.currentUser, nonProfileFields)).length;

    return parseInt((100*nonNullFieldsLength/totalFieldsLength).toString())
  }


  onChangeRole(userRole: User.RolesEnum) {
    this._userService.usersIdPatch(<User><unknown>{
      roles: [User.RolesEnum.USER, userRole]
    }, this.currentUser.id).subscribe(response => {
      this.currentUser = Object.assign(this.currentUser, {
        roles: response.roles
      });
      this.sessionService.currentUserUpdated(this.currentUser);
      if (userRole === User.RolesEnum.USER) {
        this._router.navigate(['/dashboard/applied-listings']);
      } else if (userRole === User.RolesEnum.REALESTATE) {
        this._router.navigate(['/dashboard/listings']);
      }
    });
  }


}
