<table class="table">
  <thead>
    <tr>
      <th>Title</th>
      <th></th>
      <th>Source</th>
      <th>Occupancy</th>
      <th>Website</th>
      <th>Amenities</th>
      <th>Address</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let company of externalCompanies">
      <td>
        <img *ngIf="company.mediaContent" [src]="company.mediaContent[0].url" class="img-fluid company-thumb"/>
        <br >
        {{company.name}}
      </td>
      <td>
        <button class="btn btn-primary" (click)="onClickAddCompany(company)" *ngIf="!company.homiCompany">Add</button>
        <div *ngIf="company.homiCompany">
          <i-feather name="check-circle" class="text-success"></i-feather>
          <a [routerLink]="'/companies/' + company.homiCompany.id">View</a>
        </div>
      </td>
      <td>
        {{company.externalSource}}
        <div *ngIf="company.homiCompany">
          <small *ngIf="company.homiCompany?.autoSyncListings" class="text-success">AUTO SYNC</small>
          <small *ngIf="!company.homiCompany?.autoSyncListings" class="text-danger">NO SYNC</small>
        </div>
      </td>
      <td>{{company.availableUnits || '?'}} / {{company.totalUnits || '?'}}</td>
      <td><a class="company-website" href="{{company.website}}" target="_blank">{{company.website}}</a></td>
      <td>{{company.amenities ? company.amenities.join(', '): ''}}</td>
      <td>{{company.address.formattedAddress}}</td>
    </tr>
  </tbody>
</table>