<div class="container-fluid px-5 candidate-wrapper mb-5">
  <div class="row">
    <div class="col-lg-3">
      <div class="card">
        <div class="card-body top-details">
          <div class="thumb rounded-circle" [style.background-image]="'url(' + profilePictureUrl + ')'"></div>
          <div class="info text-center">
            <h5 class="name mt-2">{{nameLabel}}</h5>
            <div class="job-title mb-3" *ngIf="user?.title">
              {{user?.title}}
            </div>
            <div class="contact-details my-2">
              <div class="candidate-interact">
                <button *ngIf="connectionStatus === 'NONE'" type="button" class="btn btn-link btn-sm"
                  (click)="onClickConnectButton('NONE')"><img src="/assets/img/homi_theme/user-add.png" srcset="/assets/img/homi_theme/user-add@2x.png 2x,
                  /assets/img/homi_theme/user-add@3x.png 3x"> Connect</button>
                <button *ngIf="connectionStatus === 'RECEIVED'" type="button" class="btn btn-success btn-sm"
                  (click)="onClickConnectButton('RECEIVED')">Accept connection</button>
                <button *ngIf="connectionStatus === 'SENT'" type="button" class="btn btn-link text-danger btn-sm"
                  (click)="onClickConnectButton('SENT')">Pending approval... <i-feather name="x-circle"></i-feather>
                </button>
                <a routerLink="/messages" [queryParams]="{userId: user?.id}" *ngIf="connectionStatus === 'CONNECTED'"
                  type="button" class="btn btn-primary btn-sm">
                  <i-feather class="send-message" name="message-square"></i-feather> Message
                </a>
                <div class="mt-2">
                  <a *ngIf="connectionStatus === 'CONNECTED'" class="text-danger"
                    (click)="onClickConnectButton('CONNECTED')">Remove connection</a>
                </div>

              </div>
              <div class="address mt-2" *ngIf="user?.address?.addressLocality">
                <img src="/assets/img/homi_theme/map-marker-alt.png" srcset="/assets/img/homi_theme/map-marker-alt@2x.png 2x,
                /assets/img/homi_theme/map-marker-alt@3x.png 3x"> {{user?.address.addressLocality}},
                {{user?.address.addressCountry}}
              </div>
              <div class="phone mt-2" *ngIf="user?.phone && showFullName">
                <a href="tel:{{user?.phone}}">
                  <img src="/assets/img/homi_theme/phone-receiver.png" srcset="/assets/img/homi_theme/phone-receiver@2x.png 2x,
                /assets/img/homi_theme/phone-receiver@3x.png 3x">{{user?.phone}}
                </a>
              </div>
              <div class="email mt-2" *ngIf="user?.email && showFullName">
                <a href="mailto:{{user?.email}}">
                  <img src="/assets/img/homi_theme/envelope.png" srcset="/assets/img/homi_theme/envelope@2x.png 2x,
                /assets/img/homi_theme/envelope@3x.png 3x"> {{user?.email}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <!-- PHOTOS -->
      <div class="card" *ngIf="mediaContents && mediaContents.length">
        <div class="card-body">
          <div class="photos-wrapper">
            <app-media-contents-gallery [mediaContents]="mediaContents"></app-media-contents-gallery>
          </div>
        </div>
      </div>
      <!-- END PHOTOS -->

      <!-- WHY YOU SHOULD EMPLOY ME -->
      <div class="card mt-2" *ngIf="user?.aboutMe">
        <div class="card-body">
          <div class="photos-title">Why would you employ me?
          </div>
          <p class="about-me-text mt-3 info-detail" [innerHtml]="user?.aboutMe"></p>
        </div>
      </div>
      <!-- END WHY YOU SHOULD EMPLOY ME -->
    </div>

    <div class="col-lg-3">
      <div class="card">
        <div class="card-body">
          <div class="education-level-wrapper">
            <div class="education-level" *ngIf="user?.education"><img
                src="/assets/img/homi_theme/user-graduate.png" srcset="/assets/img/homi_theme/user-graduate@2x.png 2x,
              /assets/img/homi_theme/user-graduate@3x.png 3x">
              {{'filterValues.educationRequirements.' + user?.education | translate}}</div>
            <div class="education-field" *ngIf="user?.educationField">
              {{'filterValues.educationFields.' + user?.educationField | translate}}
            </div>
          </div>

          <div *ngIf="user?.nationalities?.length" class="user-info">
            <div class="user-info-title">Nationality</div>
            <ul>
              <li *ngFor="let nationality of user?.nationalities">
                {{nationality?.name}} </li>
            </ul>
          </div>

          <div *ngIf="user?.spokenLanguages?.length" class="user-info">
            <div class="user-info-title">Speaks</div>
            <ul>
              <li *ngFor="let spokenLanguage of user?.spokenLanguages">
                <span class="spoken-language-name mr-1">{{spokenLanguage?.language?.name}}</span>
                <span class="spoken-language-proficiency">({{spokenLanguage?.proficiency}})</span> </li>
            </ul>
          </div>

          <div *ngIf="user?.employmentStatus" class="user-info">
            <div class="user-info-title">Employment Status</div>
            <ul>
              <li>
                {{'filterValues.employmentStatus.' + user.employmentStatus | translate}}
              </li>
            </ul>
          </div>

          <div *ngIf="user?.budget" class="user-info">
            <div class="user-info-title">Salary Expectations</div>
            <ul>
              <li>
                {{ user?.budget | currency:'USD' }}
              </li>
            </ul>
          </div>

          <div *ngIf="user?.rentingAvailability" class="user-info">
            <div class="user-info-title">Renting Availability</div>
            <ul>
              <li>
                {{'filterValues.rentingAvailability.' + user.rentingAvailability | translate}}
              </li>
            </ul>
          </div>

          <div *ngIf="user?.employmentStatus" class="user-info">
            <div class="user-info-title">Employment Status</div>
            <ul>
              <li>
                {{'filterValues.employmentStatus.' + user.employmentStatus | translate}}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="candidate-map" *ngIf="user?.address?.geolocation">
        <div class="candidate-map-title my-4">Candidate Location</div>
        <div class="candidate-location-image-wrapper">
          <div class="location-marker"></div>
          <img class="img-fluid rounded" [src]="googleMapsImage" />
        </div>
      </div>
    </div>
  </div>

  <div id="featureSoonAvailable" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>This feature will be soon available! If you would like to hear more details, please <button
              class="btn-link" data-dismiss="modal" routerLink="/contact">contact us!</button> </p>
        </div>
        <div class="modal-footer">
          <button data-dismiss="modal" routerLink="/contact" class="btn btn-primary">Contact us</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>