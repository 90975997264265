import { Component, OnInit, ViewChild, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { ListingsService, Listing, User, ListingApplication, MediaContent } from 'src/app/swagger-generated';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { PlyrComponent } from 'ngx-plyr';
import Plyr from 'plyr';
import { getMediaContentUrl } from 'src/app/services/mediaContent.service';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnChanges {
  @Input('listing') listing: Listing;
  @Input('showBack') showBack = false;
  @Output() clickBack: EventEmitter<string> = new EventEmitter();
  isFavorited: boolean;
  currentUser: User;
  listingApplication: ListingApplication;
  @ViewChild(PlyrComponent)
  plyr: PlyrComponent;
  player: Plyr;
  listingId;

  constructor(
    private listingsService: ListingsService,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
    private router: Router
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.listingId = params.id;
      this.fetchListing();
    })
  }

  ngOnChanges() {
    if (this.listing) {
      this.listingId = this.listing.id;
    } 
    this.fetchListing();
  }

  fetchListing() {
    if (this.listingId) {
      const joinQueryString = [
        'address',
        'usersFavorited',
        'price',
        'listingApplications',
        'listingApplications.user',
        'listingApplications.user.profilePicture',
        'mediaContent',
        'ownerUsers',
        'userLabel',
        'userLabel.profilePictureUserLabel',
        'companyLabel',
        'companyLabel.companyLogo',
        'owner',
        'owner.logo',
        'owner.address',
        'requiredLanguages',
        'requiredLanguages.language',
        'thumbnail',
        'postedAsCompany'
      ]
      this.listingsService.listingsIdGet(this.listingId, undefined, joinQueryString).subscribe(response => {
        this.listing = response;
        this.sessionService.getCurrentUser().then(currentUser => {
          this.currentUser = currentUser;
          this.isFavorited = !!this.listing.usersFavorited.find(user => user.id === currentUser.id)
          if (this.listing.listingApplications) {
            this.listingApplication = this.listing.listingApplications.find(listingApplication => listingApplication.user.id === this.currentUser.id);
          }
        }, err => this.currentUser = null);
      });
    }
  }

  onClickFavorite() {
    if (this.currentUser) {
      if (this.isFavorited) {
        this.listingsService.listingsIdFavoriteDelete(this.listing.id).subscribe(response => this.listing = Object.assign(this.listing, response));
      } else {
        this.listingsService.listingsIdFavoritePost(this.listing.id).subscribe(response => this.listing = Object.assign(this.listing, response));
      }
      this.isFavorited = !this.isFavorited;
    } else {
      this.router.navigate(['login']);
    }
  }

  onClickApply() {
    if (this.currentUser) {
      if (this.listingApplication) {
        this.listingsService.listingApplicationsIdDelete(this.listingApplication.id).subscribe(() => {
          this.listingApplication = null;
        });
      } else {
        const listingApplication: ListingApplication = <ListingApplication>{
          listing: this.listing,
          user: this.currentUser
        }
        this.listingsService.listingApplicationsPost(listingApplication).subscribe(listingApplicationResponse => {
          this.listingApplication = listingApplicationResponse;
        });
      }

    } else {
      this.router.navigate(['login']);
    }
  }

  getMediaContentUrl(mediaContent: MediaContent, entityType: string, size?: string) {
    return getMediaContentUrl(mediaContent, entityType, size);
  }

  onClickBack(ev) {
    this.clickBack.emit(ev);
  }

  /**
   * TODO - optimize currentUser fetching mechanism
   */
  get canEdit(): boolean {
    if (this.currentUser) {
      return this.listing && this.listing.ownerUsers && !!this.listing.ownerUsers.find(user => user.id === this.currentUser.id);
    }

    return false;
  }

  get googleMapsImage() {
    const coords = this.address.geolocation;
    return `https://maps.googleapis.com/maps/api/staticmap?center=${coords.lat},${coords.lng}&zoom=13&size=300x300&maptype=roadmap&markers=${coords.lat},${coords.lng}&key=AIzaSyAdQQP16TXbwkzjcrA5DgoGsVSe2Pi3EQo`
  }

  get mediaContents() {
    if (this.listing && this.listing.mediaContent) {
      return [...this.listing.mediaContent];
    }
    return [];
  }

  get title() {
    if (this.listing.title) {
      return this.listing.title;
    }
    return this.listing.owner.name + ' ' + this.listing.rooms + ' bdr'
  }

  get address() {
    if (this.listing) {
      if (this.listing.address) {
        return this.listing.address;
      }
      return this.listing.owner.address;
    }
  }
}
