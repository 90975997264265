<mat-spinner *ngIf="isLoading"></mat-spinner>
<div class="container login-register-wrapper">
  <div class="card my-5 col-md-6" *ngIf="!isRegisterMode">
    <div class="card-body p-4">
      <div class="row">
        <div class="col-md-12 text-center">
          <h2>Login</h2>
        </div>
        <div class="col-md-12">
          <div class="access-form">
            <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorMessage">
              {{errorMessage}}
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form class="text-center" (ngSubmit)="onSubmitLoginForm()" [formGroup]="loginForm" *ngIf="loginType === 'email'">
              <div class="col-md-12">
                <div class="form-group">
                  <input type="email" placeholder="{{'Email' | translate}}" class="form-control" formControlName="email"
                    required name="email">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input type="password" placeholder="{{'Password' | translate}}" class="form-control"
                    formControlName="password" required>
                </div>
              </div>
              <button class="btn btn-primary btn-lg">Login</button>
            </form>

            <div class="phone-login" *ngIf="loginType === 'mobile'">
              <div class="account-info" [ngClass]="(validationCodeSent) ? 'text-center hidden' : 'text-center'">
                <input ng2TelInput (intlTelInputObject)="telInputObject($event)" type="tel" class="form-control"
                  aria-describedby="phoneHelp" #phoneInput [ng2TelInputOptions]="phoneInputOptions">
                <form [formGroup]="authorizeForm" (ngSubmit)="onSubmitAuthorizeForm()" class="row m-0">
                  <div class="col-lg-12 text-center">
                    <small id="phoneHelp" class="form-text text-muted">Use a valid phone number - we will send you a
                      code via SMS.</small>
                  </div>
                  <div class="col-lg-12 text-center mt-3">
                    <button class="btn btn-primary btn-lg" [disabled]="!intTelInputObject.isValidNumber()">Request
                      code</button>
                  </div>
                </form>
              </div>
              <div class="validate-code" *ngIf="validationCodeSent && !errorMessage">
                <form [formGroup]="verifyForm" (ngSubmit)="onSubmitVerifyForm()" class="row m-0">
                  <input type="hidden" formControlName="token" required name="token">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <input type="number" placeholder="{{'Code' | translate}}" class="form-control"
                        formControlName="code" required name="code">
                    </div>
                  </div>
                  <div class="col-lg-12 text-center mt-3">
                    <button class="btn btn-primary btn-lg" [disabled]="!verifyForm.valid">Verify</button>
                  </div>
                </form>
              </div>
              <div class="text-center" *ngIf="errorMessage">
                <div class="btn btn-link" *ngIf="errorMessage" (click)="onClickTryAgain()">Try again...</div>
              </div>
            </div>

            <div>
              <button *ngIf="loginType !== 'mobile'" type="button" class="btn btn-link"
                (click)="onClickSwitchLogin('mobile')">Login with my mobile
                phone</button>
              <button *ngIf="loginType !== 'email'" type="button" class="btn btn-link"
                (click)="onClickSwitchLogin('email')">Login with my email</button>
            </div>
            <div class="shortcut-login my-4 text-center">
              <span>Or connect with</span>
              <div class="buttons">
                <a class="btn btn-light facebook" (click)="onFacebookClick()">Facebook</a>
                <a class="btn btn-light google" (click)="onGoogleClick()">Google</a>
              </div>
            </div>
            <div>
              <p>Don't have an account? <a routerLink="/register">Register</a></p>
              <p><a routerLink="/forgot-password">Forgot your password?</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Registration form  -->
  <div class="card my-5 col-md-6" *ngIf="isRegisterMode">
    <div class="card-body p-4">
      <div class="row">
        <div class="col-md-12 text-center">
          <h2>Registration</h2>
          <p>Sign up now and start managing your listings!</p>
        </div>
        <div class="account-type col-md-12">
          <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorMessage">
            {{errorMessage}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="account-type-label">I AM A...</div>
          <div class="role-pick my-3">
            <label for="idRegisterCan">
              <input id="idRegisterCan" type="radio" name="register" (click)="selectedRole='USER'" checked>
              <span> Roommate</span>
            </label>
            <label class="ml-2" for="idRegisterEmp">
              <input id="idRegisterEmp" type="radio" name="register" (click)="selectedRole='REALESTATE'">
              <span> Landlord</span>
            </label>
          </div>
        </div>
        <div class="account-info">
          <form [formGroup]="registerForm" (ngSubmit)="onSubmitRegisterForm()" class="row m-0">
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" placeholder="{{'First Name' | translate}}" class="form-control"
                  formControlName="givenName" name="givenName">
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group">
                <input type="text" placeholder="{{'Last Name' | translate}}" class="form-control"
                  formControlName="familyName" name="familyName">
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <input type="email" placeholder="{{'Email' | translate}}" class="form-control" formControlName="email"
                  required name="email">
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <input type="password" placeholder="{{'Password' | translate}}" class="form-control"
                  formControlName="password" required>
              </div>
            </div>
            <div class="col-md-12">

              <label for="radio-4"><input class="custom-radio" type="checkbox" id="radio-4" name="termsandcondition"
                  formControlName="acceptTerms" required> I accept the <a routerLink="/terms">terms & conditions</a> and
                I am over the
                age of 16.
              </label>
            </div>
            <button class="btn btn-primary btn-lg" [disabled]="!registerForm.valid">Register</button>
          </form>
          <div class="shortcut-login my-4 text-center">
            <span>Or connect with</span>
            <div class="buttons">
              <a class="btn btn-light facebook" (click)="onFacebookClick()">Facebook</a>
              <a class="btn btn-light google" (click)="onGoogleClick()">Google</a>
            </div>
          </div>
          <p>Already have an account? <a routerLink="/login">Login</a></p>
        </div>
      </div>
    </div>
  </div>
</div>