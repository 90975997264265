import { Component, OnInit } from '@angular/core';
import { CommonService, NotificationsService, Notification } from '../../swagger-generated';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications-dropdown',
  templateUrl: './notifications-dropdown.component.html',
  styleUrls: ['./notifications-dropdown.component.scss']
})
export class NotificationsDropdownComponent implements OnInit {
  newNotificaitons = 0;
  notifications = [];

  constructor(
    private _notificationsService: NotificationsService,
    private _router: Router
  ) { }

  ngOnInit() {
    this._notificationsService.notificationsGet(undefined, undefined, undefined, ['createdAt,DESC']).subscribe(notifications => {
      this.notifications = notifications;
      let newNotifCount = 0;
      notifications.forEach(notif => { if (notif.status === Notification.StatusEnum.NEW) newNotifCount++ });
      this.newNotificaitons = newNotifCount;
    })
  }

  onClickExpandNotifications() {
    this._notificationsService.notificationsResetNewPost().subscribe(result => {
      this.newNotificaitons = 0;
    });
  }

  getNotificationLabel(notification: Notification) {
    const payload = JSON.parse(notification.data);

    switch (notification.type) {
      case 'CONNECTION_REQUEST': 
        return `${payload.users.from.givenName} ${payload.users.from.familyName} sent you a connection request.`;
      case 'CONNECTION_REQUEST_ACCEPTED': 
      return `${payload.users.to.givenName} ${payload.users.to.familyName} accepted your connection request.`;
    }
  }

  onClickNotification(notification: Notification) {
    const payload = JSON.parse(notification.data);

    switch (notification.type) {
      case 'CONNECTION_REQUEST': 
        return this._router.navigate([`/candidates/${payload.users.from.id}`]);
      case 'CONNECTION_REQUEST_ACCEPTED': 
        return this._router.navigate([`/candidates/${payload.users.to.id}`]);
    }
  }

}
