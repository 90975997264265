/**
 * Homi Server Endpoints
 * Available interactions with the Homi Backend services
 *
 * OpenAPI spec version: 0.0.17
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { FloorPlan } from './floorPlan';
import { Listing } from './listing';
import { MediaContent } from './mediaContent';
import { ModelDate } from './modelDate';
import { User } from './user';


export interface Company {
    id: string;
    createdAt: ModelDate;
    updatedAt: ModelDate;
    /**
     * Name
     */
    name: string;
    /**
     * Email
     */
    email: string;
    /**
     * Phone
     */
    phone: string;
    companyActivityType: Company.CompanyActivityTypeEnum;
    isExternal: boolean;
    externalSource: Company.ExternalSourceEnum;
    externalId: string;
    companyLogo: MediaContent;
    /**
     * Website Address
     */
    website: string;
    /**
     * Slogan
     */
    slogan: string;
    /**
     * Description
     */
    description: string;
    autoSyncListings: boolean;
    administrators: Array<User>;
    employees: Array<User>;
    address: Address;
    amenities: Array<Company.AmenitiesEnum>;
    internalNotes: string;
    totalUnits: number;
    availableUnits: number;
    slug: string;
    mediaContent: Array<MediaContent>;
    photos: Array<MediaContent>;
    listingsLabeled: Array<Listing>;
    floorPlans: Array<FloorPlan>;
    listings: Array<Listing>;
}
export namespace Company {
    export type CompanyActivityTypeEnum = 'DEVELOPER' | 'REALESTATE' | 'INDEPENDENT' | 'APARTMENTCOMPLEX';
    export const CompanyActivityTypeEnum = {
        DEVELOPER: 'DEVELOPER' as CompanyActivityTypeEnum,
        REALESTATE: 'REALESTATE' as CompanyActivityTypeEnum,
        INDEPENDENT: 'INDEPENDENT' as CompanyActivityTypeEnum,
        APARTMENTCOMPLEX: 'APARTMENTCOMPLEX' as CompanyActivityTypeEnum
    }
    export type ExternalSourceEnum = 'BOZZUTO' | 'EQUITY_APARTMENTS' | 'MILLCREEK' | 'APARTMENTSCOM';
    export const ExternalSourceEnum = {
        BOZZUTO: 'BOZZUTO' as ExternalSourceEnum,
        EQUITYAPARTMENTS: 'EQUITY_APARTMENTS' as ExternalSourceEnum,
        MILLCREEK: 'MILLCREEK' as ExternalSourceEnum,
        APARTMENTSCOM: 'APARTMENTSCOM' as ExternalSourceEnum
    }
    export type AmenitiesEnum = 'PETS' | 'PETS_SMALL' | 'PETS_LARGE' | 'PETS_DOGS' | 'SMOKING_AREA' | 'PARKING' | 'PARKING_GARAGE' | 'PARKING_STREET' | 'SWIMMING_POOL' | 'PUBLIC_TRASPORTATION_ACCESSIBLE' | 'LAUNDRY' | 'GYM' | 'FURNITURE';
    export const AmenitiesEnum = {
        PETS: 'PETS' as AmenitiesEnum,
        PETSSMALL: 'PETS_SMALL' as AmenitiesEnum,
        PETSLARGE: 'PETS_LARGE' as AmenitiesEnum,
        PETSDOGS: 'PETS_DOGS' as AmenitiesEnum,
        SMOKINGAREA: 'SMOKING_AREA' as AmenitiesEnum,
        PARKING: 'PARKING' as AmenitiesEnum,
        PARKINGGARAGE: 'PARKING_GARAGE' as AmenitiesEnum,
        PARKINGSTREET: 'PARKING_STREET' as AmenitiesEnum,
        SWIMMINGPOOL: 'SWIMMING_POOL' as AmenitiesEnum,
        PUBLICTRASPORTATIONACCESSIBLE: 'PUBLIC_TRASPORTATION_ACCESSIBLE' as AmenitiesEnum,
        LAUNDRY: 'LAUNDRY' as AmenitiesEnum,
        GYM: 'GYM' as AmenitiesEnum,
        FURNITURE: 'FURNITURE' as AmenitiesEnum
    }
}
