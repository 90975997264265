<div class="dialog-container p-3">
  <div *ngIf="!success && !error">
    <sf-form [schema]="applicationSchema" [(model)]="application" #form></sf-form>
    <small class="text-muted">Submitting this application, you accept our terms and conditions.</small>
    <div class="text-center mt-2">
      <button class="btn btn-primary btn-lg" (click)="onClickApply()">Apply</button>
    </div>
  </div>
  <div *ngIf="success" class="text-center">
    <h3 class="text-success">You application has been submitted!</h3>
    <p>We will reach you out in 24 hours.</p>
    <button class="btn btn-link" (click)="onClickClose()">Close</button>
  </div>
  <div *ngIf="error" class="text-center">
    <div class="alert alert-danger">Unfortunately we could not submit your application request.</div>
    <button class="btn btn-link" (click)="onClickClose()">Close</button>
  </div>
  
</div>