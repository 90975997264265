import { NgModule } from '@angular/core';

import { FeatherModule } from 'angular-feather';
import {
  Camera, Heart, Github, MapPin, Briefcase, Clock, Search, Eye, Trash2, Edit, ExternalLink, Mail, Zap,
  Book,
  Gift, Printer,
  Flag,
  UploadCloud,
  AlignLeft,
  Globe,
  CheckCircle,
  Video,
  Upload,
  Feather,
  Users,
  PenTool,
  Edit2,
  Edit3,
  UserCheck,
  Lock,
  Download,
  DollarSign,
  AlignJustify,
  Home,
  XCircle,
  Save,
  UserPlus,
  Image,
  Star,
  AlertOctagon,
  Trash,
  Facebook,
  Instagram,
  Linkedin,
  Frown,
  List,
  Grid,
  MessageSquare,
  RefreshCw
} from 'angular-feather/icons';

const icons = {
  Camera,
  Heart,
  Github,
  MapPin,
  Briefcase,
  Clock,
  Search,
  Eye,
  Trash2,
  Edit,
  ExternalLink,
  Mail,
  Zap,
  Book,
  Gift,
  Printer,
  Flag,
  UploadCloud,
  AlignLeft,
  Globe,
  CheckCircle,
  Video,
  Upload,
  Feather,
  Users,
  PenTool,
  Edit2,
  Edit3,
  UserCheck,
  Lock,
  Download,
  DollarSign,
  AlignJustify,
  Home,
  XCircle,
  Save,
  UserPlus,
  Image,
  Star,
  AlertOctagon,
  Trash,
  Facebook,
  Instagram,
  Linkedin,
  Frown,
  List,
  Grid,
  MessageSquare,
  RefreshCw
};

@NgModule({
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule { }