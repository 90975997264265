import { Component, Injector, ViewChild, OnInit, AfterContentChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { CompaniesService, Company, MediaContentService } from '../../../../swagger-generated';
import { SwaggerDocOptimizerService } from 'ngx-swag-form';
import { FormGenericComponent } from '../form-generic/form-generic.component';

@Component({
  selector: 'app-form-company',
  templateUrl: './form-company.component.html'
})
export class FormCompanyComponent extends FormGenericComponent implements AfterContentChecked {
  get definitionName() { return 'Company' };
  // get widgetName() { return 'company' };
  companyData: any;
  companyDataAdded = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public swaggerDocOptimizerService: SwaggerDocOptimizerService,
    private _companiesService: CompaniesService,
    private _mediaContentService: MediaContentService,
    private _router: Router

  ) {
    super(activatedRoute, swaggerDocOptimizerService);
    this.activatedRoute.queryParams.subscribe(data => {
      if (data.companyData) {
        this.companyData = JSON.parse(decodeURI(data.companyData));
      }
    })
  }

  pullData() {
    let joinArray = [
      'address',
      'mediaContent'
    ];

    this._companiesService.companiesIdGet(this.data.id, undefined, joinArray).subscribe(data => {
      this.data = data;
      this.form.rootProperty.getProperty('mediaContent').setValue(_.sortBy(data.mediaContent, 'weight'));
      this.form.rootProperty.getProperty('amenities').setValue(data.amenities);
    });
  }

  ngAfterContentChecked() {
    if (!this.companyDataAdded && this.form && this.companyData) {
      this.form.rootProperty.setValue(this.companyData);
      this.companyDataAdded = true;
    }
  }

  async saveData() {
    return Promise.all(this.data.mediaContent.map( mediaContent => new Promise(async (resolve, reject) => {
      if (!mediaContent.id) {
        await this._mediaContentService.mediaContentPost(mediaContent).subscribe(response => {
          resolve(response);
        })
      } else {
        resolve(mediaContent);
      }
    }))).then(response => {
      this.payload.mediaContent = response;
      return new Promise((resolve, reject)=> { 
        this._companiesService.companiesPost(this.payload).subscribe(response => {
          resolve(response);
        }, err => reject(err));
      }) 
    })
  }

  async updateData() {
    return Promise.all(this.data.mediaContent.map(mediaContent => new Promise(async (resolve, reject) => {
      if (!mediaContent.id) {
        await this._mediaContentService.mediaContentPost(mediaContent).subscribe(response => {
          resolve(response);
        })
      } else {
        resolve(mediaContent);
      }
    }))).then(response => {
      this.payload.mediaContent = response;
      return this._companiesService.companiesIdPatch(this.payload, this.data.id).toPromise();
    })
  }

  deleteData() {
    return this._companiesService.companiesIdDelete(this.data.id).toPromise();
  }

  handleSavedData(response) {
    this._router.navigate(['companies', response.id]);
  }

  handleUpdatedData(response) {
    this._router.navigate(['companies', response.id]);
  }
}