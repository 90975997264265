/**
 * Homi Server Endpoints
 * Available interactions with the Homi Backend services
 *
 * OpenAPI spec version: 0.0.17
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Company } from './company';
import { ListingApplication } from './listingApplication';
import { MediaContent } from './mediaContent';
import { ModelDate } from './modelDate';
import { User } from './user';


export interface Listing {
    id: string;
    createdAt: ModelDate;
    updatedAt: ModelDate;
    showOwner: boolean;
    autoSync: boolean;
    title: string;
    description: string;
    amenities: Array<Listing.AmenitiesEnum>;
    baths: number;
    price: number;
    rooms: number;
    beds: number;
    applicationDeadline: ModelDate;
    available: ModelDate;
    educationRequirements: Listing.EducationRequirementsEnum;
    workRequirements: Listing.WorkRequirementsEnum;
    postedAsCompany: boolean;
    status: Listing.StatusEnum;
    type: Listing.TypeEnum;
    internalNotes: string;
    published: boolean;
    featured: boolean;
    dateAvailable: ModelDate;
    usersFavorited: Array<User>;
    listingApplications: Array<ListingApplication>;
    mediaContent: Array<MediaContent>;
    owner: Company;
    ownerUsers: Array<User>;
    address: Address;
    userLabel: User;
    companyLabel: Company;
}
export namespace Listing {
    export type AmenitiesEnum = 'PETS' | 'PETS_SMALL' | 'PETS_LARGE' | 'PETS_DOGS' | 'SMOKING_AREA' | 'PARKING' | 'PARKING_GARAGE' | 'PARKING_STREET' | 'SWIMMING_POOL' | 'PUBLIC_TRASPORTATION_ACCESSIBLE' | 'LAUNDRY' | 'GYM' | 'FURNITURE';
    export const AmenitiesEnum = {
        PETS: 'PETS' as AmenitiesEnum,
        PETSSMALL: 'PETS_SMALL' as AmenitiesEnum,
        PETSLARGE: 'PETS_LARGE' as AmenitiesEnum,
        PETSDOGS: 'PETS_DOGS' as AmenitiesEnum,
        SMOKINGAREA: 'SMOKING_AREA' as AmenitiesEnum,
        PARKING: 'PARKING' as AmenitiesEnum,
        PARKINGGARAGE: 'PARKING_GARAGE' as AmenitiesEnum,
        PARKINGSTREET: 'PARKING_STREET' as AmenitiesEnum,
        SWIMMINGPOOL: 'SWIMMING_POOL' as AmenitiesEnum,
        PUBLICTRASPORTATIONACCESSIBLE: 'PUBLIC_TRASPORTATION_ACCESSIBLE' as AmenitiesEnum,
        LAUNDRY: 'LAUNDRY' as AmenitiesEnum,
        GYM: 'GYM' as AmenitiesEnum,
        FURNITURE: 'FURNITURE' as AmenitiesEnum
    }
    export type EducationRequirementsEnum = 'HIGHSCHOOL' | 'COLLEGE' | 'GRADUATE';
    export const EducationRequirementsEnum = {
        HIGHSCHOOL: 'HIGHSCHOOL' as EducationRequirementsEnum,
        COLLEGE: 'COLLEGE' as EducationRequirementsEnum,
        GRADUATE: 'GRADUATE' as EducationRequirementsEnum
    }
    export type WorkRequirementsEnum = 'STUDENT' | 'EMPLOYED' | 'SELFEMPLOYED';
    export const WorkRequirementsEnum = {
        STUDENT: 'STUDENT' as WorkRequirementsEnum,
        EMPLOYED: 'EMPLOYED' as WorkRequirementsEnum,
        SELFEMPLOYED: 'SELFEMPLOYED' as WorkRequirementsEnum
    }
    export type StatusEnum = 'NEW' | 'UNAVAILABLE' | 'EXPIRED' | 'CLOSED';
    export const StatusEnum = {
        NEW: 'NEW' as StatusEnum,
        UNAVAILABLE: 'UNAVAILABLE' as StatusEnum,
        EXPIRED: 'EXPIRED' as StatusEnum,
        CLOSED: 'CLOSED' as StatusEnum
    }
    export type TypeEnum = 'APARTMENT' | 'HOUSE' | 'STUDIO';
    export const TypeEnum = {
        APARTMENT: 'APARTMENT' as TypeEnum,
        HOUSE: 'HOUSE' as TypeEnum,
        STUDIO: 'STUDIO' as TypeEnum
    }
}
