export const BENEFITS = [
    'MEDICAL_SERVICES',
    'OFFICE_PHONE',
    'FLEXIBLE_SCHEDULE',
    'WORK_REMOTE',
    'PARENT_LEAVE',
    'RAISE_KID_LEAVE',
    'MEDICAL_LEAVE',
    'FREE_COFFEE_SODA',
    'FREE_DISCOUNT_TRAINING',
    'FOOD_TICKETS',
    'VACATION_BONUS',
    'HOLIDAY_BONUS',
    '13_SALARY',
    'MEDICAL_INSURANCE_FAMILY',
    'DENTAL_SERVICES',
    'RETIREMENT',
    'LIFE_INSURANCE',
    'OFFICE_CAR',
    'GYM_MASSAGE',
    'FREE_TICKETS',
    'KINDERGARDEN_PACKAGE',
    'FREE_MEAL',
    'TEAMBUILDINGS'
]