import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { User } from '../modules/generated-api.module';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private sessionService: SessionService,
    private router: Router
  ) { }

  canActivate(): any {
    return new Promise((resolve, reject) => {
      this.sessionService.getCurrentUser().then((user: User) => {
        if (!user || !user.id) {
          reject();
          this.router.navigate(['register']);
        } else {
          resolve(true);
        }
      }, (err) => {
        this.router.navigate(['register']);
        reject(err);
      });
    });
  }
}
