import { Component, OnInit } from '@angular/core';
import { ListingsService, ListingApplication } from '../../../swagger-generated';

@Component({
  selector: 'app-admin-listing-applications',
  templateUrl: './admin-listing-applications.component.html',
  styleUrls: ['./admin-listing-applications.component.scss']
})
export class AdminListingApplicationsComponent implements OnInit {
  listingApplications: ListingApplication[];

  constructor(
    private _listingsService: ListingsService,
  ) { }

  ngOnInit(): void {
    this._listingsService.listingApplicationsGet(undefined, undefined, undefined, ['createdAt,DESC'], ['user', 'listing', 'listing.owner']).subscribe(data => {
      this.listingApplications = data;
    });
  }

}
