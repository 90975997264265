/**
 * Homi Server Endpoints
 * Available interactions with the Homi Backend services
 *
 * OpenAPI spec version: 0.0.17
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Company } from './company';
import { FloorPlan } from './floorPlan';
import { Listing } from './listing';
import { ModelDate } from './modelDate';
import { User } from './user';


export interface MediaContent {
    id: string;
    createdAt: ModelDate;
    updatedAt: ModelDate;
    type: MediaContent.TypeEnum;
    weight: number;
    title: string;
    url: string;
    thumbSizeUrl: string;
    mediumSizeUrl: string;
    largeSizeUrl: string;
    listing: Listing;
    floorPlan: FloorPlan;
    company: Company;
    userPhotos: User;
    listingPhotos: Listing;
    companyPhotos: Company;
}
export namespace MediaContent {
    export type TypeEnum = 'IMAGE' | 'VIDEO' | 'VIDEO_YOUTUBE' | 'VIDEO_VIMEO' | 'IMAGE_LOGO' | 'PDF' | 'OTHER';
    export const TypeEnum = {
        IMAGE: 'IMAGE' as TypeEnum,
        VIDEO: 'VIDEO' as TypeEnum,
        VIDEOYOUTUBE: 'VIDEO_YOUTUBE' as TypeEnum,
        VIDEOVIMEO: 'VIDEO_VIMEO' as TypeEnum,
        IMAGELOGO: 'IMAGE_LOGO' as TypeEnum,
        PDF: 'PDF' as TypeEnum,
        OTHER: 'OTHER' as TypeEnum
    }
}
