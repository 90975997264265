/**
 * Homi Server Endpoints
 * Available interactions with the Homi Backend services
 *
 * OpenAPI spec version: 0.0.17
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Listing } from './listing';
import { ModelDate } from './modelDate';
import { User } from './user';


export interface ListingApplication {
    id: string;
    createdAt: ModelDate;
    updatedAt: ModelDate;
    status: ListingApplication.StatusEnum;
    listing: Listing;
    user: User;
    reason: string;
}
export namespace ListingApplication {
    export type StatusEnum = 'USER_APPLIED' | 'USER_CONTACTED' | 'USER_PENDING_GROUP' | 'GROUP_ASSEMBLED' | 'APPOINTMENT_SCHEDULED' | 'APPOINTMENT_SUCCESS' | 'USER_NO_SHOW' | 'GROUP_FOLLOW_UP' | 'LEASE_PENDING' | 'CLOSED_WON' | 'CLOSED_LOST';
    export const StatusEnum = {
        USERAPPLIED: 'USER_APPLIED' as StatusEnum,
        USERCONTACTED: 'USER_CONTACTED' as StatusEnum,
        USERPENDINGGROUP: 'USER_PENDING_GROUP' as StatusEnum,
        GROUPASSEMBLED: 'GROUP_ASSEMBLED' as StatusEnum,
        APPOINTMENTSCHEDULED: 'APPOINTMENT_SCHEDULED' as StatusEnum,
        APPOINTMENTSUCCESS: 'APPOINTMENT_SUCCESS' as StatusEnum,
        USERNOSHOW: 'USER_NO_SHOW' as StatusEnum,
        GROUPFOLLOWUP: 'GROUP_FOLLOW_UP' as StatusEnum,
        LEASEPENDING: 'LEASE_PENDING' as StatusEnum,
        CLOSEDWON: 'CLOSED_WON' as StatusEnum,
        CLOSEDLOST: 'CLOSED_LOST' as StatusEnum
    }
}
