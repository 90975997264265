<mat-spinner *ngIf="isLoading" diameter="40"></mat-spinner>
<div [ngClass]="isLoading ? 'chat-container p-5 is-loading' : 'chat-container p-5'" class=" ">
  <!-- USER LIST -->
  <div class="user-list-container">
    <ul class="threads-container m-0 p-0">
      <li *ngFor="let thread of threads" class="thread-container">
        <a *ngIf="thread" [routerLink]="'/messages/' + thread.id" routerLinkActive="active">
          <div class="user-item-wrapper">
            <app-user-item [user]="getThreadUser(thread)" showFullName="true" noLink="true"></app-user-item>
          </div>
        </a>
      </li>
    </ul>
  </div>

  <!-- MESSAGES -->
  <div class="messages-container px-3">
    <router-outlet></router-outlet>
  </div>
</div>