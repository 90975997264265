<!-- NOTIFICATIONS -->
<div class="dropdown mx-2">
  <div class="user-dropdown" id="dropdownMenuButtonNotifications" data-toggle="dropdown" aria-haspopup="true"
    aria-expanded="false" (click)="onClickExpandNotifications()">
    <div class="notification-icon">
      <img class="logo-color" src="/assets/img/homi_theme/bell.png"
        srcset="/assets/img/homi_theme/bell@2x.png 2x, /assets/img/homi_theme/bell@3x.png 3x">
    </div>
    <span *ngIf="newNotificaitons" class="notifications-count badge badge-danger">{{newNotificaitons}}</span>
  </div>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButtonNotifications">
    <a *ngFor="let notification of notifications" (click)="onClickNotification(notification)" class="dropdown-item notification-item">{{getNotificationLabel(notification)}}</a>
  </div>
</div>