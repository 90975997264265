<div class="card">
  <div class="card-body">
    <div class="manage-listing-container">
      <div class="basic-info-input">
        <h4>{{listings?.length}} Applied Listings</h4>
      </div>
      <div class="listing-filter-result">
        <app-listing-item *ngFor="let listing of listings" [listing]="listing"></app-listing-item>
      </div>
    </div>
  </div>
</div>