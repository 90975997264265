import { Component, OnInit } from '@angular/core';
import { ObjectWidget } from 'ngx-schema-form';
import slugify from 'slugify';
import * as _ from 'lodash';
import { ApartmentscomService, Company } from '../../../../swagger-generated';


@Component({
  selector: 'app-form-control-company',
  templateUrl: './form-control-company.component.html',
  styleUrls: ['./form-control-company.component.scss']
})
export class FormControlCompanyComponent extends ObjectWidget implements OnInit {
  constructor(
    private _apartmentscomService: ApartmentscomService
  ) {
    super();
  }
  ngOnInit() {
    this.formProperty.getProperty('name').valueChanges.subscribe((data)=>this.updateSlug(data, undefined));
    this.formProperty.getProperty('address').valueChanges.subscribe((data)=>this.updateSlug(undefined, data));
  }

  updateSlug(name, address) {
    let slug;
    name = name || this.formProperty.getProperty('name').value || '';
    address = address || this.formProperty.getProperty('address').value || {};
    if (name) {
      slug = `${name} Apartments`;
      if (address && address.addressLocality) {
        slug += ` ${address.addressLocality}`;
      }
    } else {
      slug = '';
    }
    slug = slugify(slug, {
      strict: true
    });
    this.formProperty.getProperty('slug').setValue(slug);
  }

  get showSync() {
    const formValue = this.formProperty.value;
    if (formValue.externalSource === Company.ExternalSourceEnum.APARTMENTSCOM && formValue.externalId) {
      return true;
    }
    return false;
  }

  onClickSync() {
    const formValue = this.formProperty.value;
    if (formValue.externalSource === Company.ExternalSourceEnum.APARTMENTSCOM && formValue.externalId) {
      this._apartmentscomService.apartmentscomCompaniesIdGet(formValue.externalId).subscribe(data => {
        this.formProperty.setValue(_.omit(data, 'listings'), false);
      })
    }
  }
}