<table class="table">
    <thead>
      <tr>
        <th>Created at</th>
        <th>Full name</th>
        <th>Email</th>
        <th>Phone</th>
        <th>Listing</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let listingApplication of listingApplications">
        <td>{{listingApplication.user?.createdAt | date:'longDate'}}</td>
        <td>{{listingApplication.user?.givenName}} {{listingApplication.user?.familyName}}</td>
        <td>{{listingApplication.user?.email}}</td>
        <td>{{listingApplication.user?.phone}}</td>
        <td>
          <a [routerLink]="'/listings/' + listingApplication.listing?.id">{{listingApplication.listing?.owner?.name}}</a>
        </td>
        <td>{{'listing.listingStatuses.' + listingApplication.status | translate}}</td>
      </tr>
    </tbody>
  </table>