import { Injectable } from '@angular/core';
import { User, UsersService } from '../modules/generated-api.module';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { UpdateRequiredProfileDetailsComponent } from '../components/update-required-profile-details/update-required-profile-details.component';
import { Router } from '@angular/router';

const getCurrentPosition = (options = {}) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
};

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  currentUser: User;
  private currentUserUpdatedSource = new Subject<User>();
  currentUserUpdated$ = this.currentUserUpdatedSource.asObservable();
  currentLocation: any;
  updateProfileDialogIsOpened = false;
  getCurrentUserPromise: Promise<User>;

  constructor(
    private usersService: UsersService,
    public dialog: MatDialog,
    private _router: Router
  ) {
    /**
     * If permission has been previously granted
     * request user location
     */
    // @ts-ignore
    if (navigator.permissions) {
      // @ts-ignore
      navigator.permissions.query({ name: 'geolocation' })
        .then((permissionStatus) => {
          if (permissionStatus.state === 'granted') {
            this.getCurrentLocation();
          }
        });
    } else {
      this.getCurrentLocation();
    }
  }

  getCurrentUser(forceSync = false): Promise<User> {
    if (this.getCurrentUserPromise && !this.currentUser) {
      return this.getCurrentUserPromise;
    } else {
      this.getCurrentUserPromise = new Promise((resolve, reject) => {
        if (this.currentUser && !forceSync) {
          resolve(this.currentUser);
          this.getCurrentUserPromise = null;
        } else {
          this.usersService.usersMeGet().subscribe(response => {
            this.getCurrentUserPromise = null;
            this.currentUser = response;
            this.currentUserUpdated(this.currentUser);
            resolve(response);
          }, err => {
            this.currentUserUpdated(null);
            this.getCurrentUserPromise = null;
            resolve(null);
          })
        }
      });
      return this.getCurrentUserPromise;
    }
  }

  currentUserUpdated(currentUser: any): any {
    this.currentUserUpdatedSource.next(currentUser);
    this.currentUser = currentUser;
    this.currentLocation = undefined;
    // if (!this.updateProfileDialogIsOpened) {
    //   if (currentUser && (((currentUser.roles.indexOf(User.RolesEnum.USER) === -1 && currentUser.roles.indexOf(User.RolesEnum.REALESTATE) === -1) || !currentUser.email || !currentUser.phone) || !currentUser.familyName || !currentUser.givenName)) {
    //     this.updateProfileDialogIsOpened = true;
    //     const dialogRef = this.dialog.open(UpdateRequiredProfileDetailsComponent, { data: { user: this.currentUser }, disableClose: true });

    //     dialogRef.afterClosed().subscribe(result => {
    //       this.updateProfileDialogIsOpened = false;
    //       this.currentUserUpdated(result);
    //       this._router.navigate(['/dashboard']);
    //     });
    //   }
    // }

  }

  hasRole(role: User.RolesEnum) {
    return this.currentUser && this.currentUser.roles && this.currentUser.roles.includes(role);
  }

  async getCurrentLocation(): Promise<any> {
    if (!this.currentLocation) {
      try {
        this.currentLocation = await getCurrentPosition();
      } catch (e) {
        const user = await this.getCurrentUser();
        if (user && user.address && user.address.geolocation) {
          this.currentLocation = {
            coords: {
              latitude: user.address.geolocation.lat,
              longitude: user.address.geolocation.lng
            }
          }
        }
      }
    }

    return this.currentLocation;
  }
}
