/**
 * Homi Server Endpoints
 * Available interactions with the Homi Backend services
 *
 * OpenAPI spec version: 0.0.17
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { ResetPasswordDto } from '../model/resetPasswordDto';
import { SignInDto } from '../model/signInDto';
import { SignUpDto } from '../model/signUpDto';
import { StateParamsDto } from '../model/stateParamsDto';
import { UpdatePasswordDto } from '../model/updatePasswordDto';
import { ValidateAccountDto } from '../model/validateAccountDto';
import { VerifyPhoneDto } from '../model/verifyPhoneDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AuthService {

    protected basePath = 'http://localhost/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param stateParamsDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authFacebookUriPost(stateParamsDto: StateParamsDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public authFacebookUriPost(stateParamsDto: StateParamsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public authFacebookUriPost(stateParamsDto: StateParamsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public authFacebookUriPost(stateParamsDto: StateParamsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (stateParamsDto === null || stateParamsDto === undefined) {
            throw new Error('Required parameter stateParamsDto was null or undefined when calling authFacebookUriPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/auth/facebook/uri`,
            stateParamsDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param stateParamsDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authGoogleUriPost(stateParamsDto: StateParamsDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public authGoogleUriPost(stateParamsDto: StateParamsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public authGoogleUriPost(stateParamsDto: StateParamsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public authGoogleUriPost(stateParamsDto: StateParamsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (stateParamsDto === null || stateParamsDto === undefined) {
            throw new Error('Required parameter stateParamsDto was null or undefined when calling authGoogleUriPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/auth/google/uri`,
            stateParamsDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param authorization 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authLogoutPost(authorization: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public authLogoutPost(authorization: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public authLogoutPost(authorization: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public authLogoutPost(authorization: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (authorization === null || authorization === undefined) {
            throw new Error('Required parameter authorization was null or undefined when calling authLogoutPost.');
        }

        let headers = this.defaultHeaders;
        if (authorization !== undefined && authorization !== null) {
            headers = headers.set('authorization', String(authorization));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/auth/logout`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param state 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authOauthSigninPost(state: string, code: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public authOauthSigninPost(state: string, code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public authOauthSigninPost(state: string, code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public authOauthSigninPost(state: string, code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (state === null || state === undefined) {
            throw new Error('Required parameter state was null or undefined when calling authOauthSigninPost.');
        }
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling authOauthSigninPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (state !== undefined) {
            queryParameters = queryParameters.set('state', <any>state);
        }
        if (code !== undefined) {
            queryParameters = queryParameters.set('code', <any>code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<any>(`${this.basePath}/auth/oauth/signin`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param resetPasswordDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authResetPasswordPost(resetPasswordDto: ResetPasswordDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public authResetPasswordPost(resetPasswordDto: ResetPasswordDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public authResetPasswordPost(resetPasswordDto: ResetPasswordDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public authResetPasswordPost(resetPasswordDto: ResetPasswordDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (resetPasswordDto === null || resetPasswordDto === undefined) {
            throw new Error('Required parameter resetPasswordDto was null or undefined when calling authResetPasswordPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/auth/reset-password`,
            resetPasswordDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param signInDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authSigninPost(signInDto: SignInDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public authSigninPost(signInDto: SignInDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public authSigninPost(signInDto: SignInDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public authSigninPost(signInDto: SignInDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (signInDto === null || signInDto === undefined) {
            throw new Error('Required parameter signInDto was null or undefined when calling authSigninPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/auth/signin`,
            signInDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param signUpDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authSignupPost(signUpDto: SignUpDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public authSignupPost(signUpDto: SignUpDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public authSignupPost(signUpDto: SignUpDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public authSignupPost(signUpDto: SignUpDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (signUpDto === null || signUpDto === undefined) {
            throw new Error('Required parameter signUpDto was null or undefined when calling authSignupPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/auth/signup`,
            signUpDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param updatePasswordDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authUpdatePasswordPost(updatePasswordDto: UpdatePasswordDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public authUpdatePasswordPost(updatePasswordDto: UpdatePasswordDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public authUpdatePasswordPost(updatePasswordDto: UpdatePasswordDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public authUpdatePasswordPost(updatePasswordDto: UpdatePasswordDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (updatePasswordDto === null || updatePasswordDto === undefined) {
            throw new Error('Required parameter updatePasswordDto was null or undefined when calling authUpdatePasswordPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/auth/update-password`,
            updatePasswordDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param verifyPhoneDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authVerifyPhonePost(verifyPhoneDto: VerifyPhoneDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public authVerifyPhonePost(verifyPhoneDto: VerifyPhoneDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public authVerifyPhonePost(verifyPhoneDto: VerifyPhoneDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public authVerifyPhonePost(verifyPhoneDto: VerifyPhoneDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (verifyPhoneDto === null || verifyPhoneDto === undefined) {
            throw new Error('Required parameter verifyPhoneDto was null or undefined when calling authVerifyPhonePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/auth/verify/phone`,
            verifyPhoneDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param validateAccountDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authVerifyPhoneValidatePost(validateAccountDto: ValidateAccountDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public authVerifyPhoneValidatePost(validateAccountDto: ValidateAccountDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public authVerifyPhoneValidatePost(validateAccountDto: ValidateAccountDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public authVerifyPhoneValidatePost(validateAccountDto: ValidateAccountDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (validateAccountDto === null || validateAccountDto === undefined) {
            throw new Error('Required parameter validateAccountDto was null or undefined when calling authVerifyPhoneValidatePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/auth/verify/phone/validate`,
            validateAccountDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param stateParamsDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authYoutubeUriPost(stateParamsDto: StateParamsDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public authYoutubeUriPost(stateParamsDto: StateParamsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public authYoutubeUriPost(stateParamsDto: StateParamsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public authYoutubeUriPost(stateParamsDto: StateParamsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (stateParamsDto === null || stateParamsDto === undefined) {
            throw new Error('Required parameter stateParamsDto was null or undefined when calling authYoutubeUriPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/auth/youtube/uri`,
            stateParamsDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
