<div class="user-item-wrapper">
  <div class="media">
    <a class="mr-3 img-fluid thumb" [routerLink]="noLink ? null : candidateLink"
      [style.background-image]="'url(' + getMediaContentUrl(user.profilePicture, 'user', 'thumb') + ')'"></a>
    <div class="media-body">
      <h5 class="user-title"><a [routerLink]="noLink ? null : candidateLink">{{nameLabel}}</a></h5>
      <span class="user-job-title">{{user.title}}</span>
    </div>
  </div>
  <div class="salary-location pt-2 row m-0">
    <div class="salary-wrapper col-lg-6 p-0">
      <div class="salary" *ngIf="user.budget">
        <span class="amount">{{user?.budget | currency:'USD' }}</span>
      </div>
    </div>
    <div class="location-wrapper col-lg-6 p-0">
      <div class="location" *ngIf="user.address">
        <span>{{user.address?.addressLocality}}</span> / <span>{{user.address?.addressCountry}}</span>
      </div>
      <div *ngIf="relativeAddress" class="distance badge badge-secondary">
        {{relativeDistance}}
      </div>
    </div>
  </div>
  <hr *ngIf="!noLink">
  <a *ngIf="!noLink" class="btn btn-primary btn-view-profile" [routerLink]="candidateLink">View Profile</a>
</div>