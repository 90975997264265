import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { SwaggerDocOptimizerService } from 'ngx-swag-form';
import { FormComponent } from 'ngx-schema-form';
import { Listing, ListingsService } from '../../swagger-generated';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog-apply',
  templateUrl: './dialog-apply.component.html',
  styleUrls: ['./dialog-apply.component.scss']
})
export class DialogApplyComponent {
  @ViewChild('form') form;
  @Input() listing: Listing;
  applicationSchema;
  application;
  success = false;
  error = false;

  constructor(
    private _swaggerDocOptimizerService: SwaggerDocOptimizerService,
    private _listingsService: ListingsService,
    public activeModal: NgbActiveModal
  ) {
    this.applicationSchema = this._swaggerDocOptimizerService.getOptimizedSchema('Application');
  }

  onClickApply() {
    const payload = Object.assign({}, this.form.model, {
      listingId: this.listing.id
    });
    this._listingsService.listingApplicationsGuestPost(payload).subscribe(response => {
      this.success = true;
    }, error => {
      this.error = error;
    })
  }

  onClickClose() {
    this.activeModal.close('Close click');
  }

}
