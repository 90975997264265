import { Component } from '@angular/core';
import * as _ from 'lodash';
import { FormControlMediaContentComponent } from 'ngx-swag-form';
import { MediaContentService } from '../../../../swagger-generated';

@Component({
  selector: 'app-form-control-media-content-homi',
  templateUrl: './form-control-media-content-homi.component.html',
  styleUrls: ['./form-control-media-content-homi.component.scss']
})
export class FormControlMediaContentHomiComponent extends FormControlMediaContentComponent {
  constructor(
    private _mediaContentService: MediaContentService
  ){
    super();
  }
  save() {
    debugger;
  }

  onChangeWeight(ev) {
    const sortedItems = _.sortBy(this.formProperty.parent.value, 'weight');
    this.formProperty.parent.setValue(sortedItems, false);
  }

  delete() {
    this._mediaContentService.mediaContentIdDelete(this.formProperty.value.id).subscribe(data => {
      this.formProperty.reset({}, false);
    })
  }
}
