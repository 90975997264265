/**
 * Homi Server Endpoints
 * Available interactions with the Homi Backend services
 *
 * OpenAPI spec version: 0.0.17
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ModelDate } from './modelDate';


export interface Notification {
    id: string;
    createdAt: ModelDate;
    updatedAt: ModelDate;
    type: Notification.TypeEnum;
    status: Notification.StatusEnum;
    data: string;
}
export namespace Notification {
    export type TypeEnum = 'MESSAGE' | 'CONNECTION_REQUEST' | 'CONNECTION_REQUEST_ACCEPTED';
    export const TypeEnum = {
        MESSAGE: 'MESSAGE' as TypeEnum,
        CONNECTIONREQUEST: 'CONNECTION_REQUEST' as TypeEnum,
        CONNECTIONREQUESTACCEPTED: 'CONNECTION_REQUEST_ACCEPTED' as TypeEnum
    }
    export type StatusEnum = 'NEW' | 'SEEN';
    export const StatusEnum = {
        NEW: 'NEW' as StatusEnum,
        SEEN: 'SEEN' as StatusEnum
    }
}
