import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, User } from 'src/app/swagger-generated';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent {
  authorizeForm: FormGroup;
  verifyForm: FormGroup;
  isRegisterMode: boolean;
  routeSubscription;
  isAuthenticated: true;
  selectedRole = User.RolesEnum.USER;
  registerForm: FormGroup;
  loginForm: FormGroup;
  errorMessage;
  loginType = 'email';
  @ViewChild('phoneInput') phoneInput: ElementRef;
  intTelInputObject;
  phoneInputOptions = {
    preferredCountries: ['us', 'ro']
  }
  validationCodeSent = false;

  isLoading = false;
  queryParams: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    public formBuilder: FormBuilder
  ) {
    this.activatedRoute
      .data
      .subscribe(data => this.isRegisterMode = (data.mode === 'register'));

    this.activatedRoute.queryParams.subscribe(q => {
      this.queryParams = q;
      this.isLoading = false;
      if (q.code) {
        this.isLoading = true;
        this.oauthLogin(q);
      }
    });

    this.loginForm = this.formBuilder.group({
      email: '',
      password: ''
    })

    this.registerForm = this.formBuilder.group({
      email: '',
      givenName: '',
      familyName: '',
      password: '',
      acceptTerms: false
    })
    this.authorizeForm = this.formBuilder.group({
      phone: ''
    })
    this.verifyForm = this.formBuilder.group({
      token: '',
      code: ''
    })
  }

  async onFacebookClick() {
    this.isLoading = true;
    const { redirectUri } = await this.authService.authFacebookUriPost({ params: this.queryParams }).toPromise();
    window.location.href = redirectUri;
  }

  async onGoogleClick() {
    this.isLoading = true;
    const { redirectUri } = await this.authService.authGoogleUriPost({ params: this.queryParams }).toPromise();
    window.location.href = redirectUri;
  }

  oauthLogin(queryParams: any): void {
    this.isLoading = true;
    this.authService.authOauthSigninPost(queryParams.state, queryParams.code)
      .subscribe(
        res => this.handleLogin(res),
        err => {
          this.errorMessage = 'Login error';
          this.isLoading = false;
        }
      );
  }

  onSubmitRegisterForm() {
    this.isLoading = true;
    this.errorMessage = null;
    const payload = this.registerForm.value;
    payload.roles = [User.RolesEnum.USER, this.selectedRole];
    this.authService.authSignupPost(payload)
      .subscribe(
        res => this.handleLogin(res),
        ({ error }) => {
          this.errorMessage = error.message,
            this.isLoading = false
        }
      )
  }

  onSubmitLoginForm() {
    this.isLoading = true;
    this.errorMessage = null;
    this.authService.authSigninPost(this.loginForm.value)
      .subscribe(
        res => this.handleLogin(res),
        err => {
          this.errorMessage = 'Login error',
            this.isLoading = false
        }
      )
  }

  onClickSwitchLogin(type: string) {
    this.loginType = type;
  }

  onSubmitAuthorizeForm() {
    this.isLoading = true;
    this.errorMessage = null;
    const phone = this.intTelInputObject.getNumber();

    this.authService.authVerifyPhonePost({
      phone: phone
    })
      .subscribe(
        res => { 
          this.validationCodeSent = true; 
          this.isLoading = false;
          this.verifyForm.get('token').setValue(res.token);
        }, 
        err => {
          this.errorMessage = 'Sending authorization code failed',
          this.isLoading = false
        }
      )
  }

  onSubmitVerifyForm() {
    this.isLoading = true;
    this.errorMessage = null;
    this.authService.authVerifyPhoneValidatePost(this.verifyForm.value)
      .subscribe(
        res => { this.handleLogin(res) }, 
        err => {
          this.errorMessage = err.error.message,
          this.isLoading = false
        }
      )
  }

  handleLogin({token}) {
    localStorage.setItem('token', token);
    this.router.navigate(['/dashboard']);
  }

  telInputObject(obj) {
    this.intTelInputObject = obj;
  }

}
