import { Component, OnInit } from '@angular/core';
import { omit } from 'lodash';
import { Listing, UsersService, ListingApplication, ListingsService } from 'src/app/swagger-generated';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-applied-listings',
  templateUrl: './applied-listings.component.html',
  styleUrls: ['./applied-listings.component.scss']
})
export class DashboardAppliedListingsComponent implements OnInit {

  listings: Listing[];

  constructor(
    private sessionService: SessionService,
    private listingsService: ListingsService
  ) { }

  ngOnInit() {
    this.sessionService.getCurrentUser().then(user => {
      const joinQueryString = [
        'listing',
        'listing.companyLabel',
        'listing.companyLabel.companyLogo',
        'listing.owner',
        'listing.usersFavorited',
        'listing.address',
        'listing.price'
      ]
      this.listingsService.listingApplicationsGet(undefined, [`user||eq||${user.id}`], undefined, undefined, joinQueryString).subscribe(response => {
        if (response) {
          this.listings = response.map(listingApplication => {
            return {
              ...listingApplication.listing,
              listingApplications: [{ ...omit(listingApplication, 'listing'), user }],
            }
          })
        }
      });
    })
  }
}
