<div class="media listing-item-wrapper" *ngIf="listing">
  <a class="favorite" (click)="onClickFavorite()">
    <div *ngIf="!favorited">
      <mat-spinner diameter="20" *ngIf="isLoadingFavorite"></mat-spinner>
      <i-feather *ngIf="!isLoadingFavorite" name="heart"></i-feather>
    </div>
    <div *ngIf="favorited" class="favorited">
      <mat-spinner diameter="20" *ngIf="isLoadingFavorite"></mat-spinner>
      <i-feather *ngIf="!isLoadingFavorite" name="heart"></i-feather>
    </div>
  </a>

  <div class="media-body">
    <h5 class="m-0"><a class="listing-title" (click)="onClickListingTitle()">
      <span *ngIf="listing.autoSync" class="badge badge-success autosync-badge" title="Auto Sync"><i-feather name="refresh-cw" style="color: white;"></i-feather></span> {{title}} <span
          *ngIf="listingApplication && !isLoadingApply"
          class="badge badge-success applied ">{{'APPLIED' | translate}}</span></a></h5>
    <div class="employer-salary-warpper my-1">
      <span class="employer">
        <a [routerLink]="'/companies/' + listing.owner.id"><span
            class="employer-name">{{listing.owner.name}}</span></a><span> - </span>
      </span>
      <span class="salary">
        <span class="amount">{{listing.price | currency:'USD'}}</span>/month
      </span>
    </div>
    <div *ngIf="!hideDescription" class="block-with-text">
      {{description}}
    </div>
    <div class="suffix mt-2" *ngIf="!lightDisplay">
      <div class="location-type">
        <span class="location">
          <i-feather name="map-pin" class="mr-1"></i-feather>{{listing.address?.addressLocality}} <i
            *ngIf="listing.address?.geolocation && distanceToListing">({{distanceToListing}})</i>
        </span>
      </div>
      <div class="special-commitments-deadline">
        <div class="deadline">
          Deadline: {{listing?.applicationDeadline | date:'dd-MMM-yyyy'}}
        </div>
      </div>
    </div>
  </div>
</div>