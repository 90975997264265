<p>
  We are almost there! Few more details are necessary to start exploring!
</p>
<div class="select-role-wrapper" *ngIf="!hasRole">
  <h4 class="section-title">
    I am using this platform as:
  </h4>
  <hr>

  <div class="buttons-container">
    <button class="btn btn-primary" (click)="onClickSelectRole(RolesEnum.CANDIDATE)">Candidate</button><button
      class="btn btn-primary" (click)="onClickSelectRole(RolesEnum.RECRUITER)">Realtor</button>
  </div>
</div>

<div class="select-role-wrapper" *ngIf="!data.user.givenName || !data.user.familyName">
  <form [formGroup]="userForm" class="row m-0">
    <div class="col-lg-6">
      <div class="form-group">
        <label class="form-group-title" for="firstNameInput">First Name <span class="required">*</span></label>
        <input type="text" class="form-control" id="firstNameInput" placeholder="e.g. John" formControlName="givenName"
          required>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="form-group">
        <label class="form-group-title" for="lastNameInput">Last Name <span class="required">*</span></label>
        <input type="text" class="form-control" id="lastNameInput" placeholder="e.g. Doe" formControlName="familyName"
          required>
      </div>
    </div>
  </form>
</div>

<div class="add-email-wrapper" *ngIf="!data.user.email">
  <form [formGroup]="userForm" class="row m-0">
    <div class="col-lg-12">
      <div class="form-group">
        <label class="form-group-title" for="emailInput">Email <span class="required">*</span></label>
        <input type="email" class="form-control" id="emailInput" placeholder="e.g. johndoe@mail.com"
          formControlName="email" required>
      </div>
    </div>
  </form>
</div>

<div class="alert alert-success" *ngIf="phoneVerified">
  Your phone has been successfully verified!
</div>
<div class="user-phone mt-3" *ngIf="!data.user.phone && !phoneVerified">
  <h4 class="section-title">
    Your mobile phone number
  </h4>
  <hr>
  <div class="row">
    <div class="account-type col-lg-12">
      <div class="alert alert-danger" *ngIf="errorMessage">
        {{errorMessage}}
      </div>
    </div>
    <div class="account-info col-lg-12" [ngClass]="(validationCodeSent) ? 'hidden' : ''">
      <input ng2TelInput (intlTelInputObject)="telInputObject($event)" type="tel" class="form-group"
        aria-describedby="phoneHelp" #phoneInput [ng2TelInputOptions]="phoneInputOptions">
      <form [formGroup]="authorizeForm" (ngSubmit)="onSubmitAuthorizeForm()" class="row m-0">
        <div class="col-lg-6">
          <small id="phoneHelp" class="form-text text-muted">Use a valid phone number - we will send you a code via
            SMS.</small>
        </div>
        <div class="col-lg-6">
          <button class="btn btn-primary" [disabled]="!intTelInputObject.isValidNumber()">Send SMS</button>
        </div>
      </form>
    </div>
    <div class="validate-code" *ngIf="validationCodeSent && !errorMessage">
      <form [formGroup]="verifyForm" (ngSubmit)="onSubmitVerifyForm()" class="row m-0">
        <input type="hidden" formControlName="token" required name="token">
        <div class="col-lg-6">
          <div class="mb-2">
            Please enter the 3 digits code you received via SMS:
          </div>
          <div class="form-group">
            <input type="number" placeholder="{{'Code' | translate}}" class="form-control" formControlName="code"
              required name="code">
          </div>
        </div>
        <div class="col-lg-6">
          <button class="btn btn-primary" [disabled]="!verifyForm.valid">Verify</button>
        </div>
      </form>
    </div>
    <div class="text-center" *ngIf="errorMessage">
      <div class="btn btn-link" *ngIf="errorMessage" (click)="onClickTryAgain()">Try again...</div>
    </div>
  </div>
</div>
<div>
  <button class="btn btn-primary" [disabled]="!canClose || !userForm.valid" (click)="save()">Save</button>
</div>