import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { GetManyDefaultResponse } from 'src/app/shared/models/paged-responses';
import { UsersService, User, Address, Company, CompaniesService } from 'src/app/swagger-generated';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { SHARED } from '../../shared';
import { UsersSearchPayload } from '../../interfaces/users-search-payload';
import { SessionService } from '../../services/session.service';

declare const google;

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss']
})
export class CandidatesComponent implements OnInit {
  pagedResponse: GetManyDefaultResponse<User>;
  selectedFilterOptions: any[] = [];
  isLoading = true;
  searchQuery = '';
  perPage = 9999;
  sortOrder: any = 'MOST_RECENT';
  autocompleteListener;
  @ViewChild('inputAddress') inputAddress: ElementRef;
  @ViewChild('rangeInput') rangeInput: ElementRef;
  errorMessage = null;
  filterOptions: any;
  urlFilter;
  SHARED = SHARED;
  minimumSalaryFilter = 200;
  sortNearAddress: Address;
  viewMode = 'grid';
  currentUserCompanies: Company[];
  relativeAddress: Address;
  currentUser: User;

  constructor(
    private usersService: UsersService,
    public translate: TranslateService,
    private _changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private _sessionService: SessionService,
    private _usersService: UsersService
  ) {
    this.activatedRoute.queryParams.subscribe(q => {
      if (q.filter) {
        if (typeof q.filter === 'string') {
          const filterData = this.handleQueryFilter(q.filter);
          this.addFilter(filterData[0], filterData[2], filterData[1], filterData[3]);
        } else {
          q.filter.forEach(filter => {
            const filterData = this.handleQueryFilter(filter);
            this.addFilter(filterData[0], filterData[2], filterData[1], filterData[3]);
          });
        }
      }
    });
  }

  handleQueryFilter(filter) {
    const filterData = filter.split('||');
    if (filterData[0] === 'distance') {
      const address: Address = JSON.parse(filterData[2]);
      this.sortNearAddress = address;
      this.sortOrder = 'NEAR_ADDRESS';
      return [filterData[0], filterData[1], `${address.geolocation.lat},${address.geolocation.lng}`, address.formattedAddress];
    } else if (filterData[0] === 'title') {
      return [filterData[0], filterData[1], filterData[2], `"${filterData[2]}"`];
    }
    return filterData;
  }

  ngOnInit() {
    this._sessionService.getCurrentUser().then(user => {
      this.currentUser = user;
      this._usersService.usersIdGet(user.id, ['administratorOf'], ['administratorOf', 'administratorOf.address']).subscribe(user => {
        this.currentUserCompanies = user.administratorOf;
      })
    });
    this.usersService.usersFiltersGet().subscribe(filterOptions => {
      this.filterOptions = filterOptions;
    })
    this._updateResults();
  }

  onChangeMinimumSalary(ev) {
    const minimumSalary = ev.currentTarget.value;
    this.removeFilter('baseSalary.min', null, false);
    this.addFilter('baseSalary.min', minimumSalary, 'gt', this.translate.instant('filterValues.budget.min', { value: minimumSalary + '€ / mo' }), true);
  }

  addFilter(filterName: string, filterValue: any, filterOperator = 'eq', filterLabel?: string, sync = true) {
    if (filterName) {
      this.selectedFilterOptions.push({
        filterName,
        filterValue,
        filterType: filterOperator,
        label: filterLabel || this.getFilterLabel(filterName, filterValue)
      });
      if (sync) {
        this._updateResults();
      }
    }
  }

  getDriversLiceseLabel(driversLicenseName) {
    return this.translate.instant('driversLicenseCategory') + ' ' + this.translate.instant('skills.' + driversLicenseName);
  }

  toggleFilter(event, filterName: string, filterValue: any, filterOperator = 'eq', filterLabel?: string, sync = true) {
    if (event.target.checked) {
      this.addFilter(filterName, filterValue, filterOperator, filterLabel, sync);
    } else {
      this.removeFilter(filterName, filterValue);
    }
  }

  getFilterLabel(filterName: string, filterValue: string) {
    return this.translate.instant(`filterValues.${filterName}.${filterValue}`);
  }

  async _updateResults() {
    this.errorMessage = null;
    this.isLoading = true;
    window.scrollTo(0, 0);

    const sortString = await this.getSort();

    const joinQueryString = [
      'profilePicture',
      'address',
      'baseSalary',
      'spokenLanguages',
      'driversLicenseCategories',
      'connections',
      'connections.users'
    ];

    // Tweak - isLoaded change is not detected
    this._changeDetectorRef.detectChanges();

    this.usersService.usersGet(undefined, this.getFilter(), this.getOrFilter(), sortString, joinQueryString, 9999, 0, 0)
      .subscribe(response => {
        this.isLoading = false;
        this.pagedResponse = <GetManyDefaultResponse<User>><unknown>response;
        // Tweak - isLoaded change is not detected
        this._changeDetectorRef.detectChanges();
      },
        err => {
          this.isLoading = false;
          this.errorMessage = err.message;
          // Tweak - isLoaded change is not detected
          this._changeDetectorRef.detectChanges();
        })
  }

  getSort(): Promise<string[]> {
    return new Promise(async (resolve, reject) => {
      this.relativeAddress = undefined;
      switch (this.sortOrder) {
        case 'MOST_RECENT': {
          resolve(['createdAt,DESC']);
          break;
        };
        case 'LOWEST_SALARY': {
          resolve(['baseSalary.min,ASC']);
          break;
        }
        default: {
          // Company selected
          if (typeof this.sortOrder === 'object') {
            const address = this.sortOrder.address;
            if (address.geolocation.lat) {
              this.relativeAddress = address;
              resolve([`distance[${address.geolocation.lat}|${address.geolocation.lng}],ASC`]);
            } else {
              reject(`No address selected`);
              console.error('No address selected');
            }
            break;
          }
          reject('NO_SORT_OPTION');
          console.error('Undefined sort option');
          break;
        }
      }
    });
  }

  getFilter() {
    const filterValues = this.selectedFilterOptions.map((filterOptions, index) => {
      return `${filterOptions.filterName}||${filterOptions.filterType}||${filterOptions.filterValue}`;
    });

    return [...filterValues, 'isPublic||eq||true'];
  }

  getOrFilter() {
    if (this.searchQuery) {
      return [`familyName||cont||${this.searchQuery}`, `givenName||cont||${this.searchQuery}`, `description||cont||${this.searchQuery}`];
    }
    return [];
  }

  removeFilter(filterName?: string, filterValue?: any, sync = true) {
    if (!filterName && !filterValue) {
      document.querySelectorAll('.sidebar input[type="checkbox"]').forEach((el: HTMLInputElement) => el.checked = false)
      this.selectedFilterOptions = [];
    } else if (!filterValue) {
      this.selectedFilterOptions = this.selectedFilterOptions.filter(filter => {
        return filter.filterName !== filterName;
      });
    } else {
      this.selectedFilterOptions = this.selectedFilterOptions.filter(filter => {
        return filter.filterName !== filterName || filter.filterValue !== filterValue;
      });
    }

    if (sync) {
      this._updateResults();
    }
  }

  onKeyDownSearch(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      this._updateResults();
    }
  }

  isSelectedFilter(filterName?: string, filterValue?: any) {
    return !!this.selectedFilterOptions.find(filter => {
      return filter.filterName === filterName && filter.filterValue === filterValue;
    });
  }

  onChangeSort($event) {
    this._updateResults();
  }

  initializeAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.inputAddress.nativeElement);
    this.autocompleteListener = google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.removeFilter('distance');
      const place = autocomplete.getPlace();
      if (place.name) {
        const location = place.geometry.location;
        this.addFilter('distance', `${location.lat()},${location.lng()}`, 'eq', place.formatted_address);
      }
    })
  }

  onSearch(payload: UsersSearchPayload) {
    this.removeFilter('title');
    this.removeFilter('distance');
    if (payload.query) {
      this.addFilter('title', payload.query, 'cont', `"${payload.query}"`);
    }

    if (payload.address.geolocation) {
      this.sortNearAddress = payload.address;
      this.sortOrder = 'NEAR_ADDRESS';
      this.addFilterNearAddress(payload.address);
    } else {
      this.sortOrder = 'MOST_RECENT';
      this.sortNearAddress = undefined;
    }
    this._updateResults();
  }

  addFilterNearAddress(address: Address) {
    this.removeFilter('distance');
    this.addFilter('distance', `${address.geolocation.lat},${address.geolocation.lng}`, 'eq', address.formattedAddress);
  }

  get sortNearAddressLabel() {
    if (this.sortNearAddress.formattedAddress.length > 20) {
      return this.sortNearAddress.formattedAddress.substr(0, 20) + '...';
    }

    return this.sortNearAddress.formattedAddress;
  }

}
