import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { UsersService, User, AuthService } from 'src/app/swagger-generated';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-required-profile-details',
  templateUrl: './update-required-profile-details.component.html',
  styleUrls: ['./update-required-profile-details.component.scss']
})
export class UpdateRequiredProfileDetailsComponent implements OnInit {
  RolesEnum = User.RolesEnum;
  isLoading = false;
  errorMessage;
  user: User;
  phoneInputOptions = {
    preferredCountries: ['us', 'ro']
  }
  validationCodeSent = false;
  @ViewChild('phoneInput') phoneInput: ElementRef;
  intTelInputObject;
  authorizeForm: FormGroup;
  verifyForm: FormGroup;
  userForm: FormGroup;
  phoneVerified = false;

  constructor(
    public dialogRef: MatDialogRef<UpdateRequiredProfileDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {user: User},
    private _usersService: UsersService,
    private _formBuilder: FormBuilder,
    private _authService: AuthService
  ) {
    this.authorizeForm = this._formBuilder.group({
      phone: ''
    })
    this.verifyForm = this._formBuilder.group({
      token: '',
      code: ''
    });
    this.userForm = this._formBuilder.group({
      givenName: data.user.givenName,
      familyName:  data.user.familyName,
      email: data.user.email
    });
  }

  ngOnInit() {
  }

  onClickSelectRole(role: User.RolesEnum) {
    const newRoles = [...this.data.user.roles, role];
    this._usersService.usersIdPatch(<User><unknown>{roles: newRoles}, this.data.user.id).subscribe(data => {
      this.data.user.roles = data.roles;
    });
  }

  get hasRole() {
    return this.data.user.roles.indexOf(User.RolesEnum.USER) !== -1 || this.data.user.roles.indexOf(User.RolesEnum.REALESTATE) !== -1;
  }

  onSubmitAuthorizeForm() {
    this.isLoading = true;
    this.errorMessage = null;
    const phone = this.intTelInputObject.getNumber();

    this._authService.authVerifyPhonePost({
      phone: phone
    })
      .subscribe(
        res => { 
          this.validationCodeSent = true; this.isLoading = false;
          this.verifyForm.get('token').setValue(res.token);
        }, 
        err => {
          this.errorMessage = 'Sending authorization code failed',
          this.isLoading = false
        }
      )
  }

  onSubmitVerifyForm() {
    this.isLoading = true;
    this.errorMessage = null;
    this._authService.authVerifyPhoneValidatePost(this.verifyForm.value)
      .subscribe(
        res => {
          this.data.user.phone = res.phone;
          if (res.token) {
            this.handleLogin(res);
          }
          this.phoneVerified = true;
         }, 
        err => {
          this.errorMessage = err.error.message,
          this.isLoading = false
        }
      )
  }

  handleLogin({token}) {
    localStorage.setItem('token', token);
    window.location.reload();
  }

  onClickTryAgain() {
    this.validationCodeSent = false;
    this.errorMessage = null;
    this.verifyForm.get('code').setValue('');
  }

  telInputObject(obj) {
    this.intTelInputObject = obj;
  }

  get canClose() {
    return this.data.user.phone && this.hasRole;
  }

  save() {
    this._usersService.usersIdPatch(<User><unknown>this.userForm.value, this.data.user.id).subscribe(data => {
      Object.assign(this.data.user, data);
      this.dialogRef.close(this.data.user);
    });
  }

}
