<div class="listing-item-wrapper">
  <div class="card mb-2">
    <div class="header-section card-img-top">
      <!-- Carousel -->
      <div id="carousel-{{listing.id}}" class="carousel slide" data-interval="false">
        <div class="carousel-inner">
          <div *ngFor="let photo of photos; index as i" [ngClass]="(i === 0) ? 'carousel-item active' : 'carousel-item'"
            [style.background-image]="'url(' + photo + ')'">
          </div>
        </div>
        <a *ngIf="photos.length > 1" class="carousel-control carousel-control-prev" href="#carousel-{{listing.id}}"
          role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a *ngIf="photos.length > 1" class="carousel-control carousel-control-next" href="#carousel-{{listing.id}}"
          role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <!-- End carousel -->
      <a class="favorite btn btn-light btn-circle" (click)="onClickFavorite()">
        <div *ngIf="!favorited">
          <mat-spinner diameter="20" *ngIf="isLoadingFavorite"></mat-spinner>
          <i-feather *ngIf="!isLoadingFavorite" name="heart"></i-feather>
        </div>
        <div *ngIf="favorited" class="favorited">
          <mat-spinner diameter="20" *ngIf="isLoadingFavorite"></mat-spinner>
          <i-feather *ngIf="!isLoadingFavorite" name="heart"></i-feather>
        </div>
      </a>
      <div *ngIf="listingApplication && !isLoadingApply" class="badge badge-success applied">{{'APPLIED' | translate}}
      </div>
      <span class="location badge badge-secondary">
        {{listing.address?.addressLocality}} <i
          *ngIf="listing.address?.geolocation && distanceToListing">({{distanceToListing}})</i>
      </span>
    </div>

    <div class="card-body p-3">
      <h5 class="card-title text-truncate"><a class="listing-title"
          (click)="onClickListingTitle()"><span *ngIf="listing.autoSync" class="badge badge-success autosync-badge" title="Auto Sync"><i-feather name="refresh-cw" style="color: white;"></i-feather></span> {{title}}</a></h5>
      <div class="employer-salary-warpper my-1">
        <div class="employer text-truncate mb-2">
          <a [routerLink]="'/companies/' + listing.owner.id"><span class="employer-name">
              <i-feather name="briefcase"></i-feather> {{listing.owner.name}}
            </span></a>
        </div>
        <div class="salary">
          <span class="amount">{{listing.price | currency:'USD'}}</span>/month
        </div>
      </div>
      <div *ngIf="!hideDescription" class="block-with-text">
        {{description}}
      </div>
    </div>
  </div>
</div>