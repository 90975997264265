export default {
  Address: {
    type: 'object',
    properties: {
      geolocation: {
        type: 'object',
        properties: {
          lat: {
            type: 'string'
          },
          lng: {
            type: 'string'
          },
        }
      }
    }
  },
  Company: {
    type: 'object',
    className: 'row',
    widget: 'company',
    classNames: {
      name: 'col-lg-6'
    },
    properties: {
      id: {
        widget: 'hidden'
      },
      description: {
        widget: 'textarea'
      },
      internalNotes: {
        widget: 'textarea'
      },
      isExternal: {
        default: true
      },
      autoSyncListings: {
        default: true
      },
      address: {
        widget: 'address',
        $ref: '#/definitions/Address'
      },
      // TODO this should be imported automatically via ref
      // address: {
      //   type: 'object',
      //   widget: 'address',
      //   properties: {
      //     addressCountry: {
      //       type: 'string',
      //       widget: 'hidden'
      //     },
      //     addressLocality: {
      //       type: 'string',
      //       widget: 'hidden'
      //     },
      //     addressRegion: {
      //       type: 'string',
      //       widget: 'hidden'
      //     },
      //     streetAddress: {
      //       type: 'string',
      //       widget: 'hidden'
      //     },
      //     formattedAddress: {
      //       type: 'string',
      //       widget: 'hidden'
      //     },
      //     postalCode: {
      //       type: 'string',
      //       widget: 'hidden'
      //     },
      //     geolocation: {
      //       type: 'object',
      //       properties: {
      //         lat: {
      //           type: 'number',
      //           widget: 'hidden'
      //         },
      //         lng: {
      //           type: 'number',
      //           widget: 'hidden'
      //         },
      //       }
      //     }
      //   }
      // },
      mediaContent: {
        type: 'array',
        widget: 'media-content-array',
        items: {
          type: 'object',
          widget: 'media-content',
          properties: {
            id: {
              type: 'string'
            },
            title: {
              type: 'string',
              title: 'Image caption'
            },
            url: {
              type: 'string'
            },
            file: {
              type: 'string',
              widget: 'file'
            },
            weight: {
              type: 'number',
              widget: 'number'
            }
          }
        }
      }
    }
  },
  Listing: {
    properties: {
      id: {
        widget: 'hidden'
      },
      published: {
        default: true
      },
      showOwner: {
        default: true
      },
      postedAsCompany: {
        default: false
      },
      featured: {
        default: false
      },
      internalNotes: {
        widget: 'textarea'
      },
      dateAvailable: {
        type: 'string',
        widget: 'date'
      },
      owner: {
        type: 'object',
        properties: {
          id: {
            type: 'string',
            widget: 'select',
            oneOf: []
          }
        }
      },
      companyLabel: {
        type: 'object',
        properties: {
          id: {
            type: 'string',
            widget: 'select',
            oneOf: []
          }
        },
        visibleIf: {
          postedAsCompany: [true]
        }
      },
      type: {
        default: 'APARTMENT'
      },
      status: {
        default: 'NEW'
      },
      description: {
        widget: 'textarea'
      },
      // TODO this should be imported automatically via ref
      address: {
        type: 'object',
        widget: 'address',
        properties: {
          addressCountry: {
            type: 'string',
            widget: 'hidden'
          },
          addressLocality: {
            type: 'string',
            widget: 'hidden'
          },
          addressRegion: {
            type: 'string',
            widget: 'hidden'
          },
          streetAddress: {
            type: 'string',
            widget: 'hidden'
          },
          formattedAddress: {
            type: 'string',
            widget: 'hidden'
          },
          geolocation: {
            type: 'object',
            properties: {
              lat: {
                type: 'number',
                widget: 'hidden'
              },
              lng: {
                type: 'number',
                widget: 'hidden'
              },
            }
          }
        }
      },
      mediaContent: {
        type: 'array',
        widget: 'media-content-array',
        items: {
          type: 'object',
          widget: 'media-content',
          properties: {
            id: {
              type: 'string'
            },
            title: {
              type: 'string',
              title: 'Image caption'
            },
            url: {
              type: 'string',
            },
            file: {
              type: 'string',
              widget: 'file'
            },
            weight: {
              type: 'number',
              widget: 'number'
            }
          }
        }
      }
    },
    "order": ["id", "owner", "autoSync", "title", "showOwner", "postedAsCompany", "companyLabel", "baths", "rooms", "beds", "price", "dateAvailable", "description", "amenities", "educationRequirements", "workRequirements", "status", "type", "internalNotes", "published", "featured", "mediaContent", "address"]
  },
  Application: {
    type: 'object',
    properties: {
      givenName: {
        type: 'string'
      },
      familyName: {
        type: 'string'
      },
      email: {
        type: 'string',
        widget: 'email'
      },
      phone: {
        type: 'string'
      }
    }
  }
}