import * as Rollbar from 'rollbar';
import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler
} from '@angular/core';
import { environment } from 'src/environments/environment';

const rollbarConfig: Rollbar.Configuration = {
  accessToken: '976ef57f475649ca8f88b682287f897e',
  captureUncaught: true,
  captureUnhandledRejections: true
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err:any) : void {
      if (environment.production) {
        this.rollbar.error(err.originalError || err);
      }
      
      console.error(err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}