<div class="widget form-group">
  <div class="row">
    <div class="col-md-2">
      <sf-form-element [formProperty]="formProperty.getProperty('isExternal')"></sf-form-element>
    </div>
    <div class="col-md-2">
      <sf-form-element [formProperty]="formProperty.getProperty('autoSyncListings')"></sf-form-element>
    </div>
    <div class="col-md-4">
      <sf-form-element [formProperty]="formProperty.getProperty('externalSource')"></sf-form-element>
    </div>
    <div class="col-md-4">
      <div class="external-id-container">
        <div>
          <sf-form-element [formProperty]="formProperty.getProperty('externalId')"></sf-form-element>
        </div>
        <div>
          <button class="btn sync btn-success" (click)="onClickSync()" *ngIf="showSync">Sync</button>
        </div>
      </div>
      
    </div>
    <div class="col-md-6">
      <sf-form-element [formProperty]="formProperty.getProperty('name')"></sf-form-element>
    </div>
    <div class="col-md-6">
      <sf-form-element [formProperty]="formProperty.getProperty('email')"></sf-form-element>
    </div>
    <div class="col-md-6">
      <sf-form-element [formProperty]="formProperty.getProperty('phone')"></sf-form-element>
    </div>
    <div class="col-md-6">
      <sf-form-element [formProperty]="formProperty.getProperty('website')"></sf-form-element>
    </div>
    <div class="col-md-6">
      <sf-form-element [formProperty]="formProperty.getProperty('slogan')"></sf-form-element>
    </div>
    <div class="col-md-6">
      <sf-form-element [formProperty]="formProperty.getProperty('slug')"></sf-form-element>
    </div>
    <div class="col-md-12">
      <sf-form-element [formProperty]="formProperty.getProperty('description')"></sf-form-element>
    </div>
    <div class="col-md-12">
      <sf-form-element [formProperty]="formProperty.getProperty('internalNotes')"></sf-form-element>
    </div>
    <div class="col-md-12">
      <sf-form-element [formProperty]="formProperty.getProperty('address')"></sf-form-element>
    </div>
    <div class="col-md-12">
      <sf-form-element [formProperty]="formProperty.getProperty('mediaContent')"></sf-form-element>
    </div>
    <div class="col-md-12">
      <sf-form-element [formProperty]="formProperty.getProperty('amenities')"></sf-form-element>
    </div>
    <div class="col-md-12">
      <sf-form-element [formProperty]="formProperty.getProperty('companyActivityType')"></sf-form-element>
    </div>
  </div>
</div>