import { Component, OnInit } from '@angular/core';
import { Listing, UsersService } from 'src/app/swagger-generated';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-favorite-listings',
  templateUrl: './favorite-listings.component.html',
  styleUrls: ['./favorite-listings.component.scss']
})
export class FavoriteDashboardListingsComponent implements OnInit {

  listings: Listing[];

  constructor(
    private usersService: UsersService,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this.sessionService.getCurrentUser().then(user => {
      const joinQueryString = [
        'favoriteListings',
        'listingApplications',
        'listingApplications.listing',
        'favoriteListings.companyLabel',
        'favoriteListings.companyLabel.companyLogo',
        'favoriteListings.owner',
        'favoriteListings.usersFavorited',
        'favoriteListings.address',
        'favoriteListings.price'
      ]
      this.usersService.usersIdGet(user.id, undefined, joinQueryString).subscribe(response => {
        this.listings = response.favoriteListings.map(favoriteListing => {
          favoriteListing.usersFavorited = [user];
          let listingApplication = response.listingApplications.find(listingApplication => {
            return listingApplication.listing.id === favoriteListing.id;
          });
          if (listingApplication) {
            listingApplication.user = user;
            return {
              ...favoriteListing,
              listingApplications: [listingApplication]
            }
          }
          return favoriteListing;
        });
      });
    })

  }
}
