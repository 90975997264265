<header>
  <nav class="navbar main-nav navbar-expand-md fixed-top">
    <div class="container">
      <a class="navbar-brand ml-4" routerLink="/">
        <img class="logo-white" src="/assets/img/homi/logo.png">
      </a>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <mat-spinner *ngIf="!isLoaded" diameter="20"></mat-spinner>
        <ul class="navbar-nav mr-auto">
          <li *ngIf="isAuthenticated" class="nav-item" routerLinkActive="active"><a class="nav-link" title="Listings"
              routerLink="/listings">Listings</a>
          </li>
          <li *ngIf="hasRole(RolesEnum.REALESTATE) && isAuthenticated" class="nav-item" routerLinkActive="active"><a class="nav-link"
              title="Homis" routerLink="/candidates">Homis</a></li>
        </ul>
        <ul *ngIf="!isAuthenticated && isLoaded" class="navbar-nav mt-2 mt-md-0 hidden">
          <li class="nav-item" routerLinkActive="active"><a class="nav-link sign-up-button" title="Authenticate"
              routerLink="/login">Authenticate</a></li>
        </ul>

        <!-- NOTIFICATIONS -->
        <div *ngIf="isAuthenticated && currentUser && isLoaded">
          <app-notifications-dropdown></app-notifications-dropdown>
        </div>


        <div *ngIf="isAuthenticated && currentUser && isLoaded" class="dropdown">
          <div class="dropdown-toggle user-dropdown" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <div [style.background-image]="'url(' + profilePictureUrl + ')'"
              class="rounded-circle my-account-thumbnail mr-2"></div> {{currentUser.givenName}}
          </div>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a routerLink="/dashboard" class="dropdown-item">Dashboard</a>
            <a routerLink="/dashboard/favorite-listings" class="dropdown-item">Favorites</a>
            <a *ngIf="hasRole(RolesEnum.USER)" routerLink="/dashboard/applied-listings" class="dropdown-item">Applied
              Listings</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="/logout">Log Out</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>