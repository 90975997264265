import { Component, OnInit } from '@angular/core';
import { ListingItemComponent } from '../listing-item/listing-item.component';
import { ListingsService } from '../../swagger-generated';
import { SessionService } from '../../services/session.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-listing-item-grid',
  templateUrl: './listing-item-grid.component.html',
  styleUrls: ['../listing-item/listing-item.component.scss', './listing-item-grid.component.scss']
})
export class ListingItemGridComponent extends ListingItemComponent {

  constructor(
    listingsService: ListingsService,
    sessionService: SessionService,
    router: Router,
    _matSnackBar: MatSnackBar,
    _translateService: TranslateService
  ) {
    super(listingsService, sessionService, router, _matSnackBar, _translateService);
  }

  get title() {
    if (this.listing.title) {
      return this.listing.title;
    }
    return this.listing.owner.name + ' ' + this.listing.rooms + ' bdr'
  }

  get photos() {
    const listingPhotos = this.listing.mediaContent.map(mediaContent => this.getMediaContentUrl(mediaContent, 'listing', 'thumb'));
    const companyPhotos = this.listing.owner.mediaContent.map(mediaContent => this.getMediaContentUrl(mediaContent, 'listing', 'thumb'));
    return [...listingPhotos, ...companyPhotos];
  }

}
