import { Component, OnInit } from '@angular/core';
import { CompaniesService, Company, UsersService } from 'src/app/swagger-generated';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-manage-companies',
  templateUrl: './manage-companies.component.html',
  styleUrls: ['./manage-companies.component.scss']
})
export class ManageCompaniesComponent implements OnInit {

  companies: Company[];
  isLoadingSync = false;

  constructor(
    private _companiesService: CompaniesService,
    private _usersService: UsersService,
    private _sessionService: SessionService
  ) { }

  ngOnInit() {
    this.fetchCompanies();
  }

  fetchCompanies() {
    const joinQueryString = [
      'administratorOf',
      'administratorOf.mediaContent',
      'administratorOf.address'
    ];
    this._sessionService.getCurrentUser().then(user => {
      this._usersService.usersIdGet(user.id, undefined, joinQueryString).subscribe(user => this.companies = user.administratorOf);
    })
  }

  onClickSyncCompanies() {
    this.isLoadingSync = true;
    this._companiesService.companiesExternalSyncPost().subscribe(data => {
      this.isLoadingSync = false;
    });
  }

  onClickDelete(company: Company): void {
    3
    this._companiesService.companiesIdDelete(company.id).subscribe(data => {
      this.fetchCompanies()
    })
  }

}
