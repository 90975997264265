import Plyr from 'plyr';
import * as mime from 'mime';
import * as _ from 'lodash';
import { MediaContent } from '../modules/generated-api.module';
import { environment } from '../../environments/environment';

export function getMediaContentUrl(mediaContent?: MediaContent, entityType?: string, size = 'original', entity?: any) {

  const randomMaleAvatarCodes = ['001', '002', '004', '005', '006', '008', '009', '011', '012', '014', '015', '016', '017'];
  const randomFemaleAvatarCodes = ['003', '007', '010', '013', '018', '021', '024'];

  let mediaContentUrl;
    if (mediaContent) {
      switch (size) {
      case 'thumb': mediaContentUrl = mediaContent.thumbSizeUrl; break;
      case 'medium': mediaContentUrl = mediaContent.mediumSizeUrl; break;
      case 'large': mediaContentUrl = mediaContent.largeSizeUrl; break;
    }
    
    if (!mediaContentUrl) {
      mediaContentUrl = mediaContent.url;
    }
  }
  

  if (mediaContent && mediaContent.url && mediaContent.url.startsWith('http')) {
    return mediaContentUrl;
  } else if (mediaContent && mediaContent.url) {
    return environment.staticResourcesUrl + mediaContentUrl;
  }

  switch (entityType) {
    case 'company': return '/assets/img/default-company-thumb.png';
    case 'user': return `/assets/img/default-user-thumb.png`;
    case 'job': return '/assets/img/default-job-thumb.png';
    default: return '/assets/img/default-nophoto-thumb.png';
  }
}

export function getMediaContentVideoSource(mediaContent: MediaContent): Plyr.Source {
  let mediaContentUrl = mediaContent.url;
  if (!mediaContent.url.startsWith('http')) {
    mediaContentUrl = environment.staticResourcesUrl + mediaContent.url;
  }
  let source: Plyr.Source = {
    src: mediaContentUrl,
    title: mediaContent.title
  };
  // #173 This does not comply to all url formats used by YouTube and Vimeo
  if (mediaContent.url.match(/youtube/)) {
    source.provider = 'youtube';
  } else if (mediaContent.url.match(/vimeo/)) {
    source.provider = 'vimeo';
  } else {
    source.type = mime.getType(mediaContentUrl.match(/\.(\S{2,5})$/gi)[0]);
  }

  /**
     * Apparently this is what HTML5 needs
     */
    if (source.type === 'video/quicktime') {
      source.type = 'video/mp4';
    }

  return source;
}

export function saveMediaContent() {
  
}