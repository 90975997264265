import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatStepperModule, MatAutocompleteModule, MatFormFieldModule, MatProgressSpinnerModule, MatSelectModule, MatCardModule, MatDividerModule, MatProgressBarModule, MatTooltipModule, MatSnackBarModule, MatChipsModule, MatDatepickerModule, MatNativeDateModule, MatInputModule, MatIconModule, MatButtonModule, MatRadioModule, MatDialogModule } from '@angular/material';
import { PlyrModule } from 'ngx-plyr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MomentModule } from 'ngx-moment';
import { Ng2TelInputModule } from 'ng2-tel-input';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TosComponent } from './pages/tos/tos.component';
import { ListingsComponent } from './pages/listings/listings.component';
import { EmployersComponent } from './pages/employers/employers.component';
import { CandidatesComponent } from './pages/candidates/candidates.component';
import { CandidateComponent } from './pages/candidate/candidate.component';
import { ListingComponent } from './components/listing/listing.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpUrlEncodingCodec } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { GeneratedApiModule } from './modules/generated-api.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LogoutComponent } from './components/logout/logout.component';
import { LoginRegisterComponent } from './pages/login-register/login-register.component';
import { IconsModule } from './modules/icons.module';
import { AddListingComponent } from './pages/add-listing/add-listing.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DashboardHomeComponent } from './components/dashboard/home/home.component';
import { DashboardListingsComponent } from './components/dashboard/listings/listings.component';
import { ListingItemComponent } from './components/listing-item/listing-item.component';
import { FavoriteDashboardListingsComponent } from './components/dashboard/favorite-listings/favorite-listings.component';
import { EditProfileComponent } from './components/dashboard/edit-profile/edit-profile.component';
import { EscapeHtmlPipe } from './pipes/escape-html.pipe';
import { UserItemComponent } from './components/user-item/user-item.component';
import { DashboardAppliedListingsComponent } from './components/dashboard/applied-listings/applied-listings.component';
import { ListingApplicationsComponent } from './pages/listing-applications/listing-applications.component';
import { Ng5SliderModule } from 'ng5-slider';
import { RollbarService, rollbarFactory, RollbarErrorHandler } from './services/rollbar.service';
import { CompaniesComponent } from './pages/companies/companies.component';
import { CompanyComponent } from './pages/company/company.component';
import { ManageCompaniesComponent } from './components/dashboard/manage-companies/manage-companies.component';
import { MediaContentComponent } from './components/media-content/media-content.component';
import { CandidateApplicationComponent } from './components/candidate-application/candidate-application.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { UpdateRequiredProfileDetailsComponent } from './components/update-required-profile-details/update-required-profile-details.component';
import { SearchListingComponent } from './components/search-listing/search-listing.component';
import { ListingPageComponent } from './pages/listing/listing.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ListingItemGridComponent } from './components/listing-item-grid/listing-item-grid.component';
import { MediaContentsGalleryComponent } from './components/media-contents-gallery/media-contents-gallery.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { ThreadMessagesComponent } from './components/thread-messages/thread-messages.component';
import { NotificationsDropdownComponent } from './components/notifications-dropdown/notifications-dropdown.component';
import { GoogleMapsService } from './services/google-maps.service';
import { DashboardExternalCompaniesComponent } from './pages/dashboard-external-companies/dashboard-external-companies.component';
import { CompanyLandingPageComponent } from './pages/company-landing-page/company-landing-page.component';
import { DialogApplyComponent } from './components/dialog-apply/dialog-apply.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule as CustomFormsModule } from './modules/forms/forms.module';
import { SchemaFormModule, WidgetRegistry } from 'ngx-schema-form';
import { CustomWidgetRegistryService } from './modules/forms/widgets/widget-registry.service';
import { UsersComponent } from './components/dashboard/users/users.component';
import { CommonModule } from '@angular/common';
import { AdminListingApplicationsComponent } from './components/dashboard/admin-listing-applications/admin-listing-applications.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    TosComponent,
    ListingsComponent,
    ListingPageComponent,
    EmployersComponent,
    CandidatesComponent,
    CandidateComponent,
    ListingComponent,
    DashboardComponent,
    DashboardListingsComponent,
    LogoutComponent,
    LoginRegisterComponent,
    AddListingComponent,
    DashboardHomeComponent,
    ListingItemComponent,
    FavoriteDashboardListingsComponent,
    EditProfileComponent,
    EscapeHtmlPipe,
    UserItemComponent,
    DashboardAppliedListingsComponent,
    ListingApplicationsComponent,
    AdminListingApplicationsComponent,
    CompaniesComponent,
    CompanyComponent,
    ManageCompaniesComponent,
    MediaContentComponent,
    CandidateApplicationComponent,
    ReplacePipe,
    UpdateRequiredProfileDetailsComponent,
    SearchListingComponent,
    AboutUsComponent,
    ListingItemGridComponent,
    MediaContentsGalleryComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    MessagesComponent,
    ThreadMessagesComponent,
    NotificationsDropdownComponent,
    DashboardExternalCompaniesComponent,
    CompanyLandingPageComponent,
    DialogApplyComponent,
    UsersComponent

  ],
  imports: [
    SchemaFormModule.forRoot(),
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    GeneratedApiModule,
    IconsModule,
    ReactiveFormsModule,
    FormsModule,
    CustomFormsModule,
    EditorModule,
    HttpClientModule,
    MomentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MatStepperModule,
    BrowserAnimationsModule,
    PlyrModule,
    Ng5SliderModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatCardModule,
    MatDividerModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatDialogModule,
    Ng2TelInputModule,
    NgbModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    // { 
    //   provide: ErrorHandler,
    //   useClass: RollbarErrorHandler
    // },
    {
      provide: RollbarService,
      useFactory: rollbarFactory
    },
    {
      provide: WidgetRegistry,
      useClass: CustomWidgetRegistryService
    },
    GoogleMapsService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    UpdateRequiredProfileDetailsComponent,
    DialogApplyComponent
  ]
})
export class AppModule { }
