import { Injectable } from '@angular/core';
import { WidgetRegistryService } from 'ngx-swag-form';
import { FormControlCompanyComponent } from './form-control-company/form-control-company.component';
import { FormControlMediaContentHomiComponent } from './form-control-media-content-homi/form-control-media-content-homi.component';

@Injectable()
export class CustomWidgetRegistryService extends WidgetRegistryService {

  constructor() {
    super();
    this.register('company', FormControlCompanyComponent);
    this.register('media-content', FormControlMediaContentHomiComponent)
  }
}