import { Component, OnInit, ViewChild } from '@angular/core';
import { CompaniesService, Company, Listing, ListingsService } from '../../../app/swagger-generated';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '../../../app/services/session.service';
import { environment } from '../../../environments/environment';
import { getMediaContentUrl } from '../../services/mediaContent.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent {
  company: Company;
  canEdit: boolean;
  listings: Listing[];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _companiesService: CompaniesService,
    private _listingsService: ListingsService,
    private _sessionService: SessionService
  ) {
    const joinQueryString = [
      'mediaContent',
      'address',
      'administrators',
      'companyLogo'
    ];

    this._activatedRoute.params.subscribe(params => {
      this._companiesService.companiesIdGet(params.id, undefined, joinQueryString).subscribe(response => {
        this.company = response;
        this._sessionService.getCurrentUser().then(currentUser => {
          if (this.company.administrators.find(user => user.id === currentUser.id)) {
            this.canEdit = true;
          }
        })
      });

      const joinQueryString2 = [
        'address',
        'usersFavorited',
        'price',
        'listingApplications',
        'listingApplications.user',
        'ownerUsers',
        'userLabel',
        'userLabel.profilePictureUserLabel',
        'companyLabel',
        'owner',
        'companyLabel.companyLogo',
        'owner.logo',
        'thumbnail'
      ]
      this._listingsService.listingsGet(undefined, [`owner||eq||${params.id}`], [`companyLabel||eq||${params.id}`], undefined, joinQueryString2).subscribe(response => {
        this.listings = response;
      });
    })
  }

  get companyLogoUrl() {
    if (this.company) {
      return getMediaContentUrl(this.company.companyLogo, 'company', 'thumb');
    }
  }


  get googleMapsImage() {
    if (this.company && this.company.address) {
      const coords = this.company.address.geolocation;
      return `https://maps.googleapis.com/maps/api/staticmap?center=${coords.lat},${coords.lng}&zoom=13&size=300x300&maptype=roadmap&markers=${coords.lat},${coords.lng}&key=${environment.googleMapsApiKey}`
    }
  }

  get mediaContents() {
    if (this.company && this.company.mediaContent && this.company.photos) {
      return [...this.company.mediaContent, ...this.company.photos];
    }
    return [];
  }
}
