<div id="home-layout">
  <div class="version2">
    <section id="slider-container">
      <div class="introducing-homi">
        <h1 class="top-main-title diffused-shadow">Find Roommates {{this.company.address ? ' in ' + this.company.address?.addressLocality : ''}} at {{company?.name}}</h1>
        <h3>{{getDateLabel()}}</h3>
        <h2 class=" diffused-shadow" *ngIf="smallestPrice">Rent as low as {{smallestPrice | currency:'USD'}}/<small>mo</small> with roommates</h2>
        <div>
          <button class="btn btn-primary btn-lg mt-4" (click)="onClickApply()">Apply with Homi</button>
        </div>
        <p class="company-description mt-4">
          {{company?.description}}
        </p>
      </div>
      <!-- Carousel -->
      <div id="carousel" class="carousel slide" data-interval="3000" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item slick-current slick-active" [class.active]="i === 0" aria-hidden="true"
            tabindex="-1" *ngFor="let photo of company?.mediaContent; index as i">
            <div class="landing-page-intro-text">
            </div><img src="{{getMediaContentUrl(photo, 'user', 'large')}}">
          </div>
        </div>
        <a class="carousel-control carousel-control-prev" href="#carousel" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control carousel-control-next" href="#carousel" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <!-- End carousel -->
    </section>
    <section id="subscribe-homi" class="big-carousel-item text-center subscribe-form-container">
      <h2>Homi is officially in Boston! Download the app below to get started.</h2>
      <div class="row download-app-container">
        <div class="col-md-4 offset-md-2 store-logo-container"><a
            href="http://itunes.apple.com/app/id1451069927"><img src="/assets/img/homi/app-store-badge.png"
              class="store-logo"></a>
        </div>
        <div class="col-md-4 store-logo-container"><a
            href="https://play.google.com/store/apps/details?id=com.homiv2"><img
              src="/assets/img/homi/google-play-badge.png" class="store-logo"></a></div>
      </div>
      <form class="subscribe-form hidden">
        <div class="form-group email-input-container"><input placeholder="Enter email address" name="email" type="email"
            required="required" class="rounded-input subscribe-email form-control"></div><button type="submit"
          class="btn btn-primary btn-homi subscribe-button-container">Notify me</button>
      </form><br>
      <div class="server-alert-container"><span class="alert alert-default hidden">Registering...</span></div>
    </section>
    <section class="categories-slide row">
      <div class="col-md-12">
        <h2>Lock in your perfect apartment.</h2>
      </div>
      <div class="categories-slide-block col-md-4">
        <div class="categories-slide-block-number">1</div>
        <h4 class="categories-slide-block-title">PICK A SWEET CRIB</h4>
        <p>Browse hundreds of local listings that fit your style. Discover your ideal home right through the app.</p>
      </div>
      <div class="categories-slide-block col-md-4">
        <div class="categories-slide-block-number">2</div>
        <h4 class="categories-slide-block-title">FIND YOUR CREW</h4>
        <p>Rent too high? Check out roommate profiles of other peeps interested in the same place.</p>
      </div>
      <div class="categories-slide-block col-md-4">
        <div class="categories-slide-block-number">3</div>
        <h4 class="categories-slide-block-title">LOCK IT IN</h4>
        <p>Go in on the place together—and sign a lease. Our real estate pros make it happen fast.</p>
      </div>
    </section>
    <section class="row categories-carousel-item expert-help-section">
      <div class="col-md-6">
        <h1 class="diffused-shadow">Get paired with a real estate pro.</h1><br>
        <h4>We'll set up everything:</h4><br>
        <ul>
          <li>Apartment showings</li>
          <li>Roommate interviews</li>
          <li>Lease prep and signing</li>
        </ul>
      </div>
    </section>
    <section class="signup-slide">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <h2 class="need-more-info-title">Want to find out more or share ideas?</h2>
          <p>Call<a href="tel:6179342409" class="text-homi"> 617-934-2409</a>&nbsp;or e-mail us at<a
              href="mailto:contact@homi.house" class="text-homi"> contact@homi.house</a></p>
        </div>
      </div>
      <div class="row download-app-container">
        <div class="col-md-4 offset-md-2 store-logo-container"><a href="http://itunes.apple.com/app/id1451069927"><img
              src="/assets/img/homi/app-store-badge.png" class="store-logo"></a></div>
        <div class="col-md-4 store-logo-container"><a href="https://play.google.com/store/apps/details?id=com.homiv2"><img src="/assets/img/homi/google-play-badge.png"
              class="store-logo"></a></div>
      </div>
    </section>
  </div>
</div>