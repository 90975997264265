import { BENEFITS } from './benefits';
import { EDUCATION_LEVELS } from './education-levels';
import { MediaContentViewModesEnum } from './media-content-view-modes';
import { SPECIAL_COMMITMENTS } from './special-commitments';
import { WORK_HOURS } from './work-hours';
import { COMPANY_ACTIVITY_TYPES } from './company-activity-type';

export const MODELS = {
    BENEFITS,
    EDUCATION_LEVELS,
    MediaContentViewModesEnum,
    SPECIAL_COMMITMENTS,
    WORK_HOURS,
    COMPANY_ACTIVITY_TYPES
}