<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
    <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
    <li class="breadcrumb-item"><a routerLink="/dashboard/listings">Manage listings</a></li>
    <li class="breadcrumb-item"><a [routerLink]="'/listings/' + listing?.id">{{listing?.title}}</a></li>
    <li class="breadcrumb-item active">Candidates</li>
  </ol>
</nav>

<div class="candidates-wrapper container mt-4">
  <h4>Listing applications</h4>
  <div class="card">
    <div class="card-body">
      <div class="row p-0">
        <div *ngFor="let listingApplication of listing?.listingApplications" class="col-lg-4 user-container">
          <div class="card">
            <div class="card-body">
              <app-candidate-application [listingApplication]="listingApplication"></app-candidate-application>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>