import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
import { filter } from 'rxjs/operators';
import { GoogleAnalyticsService } from './services/google-analytics.service';

declare const feather;
declare const jQuery;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'platform-ui';
  appClass;

  constructor(
    private router: Router,
    private _translateService: TranslateService,
    private _matSnackBar: MatSnackBar,
    private _googleAnalyticsService: GoogleAnalyticsService
  ) {
    _translateService.setDefaultLang('en');
    _translateService.use('en').subscribe(() => {
      this._checkCookies();
    });
    this._initializePagesLogic();
  }

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (!(event instanceof NavigationEnd)) {
        this._googleAnalyticsService.setPage(event.urlAfterRedirects);
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  private _checkCookies() {
    // if (localStorage.getItem('3mj_cookies_accepted') === null) {
    //   this._matSnackBar.open(this._translateService.instant('cookiesGdprMessage'), this._translateService.instant('iAgree'))
    //     .onAction().subscribe(() => {
    //       localStorage.setItem('3mj_cookies_accepted', 'true');
    //       this._matSnackBar.dismiss();
    //     });
    // }
  }

  private _initializePagesLogic() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(res => {
      let classFromUrl = this.router.url.substring(1);
      if (classFromUrl.indexOf('?') !== -1) {
        classFromUrl = classFromUrl.substr(0, classFromUrl.indexOf('?'))
      }
      let queryParamsIndex = classFromUrl.indexOf('/');
      if (queryParamsIndex !== -1) {
        classFromUrl = classFromUrl.substring(0, queryParamsIndex);
      }
      this.appClass = classFromUrl || 'index';
    });
  }
}
