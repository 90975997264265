import { Component, forwardRef, Input, OnChanges, ViewChild, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MediaContent, MediaContentService, Language, CommonService } from 'src/app/swagger-generated';
import { MediaContentViewModesEnum } from 'src/app/shared/models/media-content-view-modes';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { getMediaContentUrl, getMediaContentVideoSource } from '../../services/mediaContent.service';

export const MEDIA_CONTENT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MediaContentComponent),
  multi: true,
};

@Component({
  selector: 'app-media-content',
  templateUrl: './media-content.component.html',
  styleUrls: ['./media-content.component.scss'],
  providers: [MEDIA_CONTENT_VALUE_ACCESSOR]
})

export class MediaContentComponent implements ControlValueAccessor, OnChanges, OnInit {
  @Input() viewMode: MediaContentViewModesEnum;
  @Input() mediaContent: MediaContent = <MediaContent><unknown>{};
  @Input() defaultThumbType: string;
  @Input() disableDelete: boolean;
  @Output() mediaContentUploaded: EventEmitter<any> = new EventEmitter();
  @ViewChild('fileUpload') fileUpload: ElementRef;
  @ViewChild('languageAutocomplete') languageAutocomplete: MatAutocomplete;
  mediaContentViewModesEnum = MediaContentViewModesEnum;
  isLoading = false;
  errorMessage;
  videoUrl;
  languageOptions: Language[] = [];
  allLanguagesOptions: Language[] = [];
  selectedLanguage: Language;
  sources;

  propagateChange: any = () => { };
  validateFn: any = () => { };
  languages: Language[];
  cvVideoTypes = [
    'INTRO',
    'LANGUAGE'
  ]

  value: string;

  constructor(
    private _httpClient: HttpClient,
    private _mediaContentService: MediaContentService,
    private _commonService: CommonService
  ) { }

  ngOnChanges(inputs) {
    if (inputs && inputs.mediaContent) {
      this.mediaContent = inputs.mediaContent;
      this.sources = [getMediaContentVideoSource(this.mediaContent)];
      this.value = inputs.mediaContent.id;
    }
  }

  ngOnInit() {
    this._commonService.languagesGet().subscribe(response => {
      this.languages = response;
    })
    this._commonService.languagesGet().subscribe(response => this.allLanguagesOptions = response);
  }

  writeValue(value) {
    this.value = value;
    if (value) {
      if (!this.mediaContent || !this.mediaContent.url) {
        this._mediaContentService.mediaContentIdGet(this.value, undefined, ['userVideoCvLanguage2']).subscribe(mediaContent => {
          this.mediaContent = mediaContent;
          this.sources = [getMediaContentVideoSource(this.mediaContent)];
        })
      }
    }
    this.propagateChange(this.value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  validate(c: FormControl) {
    return true;
  }

  onOptionSelectedLanguage(event: MatAutocompleteSelectedEvent) {
    this.selectedLanguage = event.option.value;
  }

  onClickUrlVideoUpload(ev){
    const formData = new FormData();

      if (this.selectedLanguage) {
        formData.append('userVideoCvLanguage2[id]', this.selectedLanguage.id.toString());
        formData.append('userVideoCvLanguage2[name]', this.selectedLanguage.name);
      }

      if (this.mediaContent.title) {
        formData.append('title', this.mediaContent.title);
      }


      if (this.videoUrl) {
        
        formData.append('url', this.videoUrl);
        
        let youtubePattern = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
        let vimeoPattern = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/;

        if (this.videoUrl.match(youtubePattern)) {
          formData.append('type', 'VIDEO_YOUTUBE');
        } else if (this.videoUrl.match(vimeoPattern)) {
          formData.append('type', 'VIDEO_VIMEO');
        }
      }


      this.isLoading = true;

      this._httpClient.post<any>(`${environment.apiUrl}/mediaContent`, formData, {
        headers: null
      }).subscribe(mediaContent => {
        this.value = mediaContent.id;
        this.mediaContent = mediaContent;
        this.propagateChange(this.value)
        this.isLoading = false;
        this.mediaContentUploaded.emit(mediaContent);
      }, (err) => {
        this.isLoading = false;
        this.errorMessage = err;
      });
  }

  onChangeMediaContent(ev) {
    if (ev.currentTarget.value) {
      const formData = new FormData();

      if (this.selectedLanguage) {
        formData.append('userVideoCvLanguage2[id]', this.selectedLanguage.id.toString());
        formData.append('userVideoCvLanguage2[name]', this.selectedLanguage.name);
      }

      if (this.mediaContent.title) {
        formData.append('title', this.mediaContent.title);
      }

      if (this.videoUrl) {
        
        formData.append('url', this.videoUrl);
      } else {
        formData.append('file', this.fileUpload.nativeElement.files[0]);
      }


      this.isLoading = true;

      this._httpClient.post<any>(`${environment.apiUrl}/mediaContent`, formData, {
        headers: null
      }).subscribe(mediaContent => {
        this.value = mediaContent.id;
        this.mediaContent = mediaContent;
        this.propagateChange(this.value)
        this.isLoading = false;
        this.mediaContentUploaded.emit(mediaContent);
      }, (err) => {
        this.isLoading = false;
        this.errorMessage = err;
      });
    }
  }

  onClickDeleteMediaContent() {
    this.isLoading = true;
    this._mediaContentService.mediaContentIdDelete(this.mediaContent.id).subscribe(
      response => {
        delete this.mediaContent;
        this.writeValue(null);
      }, err => {
        this.isLoading = false;
        this.errorMessage = err.error.message;
      }
    );
  }

  get mediaContentThumbUrl() {
    return getMediaContentUrl(this.mediaContent, this.defaultThumbType, 'thumb');
  }

  get mediaContentUrl() {
    return getMediaContentUrl(this.mediaContent, this.defaultThumbType);
  }

  onKeyUpLanguagesAutocomplete(event, i) {
    this.languageOptions = this._filter(event.currentTarget.value, this.allLanguagesOptions);
  }

  private _filter(value: any, allOptions: any[]): any[] {
    const filterValue = value.toLowerCase();

    return allOptions.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  languageDisplayFn(language?: Language): string | undefined {
    return language ? language.name : undefined;
  }

}
