/**
 * Homi Server Endpoints
 * Available interactions with the Homi Backend services
 *
 * OpenAPI spec version: 0.0.17
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Company } from './company';
import { Listing } from './listing';
import { ListingApplication } from './listingApplication';
import { MediaContent } from './mediaContent';
import { Message } from './message';
import { ModelDate } from './modelDate';
import { MonetaryAmount } from './monetaryAmount';
import { Nationality } from './nationality';
import { Notification } from './notification';
import { SpokenLanguage } from './spokenLanguage';
import { Thread } from './thread';
import { UserConnection } from './userConnection';


export interface User {
    id: string;
    createdAt: ModelDate;
    updatedAt: ModelDate;
    nickName: string;
    fcmToken: string;
    attendedCollege: string;
    attendedGradSchool: string;
    artAndMusicLikes: string;
    birthDate: string;
    email: string;
    familyName: string;
    gender: User.GenderEnum;
    givenName: string;
    title: string;
    phone: string;
    profilePicture: MediaContent;
    profilePictureUserLabel: MediaContent;
    isPublic: boolean;
    password: string;
    isFacebookUser: boolean;
    isGoogleUser: boolean;
    isBlocked: boolean;
    roles: Array<User.RolesEnum>;
    education: string;
    educationStatus: User.EducationStatusEnum;
    educationField: User.EducationFieldEnum;
    aboutMe: string;
    hobbies: string;
    artAndMusic: Array<string>;
    aboutMeInThreeWords: Array<User.AboutMeInThreeWordsEnum>;
    employmentStatus: User.EmploymentStatusEnum;
    rentingAvailability: User.RentingAvailabilityEnum;
    /**
     * Excluded listings
     */
    excluded: string;
    status: User.StatusEnum;
    administratorOf: Array<Company>;
    employeeOf: Array<Company>;
    favoriteListings: Array<Listing>;
    address: Address;
    spokenLanguages: Array<SpokenLanguage>;
    nationalities: Array<Nationality>;
    listingApplications: Array<ListingApplication>;
    listingsOwned: Array<Listing>;
    listingsLabeled: Array<Listing>;
    budget: MonetaryAmount;
    photos: Array<MediaContent>;
    connections: Array<UserConnection>;
    threads: Array<Thread>;
    messages: Array<Message>;
    createdMessages: Array<Message>;
    notifications: Array<Notification>;
}
export namespace User {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'OTHER';
    export const GenderEnum = {
        MALE: 'MALE' as GenderEnum,
        FEMALE: 'FEMALE' as GenderEnum,
        OTHER: 'OTHER' as GenderEnum
    }
    export type RolesEnum = 'ANONYMOUS' | 'USER' | 'REAL_ESTATE' | 'ADMIN';
    export const RolesEnum = {
        ANONYMOUS: 'ANONYMOUS' as RolesEnum,
        USER: 'USER' as RolesEnum,
        REALESTATE: 'REAL_ESTATE' as RolesEnum,
        ADMIN: 'ADMIN' as RolesEnum
    }
    export type EducationStatusEnum = 'HIGHSCHOOL' | 'COLLEGE' | 'GRADUATE';
    export const EducationStatusEnum = {
        HIGHSCHOOL: 'HIGHSCHOOL' as EducationStatusEnum,
        COLLEGE: 'COLLEGE' as EducationStatusEnum,
        GRADUATE: 'GRADUATE' as EducationStatusEnum
    }
    export type EducationFieldEnum = 'ACCOUNTING' | 'AEROSPACE_ENGINEERING_' | 'AGRICULTURE_AND_RELATED_SCIENCES' | 'ARCHAEOLOGY_' | 'ARCHITECTURE_BUILDING_AND_PLANNING' | 'ART_AND_DESIGN' | 'BIOLOGICAL_SCIENCES' | 'BUSINESS_MANAGEMENT_AND_ADMINISTRATIVE_STUDIES' | 'CHEMICAL_ENGINEERING' | 'CHEMISTRY' | 'CIVIL_ENGINEERING_' | 'CLASSICAL_STUDIES_' | 'COMPUTER_SCIENCE' | 'CREATIVE_ARTS_' | 'DENTISTRY_' | 'ECONOMICS' | 'EDUCATION' | 'ELECTRICAL_AND_ELECTRONIC_ENGINEERING' | 'ENGINEERING_AND_TECHNOLOGY' | 'ENGLISH' | 'FILM_CINEMATICS_AND_PHOTOGRAPHY_' | 'FINANCE' | 'GAME_DESIGN_' | 'GEOGRAPHY' | 'GEOLOGY_' | 'HISTORICAL_AND_PHILOSOPHICAL_STUDIES' | 'HOSPITALITY_LEISURE_AND_TOURISM' | 'JOURNALISM' | 'LANGUAGES' | 'LAW' | 'MARKETING' | 'MATHEMATICAL_SCIENCES' | 'MECHANICAL_ENGINEERING_' | 'MEDIA_STUDIES' | 'MEDICINE_AND_ALLIED_SUBJECTS' | 'MIDWIFERY' | 'MOLECULAR_BIOLOGY_BIOPHYSICS_AND_BIOCHEMISTRY' | 'MUSIC_' | 'NURSING' | 'OPTOMETRY' | 'PARAMEDIC_SCIENCE' | 'PHARMACOLOGY_TOXICOLOGY_AND_PHARMACY' | 'PHILOSOPHY' | 'PHYSICAL_SCIENCES' | 'PHYSIOLOGY_PHYSIOTHERAPY_AND_PATHOLOGY' | 'POLITICS_' | 'PSYCHOLOGY' | 'PUBLISHING_MEDIA_AND_INFORMATION_MANAGEMENT' | 'RADIOGRAPHY_AND_MEDICAL_TECHNOLOGY' | 'SOCIAL_STUDIES_' | 'SOCIAL_WORK' | 'SOFTWARE_ENGINEERING' | 'SPORT' | 'TEACHING' | 'THEOLOGY' | 'VETERINARY_SCIENCE' | 'ZOOLOGY';
    export const EducationFieldEnum = {
        ACCOUNTING: 'ACCOUNTING' as EducationFieldEnum,
        AEROSPACEENGINEERING: 'AEROSPACE_ENGINEERING_' as EducationFieldEnum,
        AGRICULTUREANDRELATEDSCIENCES: 'AGRICULTURE_AND_RELATED_SCIENCES' as EducationFieldEnum,
        ARCHAEOLOGY: 'ARCHAEOLOGY_' as EducationFieldEnum,
        ARCHITECTUREBUILDINGANDPLANNING: 'ARCHITECTURE_BUILDING_AND_PLANNING' as EducationFieldEnum,
        ARTANDDESIGN: 'ART_AND_DESIGN' as EducationFieldEnum,
        BIOLOGICALSCIENCES: 'BIOLOGICAL_SCIENCES' as EducationFieldEnum,
        BUSINESSMANAGEMENTANDADMINISTRATIVESTUDIES: 'BUSINESS_MANAGEMENT_AND_ADMINISTRATIVE_STUDIES' as EducationFieldEnum,
        CHEMICALENGINEERING: 'CHEMICAL_ENGINEERING' as EducationFieldEnum,
        CHEMISTRY: 'CHEMISTRY' as EducationFieldEnum,
        CIVILENGINEERING: 'CIVIL_ENGINEERING_' as EducationFieldEnum,
        CLASSICALSTUDIES: 'CLASSICAL_STUDIES_' as EducationFieldEnum,
        COMPUTERSCIENCE: 'COMPUTER_SCIENCE' as EducationFieldEnum,
        CREATIVEARTS: 'CREATIVE_ARTS_' as EducationFieldEnum,
        DENTISTRY: 'DENTISTRY_' as EducationFieldEnum,
        ECONOMICS: 'ECONOMICS' as EducationFieldEnum,
        EDUCATION: 'EDUCATION' as EducationFieldEnum,
        ELECTRICALANDELECTRONICENGINEERING: 'ELECTRICAL_AND_ELECTRONIC_ENGINEERING' as EducationFieldEnum,
        ENGINEERINGANDTECHNOLOGY: 'ENGINEERING_AND_TECHNOLOGY' as EducationFieldEnum,
        ENGLISH: 'ENGLISH' as EducationFieldEnum,
        FILMCINEMATICSANDPHOTOGRAPHY: 'FILM_CINEMATICS_AND_PHOTOGRAPHY_' as EducationFieldEnum,
        FINANCE: 'FINANCE' as EducationFieldEnum,
        GAMEDESIGN: 'GAME_DESIGN_' as EducationFieldEnum,
        GEOGRAPHY: 'GEOGRAPHY' as EducationFieldEnum,
        GEOLOGY: 'GEOLOGY_' as EducationFieldEnum,
        HISTORICALANDPHILOSOPHICALSTUDIES: 'HISTORICAL_AND_PHILOSOPHICAL_STUDIES' as EducationFieldEnum,
        HOSPITALITYLEISUREANDTOURISM: 'HOSPITALITY_LEISURE_AND_TOURISM' as EducationFieldEnum,
        JOURNALISM: 'JOURNALISM' as EducationFieldEnum,
        LANGUAGES: 'LANGUAGES' as EducationFieldEnum,
        LAW: 'LAW' as EducationFieldEnum,
        MARKETING: 'MARKETING' as EducationFieldEnum,
        MATHEMATICALSCIENCES: 'MATHEMATICAL_SCIENCES' as EducationFieldEnum,
        MECHANICALENGINEERING: 'MECHANICAL_ENGINEERING_' as EducationFieldEnum,
        MEDIASTUDIES: 'MEDIA_STUDIES' as EducationFieldEnum,
        MEDICINEANDALLIEDSUBJECTS: 'MEDICINE_AND_ALLIED_SUBJECTS' as EducationFieldEnum,
        MIDWIFERY: 'MIDWIFERY' as EducationFieldEnum,
        MOLECULARBIOLOGYBIOPHYSICSANDBIOCHEMISTRY: 'MOLECULAR_BIOLOGY_BIOPHYSICS_AND_BIOCHEMISTRY' as EducationFieldEnum,
        MUSIC: 'MUSIC_' as EducationFieldEnum,
        NURSING: 'NURSING' as EducationFieldEnum,
        OPTOMETRY: 'OPTOMETRY' as EducationFieldEnum,
        PARAMEDICSCIENCE: 'PARAMEDIC_SCIENCE' as EducationFieldEnum,
        PHARMACOLOGYTOXICOLOGYANDPHARMACY: 'PHARMACOLOGY_TOXICOLOGY_AND_PHARMACY' as EducationFieldEnum,
        PHILOSOPHY: 'PHILOSOPHY' as EducationFieldEnum,
        PHYSICALSCIENCES: 'PHYSICAL_SCIENCES' as EducationFieldEnum,
        PHYSIOLOGYPHYSIOTHERAPYANDPATHOLOGY: 'PHYSIOLOGY_PHYSIOTHERAPY_AND_PATHOLOGY' as EducationFieldEnum,
        POLITICS: 'POLITICS_' as EducationFieldEnum,
        PSYCHOLOGY: 'PSYCHOLOGY' as EducationFieldEnum,
        PUBLISHINGMEDIAANDINFORMATIONMANAGEMENT: 'PUBLISHING_MEDIA_AND_INFORMATION_MANAGEMENT' as EducationFieldEnum,
        RADIOGRAPHYANDMEDICALTECHNOLOGY: 'RADIOGRAPHY_AND_MEDICAL_TECHNOLOGY' as EducationFieldEnum,
        SOCIALSTUDIES: 'SOCIAL_STUDIES_' as EducationFieldEnum,
        SOCIALWORK: 'SOCIAL_WORK' as EducationFieldEnum,
        SOFTWAREENGINEERING: 'SOFTWARE_ENGINEERING' as EducationFieldEnum,
        SPORT: 'SPORT' as EducationFieldEnum,
        TEACHING: 'TEACHING' as EducationFieldEnum,
        THEOLOGY: 'THEOLOGY' as EducationFieldEnum,
        VETERINARYSCIENCE: 'VETERINARY_SCIENCE' as EducationFieldEnum,
        ZOOLOGY: 'ZOOLOGY' as EducationFieldEnum
    }
    export type AboutMeInThreeWordsEnum = 'HARD_WORKER' | 'INDEPENDENT' | 'SELF_CONTAINED';
    export const AboutMeInThreeWordsEnum = {
        HARDWORKER: 'HARD_WORKER' as AboutMeInThreeWordsEnum,
        INDEPENDENT: 'INDEPENDENT' as AboutMeInThreeWordsEnum,
        SELFCONTAINED: 'SELF_CONTAINED' as AboutMeInThreeWordsEnum
    }
    export type EmploymentStatusEnum = 'STUDENT' | 'EMPLOYED' | 'SELFEMPLOYED';
    export const EmploymentStatusEnum = {
        STUDENT: 'STUDENT' as EmploymentStatusEnum,
        EMPLOYED: 'EMPLOYED' as EmploymentStatusEnum,
        SELFEMPLOYED: 'SELFEMPLOYED' as EmploymentStatusEnum
    }
    export type RentingAvailabilityEnum = 'OPEN' | 'UNDECIDED' | 'NOT_AVAILABLE';
    export const RentingAvailabilityEnum = {
        OPEN: 'OPEN' as RentingAvailabilityEnum,
        UNDECIDED: 'UNDECIDED' as RentingAvailabilityEnum,
        NOTAVAILABLE: 'NOT_AVAILABLE' as RentingAvailabilityEnum
    }
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum,
        DELETED: 'DELETED' as StatusEnum
    }
}
