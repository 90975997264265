import { Component, OnInit, KeyValueDiffer, KeyValueDiffers, ViewChild, ElementRef, AfterViewChecked, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThreadsService, Message, User, Thread } from '../../swagger-generated';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-thread-messages',
  templateUrl: './thread-messages.component.html',
  styleUrls: ['./thread-messages.component.scss']
})
export class ThreadMessagesComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('messagesContainer') messagesContainer: ElementRef;
  threadId;
  messages: Message[] = [];
  currentUser: User;
  message: string;
  thread: Thread;
  private messageDiffer: KeyValueDiffer<string, any>;
  initialMessageScroll = false;
  pullInterval;
  isLoading = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _threadsService: ThreadsService,
    private _sessionService: SessionService,
    private _differs: KeyValueDiffers,
    private _router: Router
  ) {
    this._sessionService.getCurrentUser().then(user => this.currentUser = user)
    this._activatedRoute.params.subscribe(q => {
      this.threadId = q.id;
      this.initialMessageScroll = false;
      clearInterval(this.pullInterval);
      this.pullInterval = setInterval(() => this.pullThread(false), 2000);
      this.pullThread(true);
    });
  }

  pullThread(showLoading) {
    if (showLoading) {
      this.isLoading = true;
    }
    this._threadsService.threadsIdGet(this.threadId, undefined, ['messages', 'users', 'users.profilePicture']).subscribe(thread => {
      this.thread = thread;
      thread.messages = thread.messages.sort((m1, m2) => new Date(m1.createdAt.toString()).getTime() - new Date(m2.createdAt.toString()).getTime())
      this.messages = thread.messages;
      if (showLoading) {
        this.isLoading = false;
      }
    })
  }

  ngOnInit() {
    this.messageDiffer = this._differs.find(this.messages).create();
  }

  onClickSendMessage(content) {
    const message: Message = <Message>{
      content
    }

    this._threadsService.threadsIdMessagesPost(message, this.threadId).subscribe(response => {
      Object.assign(message, { createdBy: this.currentUser });
      this.messages.push(message);
      this.message = '';
    });
  }

  isOwnMessage(message: Message) {
    return message.createdBy.id === this.currentUser.id;
  }

  getMessageClass(message) {
    if (this.isOwnMessage(message)) {
      return 'message-out';
    }

    return 'message-in';
  }

  getThreadUser(thread: Thread) {
    if (this.thread && this.thread.users) {
      return this.thread.users.find(user => user.id !== this.currentUser.id);
    }
  }

  onKeyUpInput(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.onClickSendMessage(this.message);
    }
  }

  ngDoCheck(): void {
    const changes = this.messageDiffer.diff(this.messages);
    if (changes) {
      this.messagesChanged(changes);
    }
  }

  messagesChanged(changedMessages) {
    this.scrollMessagesToBottom();
  }

  scrollMessagesToBottom() {
    this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight + 100;
  }

  ngAfterViewChecked() {
    if (!this.initialMessageScroll && this.messagesContainer.nativeElement.scrollHeight > this.messagesContainer.nativeElement.clientHeight) {
      this.scrollMessagesToBottom();
      this.initialMessageScroll = true;
    }
  }

  ngOnDestroy() {
    clearInterval(this.pullInterval);
  }

}
