import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { CompaniesService, Company } from '../../swagger-generated';
import { getMediaContentUrl } from '../../services/mediaContent.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogApplyComponent } from '../../components/dialog-apply/dialog-apply.component';
import * as moment from 'moment';


@Component({
  selector: 'app-company-landing-page',
  templateUrl: './company-landing-page.component.html',
  styleUrls: ['./company-landing-page.component.scss']
})
export class CompanyLandingPageComponent implements OnInit {

  company: Company;
  getMediaContentUrl = getMediaContentUrl;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _companiesServices: CompaniesService,
    private _modalService: NgbModal
  ) {
    this._activatedRoute.params.subscribe(q => {
      console.log(q.slug);
      if (q.slug) {
        this._companiesServices.companiesGet(
          undefined, 
          [
            // `slug||eq||${q.slug}`
        ], 
          undefined,
           undefined, 
          ['mediaContent', 'listings']
        ).subscribe(response => {
          this.company = response[0];
          document.title = `Homi: ${this.company.name} ${this.company.address ? this.company.address?.addressLocality : ''} appartments for rent with Roomates | ${this.getDateLabel()}`
        })
      }
      
    });
  }

  onClickApply() {
    const applyModalRef = this._modalService.open(DialogApplyComponent, {
      centered: true
    });
     applyModalRef.componentInstance.listing = this.cheapestListing;
  }

  ngOnInit() {
  }
  
  get cheapestListing() {
    return _.sortBy(this.company.listings, listing => listing.price / listing.beds)[0];
  }

  get smallestPrice() {
    if (this.company && this.company.listings.length) {
     return this.cheapestListing.price / this.cheapestListing.beds;
    }
  }

  getDateLabel() {
    return `${moment().format('MMMM YYYY')} Prices`;
  }

}
