import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TosComponent } from './pages/tos/tos.component';
import { ListingsComponent } from './pages/listings/listings.component';
import { CandidatesComponent } from './pages/candidates/candidates.component';
import { CandidateComponent } from './pages/candidate/candidate.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LogoutComponent } from './components/logout/logout.component';
import { LoginRegisterComponent } from './pages/login-register/login-register.component';
import { AddListingComponent } from './pages/add-listing/add-listing.component';
import { AuthGuard } from './guards/auth.guard';
import { DashboardHomeComponent } from './components/dashboard/home/home.component';
import { DashboardListingsComponent } from './components/dashboard/listings/listings.component';
import { FavoriteDashboardListingsComponent } from './components/dashboard/favorite-listings/favorite-listings.component';
import { EditProfileComponent } from './components/dashboard/edit-profile/edit-profile.component';
import { DashboardAppliedListingsComponent } from './components/dashboard/applied-listings/applied-listings.component';
import { ListingApplicationsComponent } from './pages/listing-applications/listing-applications.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { CompanyComponent } from './pages/company/company.component';
import { ManageCompaniesComponent } from './components/dashboard/manage-companies/manage-companies.component';
import { AnonymousGuard } from './guards/anonymous.guard';
import { ListingPageComponent } from './pages/listing/listing.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { ThreadMessagesComponent } from './components/thread-messages/thread-messages.component';
import { DashboardExternalCompaniesComponent } from './pages/dashboard-external-companies/dashboard-external-companies.component';
import { CompanyLandingPageComponent } from './pages/company-landing-page/company-landing-page.component';
import { FormCompanyComponent } from './modules/forms/components/form-company/form-company.component';
import { UsersComponent } from './components/dashboard/users/users.component';
import { AdminListingApplicationsComponent } from './components/dashboard/admin-listing-applications/admin-listing-applications.component';
import { BrowserModule } from '@angular/platform-browser';

const routes: Routes = [
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'terms',
    component: TosComponent
  },
  {
    path: 'listings/:id',
    component: ListingPageComponent
  },
  {
    path: 'listings/:id/applications',
    component: ListingApplicationsComponent
  },
  {
    path: 'listings',
    component: ListingsComponent
  },
  {
    path: 'companies',
    component: CompaniesComponent
  },
  {
    path: 'companies/:id',
    component: CompanyComponent
  },
  {
    path: 'candidates/:id',
    component: CandidateComponent,
  },
  {
    path: 'listing-candidates/:id',
    component: CandidateComponent,
    data: {
      showFullName: true
    }
  },
  {
    path: 'candidates',
    component: CandidatesComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardHomeComponent
      },
      {
        path: 'listings',
        component: DashboardListingsComponent
      },
      {
        path: 'listings/add',
        component: AddListingComponent,
        data: {
          mode: 'create'
        }
      },
      {
        path: 'listings/:id/edit',
        component: AddListingComponent,
        data: {
          mode: 'edit'
        }
      },
      {
        path: 'favorite-listings',
        component: FavoriteDashboardListingsComponent
      },
      {
        path: 'profile',
        component: EditProfileComponent
      },
      {
        path: 'applied-listings',
        component: DashboardAppliedListingsComponent
      },
      {
        path: 'companies/add',
        component: FormCompanyComponent,
        data: {
          mode: 'create'
        }
      },
      {
        path: 'companies',
        component: ManageCompaniesComponent
      },
      {
        path: 'companies/:id/edit',
        component: FormCompanyComponent,
        data: {
          mode: 'edit'
        }
      },
      {
        path: 'external-companies',
        component: DashboardExternalCompaniesComponent
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'listing-applications',
        component: AdminListingApplicationsComponent
      }
    ]
  },
  {
    path: 'logout',
    canActivate: [AuthGuard],
    component: LogoutComponent
  },
  {
    path: 'login',
    canActivate: [AnonymousGuard],
    component: LoginRegisterComponent,
    data: {
      mode: 'login'
    }
  },
  {
    path: 'register',
    canActivate: [AnonymousGuard],
    component: LoginRegisterComponent,
    data: {
      mode: 'register'
    }
  },
  {
    path: 'forgot-password',
    canActivate: [AnonymousGuard],
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    canActivate: [AnonymousGuard],
    component: ResetPasswordComponent
  },
  {
    path: 'messages',
    canActivate: [AuthGuard],
    component: MessagesComponent,
    children: [
      {
        path: ':id',
        component: ThreadMessagesComponent
      }
    ]
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'roommates/:slug',
    component: CompanyLandingPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), BrowserModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
