export const WORK_HOURS = [
    "BUSINESS_TRIPS",
    "DAY_SHIFT",
    "EVENING_SHIFT",
    "NIGHT_SHIFT",
    "WORK_ON_WEEKENDS",
    "FULL_TIME",
    "PART_TIME",
    "CASUAL"
]
