<div class="details-section mb-3" *ngIf="mediaContents && mediaContents.length">
  <!--- Start carousel  -->
  <div id="mediaContentsGalleryCarousel" class="carousel slide" data-interval="false">
    <div class="carousel-inner">
      <div [ngClass]="(i === 0) ? 'carousel-item active' : 'carousel-item'"
        *ngFor="let sources of videoMediaContentSources; index as i">
        <div class="carousel-item-video">
          <div plyr [plyrSources]="sources" #plyrEl>
          </div>
        </div>
      </div>
      <div [ngClass]="(!videoMediaContentSources.length && i === 0) ? 'carousel-item active' : 'carousel-item'" *ngFor="let mediaContent of imageMediaContents; index as i">
        <div class="carousel-item-image"
          [style.background-image]="'url(' + getMediaContentUrl(mediaContent, 'listing', 'medium') + ')'"></div>
      </div>
      <a *ngIf="mediaContents.length > 1" class="carousel-control-prev" href="#mediaContentsGalleryCarousel"
        role="button" data-slide="prev" (click)="onClickVideoNav()">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a *ngIf="mediaContents.length > 1" class="carousel-control-next" href="#mediaContentsGalleryCarousel"
        role="button" data-slide="next" (click)="onClickVideoNav()">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <div class="gallery-thumbs mt-1">
      <div class="gallery-thumb-item mr-2" data-target="#mediaContentsGalleryCarousel"
        *ngFor="let mediaContent of videoMediaContentSources; index as i" [attr.data-slide-to]="i">
        <div class="video-indicator">
          <img class="logo-color" src="/assets/img/homi_theme/play.png"
          srcset="/assets/img/homi_theme/play@2x.png 2x, /assets/img/homi_theme/play@3x.png 3x">
        </div>
        <div *ngIf="mediaContent[i.toString()]?.provider && mediaContent[i.toString()]?.provider == 'youtube'" class="gallery-thumb-item-video" [style.background-image]="'url(' + getYouTubeVideoThumbnail(mediaContent, i) + ')'">
        </div>
        <div *ngIf="!mediaContent[i.toString()]?.provider || (mediaContent[i.toString()]?.provider && mediaContent[i.toString()]?.provider == 'vimeo')" class="gallery-thumb-item-video">
          <i-feather name="video"></i-feather>
        </div>
      </div>
      <div class="gallery-thumb-item mr-2" data-target="#mediaContentsGalleryCarousel"
        *ngFor="let mediaContent of imageMediaContents; index as i"
        [attr.data-slide-to]="videoMediaContentSources.length + i">
        <div *ngIf="mediaContent.type === 'IMAGE'" class="gallery-thumb-item-image"
          [style.background-image]="'url(' + getMediaContentUrl(mediaContent, 'listing', 'thumb') + ')'"></div>
      </div>
    </div>
    <!--- End carousel  -->
  </div>