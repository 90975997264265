import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChildren } from '@angular/core';
import { MediaContent } from '../../swagger-generated';
import { getMediaContentUrl, getMediaContentVideoSource } from '../../services/mediaContent.service';
import Plyr from 'plyr';
import { PlyrComponent } from 'ngx-plyr';
@Component({
  selector: 'app-media-contents-gallery',
  templateUrl: './media-contents-gallery.component.html',
  styleUrls: ['./media-contents-gallery.component.scss']
})
export class MediaContentsGalleryComponent implements OnChanges {
  @Input('mediaContents') mediaContents: MediaContent[];
  imageMediaContents;
  videoMediaContentSources;
  @ViewChildren(PlyrComponent) plyr: PlyrComponent[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    /**
     * TODO - Optimize checking of new media contents
     * have to check if previous value array is different than previous value array
     * performance aspects involved
     */
    if (changes['mediaContents']) {
      if (
        (changes['mediaContents'].currentValue && 
        changes['mediaContents'].previousValue &&
        changes['mediaContents'].currentValue.length !== changes['mediaContents'].previousValue.length)
        || (
          changes['mediaContents'].currentValue &&
          changes['mediaContents'].currentValue.length &&
          !changes['mediaContents'].previousValue
        )
      ) {
        this.imageMediaContents = this.mediaContents.filter(mediaContent => mediaContent.type === MediaContent.TypeEnum.IMAGE);
        this.videoMediaContentSources = this.mediaContents
          .filter(mediaContent => (mediaContent.type === MediaContent.TypeEnum.VIDEO || mediaContent.type === MediaContent.TypeEnum.VIDEOYOUTUBE || mediaContent.type === MediaContent.TypeEnum.VIDEOVIMEO))
          .map(mediaContent => {
            return [getMediaContentVideoSource(mediaContent)]
          });
      }
    }

  }

  youTubeGetID(url){
    var ID = '';
    url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if(url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    }
    else {
      ID = url[0];
    }
      return ID;
  }

  getYouTubeVideoThumbnail(url, index){
    let thumbSizeUrl = 'http://img.youtube.com/vi/'+this.youTubeGetID(url[index.toString()].src)+'/default.jpg';
    return thumbSizeUrl;
  }

  getMediaContentUrl = getMediaContentUrl;

  getMediaContentVideoSource = getMediaContentVideoSource;

  get navPlyrVideoOptions(): Plyr.Options {
    return {
      controls: [
        'play-large'
      ]
    }
  }

  onClickVideoNav() {
    this.plyr.forEach(plyr => {
      plyr.player.pause();
    });
  }
}
