import { Injectable, Inject, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from '../../environments/environment';
// import { OrganisationInfo } from '../modules/generated-api/lib/generated';

declare var google;
declare var OverlappingMarkerSpiderfier;

@Injectable()
export class GoogleMapsService {
  // googleMapsScriptLoaded: boolean;
  map: any;
  iw: any;
  oms: any; // https://github.com/jawj/OverlappingMarkerSpiderfier
  mapInitialised: boolean;
  apiKey: string;
  markersAdded: Array<any> = [];

  // Script is loaded
  private googleMapsScriptLoaded = new BehaviorSubject<any>(null);
  script$ = this.googleMapsScriptLoaded.asObservable();

  // Selected search
  private selectedPlace = new BehaviorSubject<any>(null);
  search$ = this.selectedPlace.asObservable();

  autocompleteListener: any;

  constructor(public ngZone: NgZone) {
    this.apiKey = environment.googleMapsApiKey;
  }

  init(): any {
    return new Promise((resolve, reject) => {
      this.loadGoogleMapsScript().then(() => {
        this.googleMapsScriptLoaded.next(true);
        resolve(true);
      });
    });
  }

  loadGoogleMapsScript(): any {

    return new Promise((resolve) => {
      if (typeof google === 'undefined' || typeof google.maps === 'undefined') {

        const script = document.createElement('script');

        script.id = 'googleMaps';

        window['scriptIsLoaded'] = () => {
          resolve(true);
        };
        if (this.apiKey) {
          script.src = 'https://maps.google.com/maps/api/js?key=' + this.apiKey + '&libraries=places&callback=scriptIsLoaded&region=EU';
        } else {
          script.src = 'https://maps.google.com/maps/api/js?callback=scriptIsLoaded&region=EU';
        }
        document.body.appendChild(script);
      } else {
        resolve(true);
      }
    });
  }

  initializeMap(elementRef: any, userLocation: any, mapOptions: any): any {
    return new Promise((resolve) => {
      const latLng = new google.maps.LatLng(userLocation.location.latitude, userLocation.location.longitude);
      mapOptions.center = latLng;
      const element = (typeof elementRef === 'string') ? document.getElementById(elementRef) : elementRef;
      this.map = new google.maps.Map(element, mapOptions);
      this.mapInitialised = true;

      this.iw = new google.maps.InfoWindow();

      this.oms = new OverlappingMarkerSpiderfier(this.map, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: true
      });

      resolve(true);
    });
  }

  moveToLocation(location: any): void {
    const center = new google.maps.LatLng(location.latitude, location.longitude);
    this.map.panTo(center);
  }

  initializePlaceSearch(input: any): void {
    const autocomplete = new google.maps.places.Autocomplete(input, { region: 'EU' });
    this.autocompleteListener = google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.ngZone.run(() => {
        const place = autocomplete.getPlace();
        this.selectedPlace.next(place);
      });
    });
  }

  clearSelectedPlace(): any {
    if (this.selectedPlace.value != null) {
      this.selectedPlace.next(null);
    }
  }

  destroyMap(): void {
    this.mapInitialised = undefined;

  }

  addMarker(markerInfo, key: number, clickable: any): void {
    const split = markerInfo.location.split(',');
    const lat = Number(split[0].trim());
    const lng = Number(split[1].trim());
    const latLng = new google.maps.LatLng(lat, lng);
    const markerData = {
      lat: lat,
      lng: lng
    };

    const marker = new google.maps.Marker({
      animation: google.maps.Animation.DROP,
      optimized: false,
      position: latLng,
      title: markerInfo.name,
      clickable: clickable,
      draggable: false,
      label: {
        color: '#FFFFFF',
        text: ' ',
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: '700'
      },
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 15,
        fillColor: '#26A69A',
        fillOpacity: 1.0,
        strokeColor: '#FFFFFF',
        strokeWeight: 2
      }
    });

    google.maps.event.addListener(marker, 'spider_click', event => {  // 'spider_click', not plain 'click'
      this.changeMarkerColor(marker);
    });
    this.oms.addMarker(marker);  // adds the marker to the spiderfier _and_ the map

    // Hack to add label after animation drop
    setTimeout(() => {
      const label = marker.getLabel();
      label.text = key.toString();
      marker.setLabel(label);
    }, 450);

    this.markersAdded.push(marker);
  }

  addMarkerLabels(): void {
    this.markersAdded.map((marker, key) => {
      const label = marker.getLabel();
      label.text = key.toString();
      marker.setLabel(label);
    });
  }

  changeMarkerColor(marker: any): void {
    this.restoreMarkerColors();
    const label = marker.getLabel();
    label.color = '#26A69A';
    const icon = marker.getIcon();
    icon.fillColor = '#FFFFFF';
    marker.setLabel(label);
    marker.setIcon(icon);

  }
  restoreMarkerColors(): void {
    this.markersAdded.map(marker => {
      const label = marker.getLabel();
      label.color = '#FFFFFF';
      const icon = marker.getIcon();
      icon.fillColor = '#26A69A';
      marker.setLabel(label);
      marker.setIcon(icon);
    });
  }
  removeMarkers(): void {
    if (this.markersAdded.length > 0) {
      this.markersAdded.map(marker => {
        marker.setMap(null);
      });
    }
    this.markersAdded = [];
  }
}
