<div class="container">
  <div class="row m-0">
    <div class="col-lg-8 p-0">
      <div class="listing-header py-4">
        <div class="media">
          <div class="mr-3 img-fluid thumb" [style.background-image]="'url(' + companyLogoUrl + ')'"></div>
          <div class="media-body">
            <div class="listing-title">
              <h4 class="editable-item info-title">{{company?.name}} <a *ngIf="canEdit" class="edit"
                  [routerLink]="'/dashboard/companies/' + company?.id + '/edit'">
                  <i-feather class="edit-icon" name="edit2"></i-feather>
                </a>
              </h4>
            </div>
            <div class="text-muted">{{company?.slogan}}</div>
          </div>
        </div>
      </div>
      <div class="listing-info p-3">
        <app-media-contents-gallery [mediaContents]="mediaContents"></app-media-contents-gallery>

        <div class="description details-section" *ngIf="company?.description">
          <h4 class="editable-item info-title">Company Description <a *ngIf="canEdit" class="edit"
              [routerLink]="'/dashboard/companies/' + company?.id + '/edit'">
              <i-feather class="edit-icon" name="edit2"></i-feather>
            </a>
          </h4>
          <p class="info-detail" [innerHtml]="company?.description"></p>
        </div>

        <div class="description details-section" *ngIf="listings && listings.length">
          <h4 class="info-title">Available listings</h4>
          <div class="listing-item" *ngFor="let listing of listings">
            <app-listing-item [listing]="listing"></app-listing-item>
            <hr>
          </div>
        </div>

      </div>
    </div>

    <div class="col-lg-4 listing-details-sidebar p-0">
      <div class="listing-additional-details px-3 py-4">
        <h4 class="info-title">Company Summary</h4>
        <div class class="listing-summary-details">

          <div *ngIf="company?.email" class="listing-detail mb-3">
            <h6 class="mb-0">Email</h6>
            {{company?.email}}
          </div>

          <div *ngIf="company?.slug" class="listing-detail mb-3">
            <h6 class="mb-0">Landing page</h6>
            <a [routerLink]="'/roommates/' + company?.slug" target="_blank">https://homi.house/roommates/{{company?.slug}}</a>
          </div>

          <div *ngIf="company?.phone" class="listing-detail mb-3">
            <h6 class="mb-0">Phone</h6>
            {{company?.phone}}
          </div>

          <div *ngIf="company?.website" class="listing-detail mb-3">
            <h6 class="mb-0">Website</h6>
            <a href="//{{company?.website}}" target="_blank">{{company?.website}}</a>
          </div>

        </div>
        <h4 class="info-title mt-4">Company Location</h4>
        <div class="info-detail mb-2">{{company?.address?.formattedAddress}}</div>
        <div id="map-area" *ngIf="company?.address?.geolocation">
          <img class="img-fluid" [src]="googleMapsImage" />
        </div>
      </div>
    </div>
  </div>
</div>