<footer id="app-footer" class="container-fluid">
  <div class="row footer-layout">
    <div class="social-media-blocks col-md-4"><a href="https://www.facebook.com/homiapp/" target="_blank"><img
          src="/assets/img/homi/social_FB.png" srcset="/assets/img/homi/social_FB@2x.png 2x"></a><a
        href="https://twitter.com/homiapp"><img src="/assets/img/homi/social_TW.png"
          srcset="/assets/img/homi/social_TW@2x.png 2x"></a><a href="https://www.instagram.com/homi.house/"><img
          src="/assets/img/homi/social_insta.png" srcset="/assets/img/homi/social_insta@2x.png 2x"></a><br><br><span>71
        Commercial Street, Suite 10, Boston MA, 02109</span>
    </div>
    <div class="col-md-4 text-center"><img src="/assets/img/homi/LOGO_circle.png"
        srcset="/assets/img/homi/LOGO_circle@2x.png 2x" class="homi-logo-white"></div>
    <div class="col-md-4 hidden-xs">
      <ul class="footer-items-list">
        <li><span>®Homi {{currentYear}}. All rights reserved.</span></li>
        <li><a href="#terms">Terms and Conditions</a></li>
        <li><a routerLink="contact">Contact us</a></li>
        <li><a href="#privacy">Privacy Policy</a></li>
      </ul>
    </div>
    <img src="/assets/img/homi/play_video_hover.png" class="hidden">
  </div>
</footer>