import { Component, OnInit, HostBinding, Renderer2, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ListingsService, Listing, User, GetListingFilterResponse, Address } from 'src/app/modules/generated-api.module';
import { GetManyDefaultResponse } from 'src/app/shared/models/paged-responses';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'src/app/services/session.service';
import * as _ from "lodash";
import { SHARED } from '../../shared';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ListingsSearchPayload } from '../../interfaces/listings-search-payload';

declare const google;
declare const jQuery;

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.scss']
})
export class ListingsComponent implements OnInit, OnDestroy {

  @ViewChild('listingModal') listingModal: ElementRef;
  pagedResponse: GetManyDefaultResponse<Listing>;
  selectedFilterOptions: any[] = [];
  isLoading = true;
  searchQuery = '';
  perPage = 9999;
  sortOrder = 'MOST_RECENT';
  autocompleteListener;
  @ViewChild('inputAddress') inputAddress: ElementRef;
  @ViewChild('rangeInput') rangeInput: ElementRef;
  errorMessage = null;
  listingFilterOptions: any;
  urlFilter;
  SHARED = SHARED;
  currentlySelectedListing: Listing;
  minimumSalaryFilter = 200;
  sortNearAddress: Address;
  viewMode = 'grid';

  constructor(
    private listingsService: ListingsService,
    public translate: TranslateService,
    private _sessionService: SessionService,
    private _changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {
    this.activatedRoute.queryParams.subscribe(q => {
      if (q.filter) {
        if (typeof q.filter === 'string') {
          const filterData = this.handleQueryFilter(q.filter);
          this.addFilter(filterData[0], filterData[2], filterData[1], filterData[3]);
        } else {
          q.filter.forEach(filter => {
            const filterData = this.handleQueryFilter(filter);
            this.addFilter(filterData[0], filterData[2], filterData[1], filterData[3]);
          });
        }
      }
    });
    this.viewMode = localStorage.getItem('listingsViewMode') || 'list';
  }

  handleQueryFilter(filter) {
    const filterData = filter.split('||');
    if (filterData[0] === 'distance') {
      const address: Address = JSON.parse(filterData[2]);
      this.sortNearAddress = address;
      this.sortOrder = 'NEAR_ADDRESS';
      return [filterData[0], filterData[1], `${address.geolocation.lat},${address.geolocation.lng}`, address.formattedAddress];
    } else if (filterData[0] === 'title') {
      return [filterData[0], filterData[1], filterData[2], `"${filterData[2]}"`];
    }
    return filterData;
  }

  ngOnInit() {
    this.listingsService.listingsFiltersGet().subscribe(filterOptions => {
      this.listingFilterOptions = filterOptions;
    })
    this._updateResults();
  }

  onChangeMinimumSalary(ev) {
    const minimumSalary = ev.currentTarget.value;
    this.removeFilter('price', null, false);
    this.addFilter('price', minimumSalary, 'gt', this.translate.instant('filterValues.price', { value: minimumSalary + '€ / mo' }), true);
  }

  addFilter(filterName: string, filterValue: any, filterOperator = 'eq', filterLabel?: string, sync = true) {
    if (filterName) {
      this.selectedFilterOptions.push({
        filterName,
        filterValue,
        filterType: filterOperator,
        label: filterLabel || this.getFilterLabel(filterName, filterValue)
      });
      if (sync) {
        this._updateResults();
      }
    }
  }

  toggleFilter(event, filterName: string, filterValue: any, filterOperator = 'eq', filterLabel?: string, sync = true) {
    if (event.target.checked) {
      this.addFilter(filterName, filterValue, filterOperator, filterLabel, sync);
    } else {
      this.removeFilter(filterName, filterValue);
    }
  }

  getFilterLabel(filterName: string, filterValue: string) {
    return this.translate.instant(`filterValues.${filterName}.${filterValue}`);
  }

  async _updateResults() {
    this.errorMessage = null;
    this.isLoading = true;
    window.scrollTo(0, 0);

    const joinQueryString = [
      'usersFavorited',
      'listingApplications',
      'listingApplications.user',
      'address',
      'owner',
      'owner.mediaContent',
      'price',
      'requiredLanguages',
      'requiredLanguages.language',
      'companyLabel',
      'companyLabel.companyLogo',
      'photos',
      'mediaContent',
      'thumbnail'
    ]

    const sortString = await this.getSort();

    // Tweak - isLoaded change is not detected
    this._changeDetectorRef.detectChanges();

    this.listingsService.listingsGet(undefined, this.getFilter(), this.getOrFilter(), sortString, joinQueryString, this.perPage, 0, 0)
      .subscribe(
        response => {
          this.isLoading = false;
          this.pagedResponse = <GetManyDefaultResponse<Listing>><unknown>response;
          // Tweak - isLoaded change is not detected
          this._changeDetectorRef.detectChanges();
        },
        err => {
          this.isLoading = false;
          this.errorMessage = err.message;
          // Tweak - isLoaded change is not detected
          this._changeDetectorRef.detectChanges();
        })
  }

  getSort(): Promise<[string]> {
    return new Promise(async (resolve, reject) => {
      switch (this.sortOrder) {
        case 'MOST_RECENT': {
          resolve(['createdAt,DESC']);
          break;
        };
        case 'NEARBY': {
          const currentLocation = await this._sessionService.getCurrentLocation();
          if (currentLocation) {
            resolve([`distance[${currentLocation.coords.latitude}|${currentLocation.coords.longitude}],ASC`]);
          }
          break;
        };
        case 'NEAR_ADDRESS': {
          if (this.sortNearAddress.geolocation.lat) {
            resolve([`distance[${this.sortNearAddress.geolocation.lat}|${this.sortNearAddress.geolocation.lng}],ASC`]);
          } else {
            reject(`No address selected`);
            console.error('No address selected');
          }
          break;
        }
        case 'HIGHEST_SALARY': {
          resolve(['price,DESC']);
          break;
        }
        default: {
          reject('NO_SORT_OPTION');
          console.error('Undefined sort option');
          break;
        }
      }
    });
  }

  getFilter() {
    const filterValues = this.selectedFilterOptions.map((filterOptions, index) => {
      return `${filterOptions.filterName}||${filterOptions.filterType}||${filterOptions.filterValue}`;
    });

    return [...filterValues, 'status||ne||CLOSED'];
  }

  getOrFilter() {
    if (this.searchQuery) {
      return [`title||cont||${this.searchQuery}`, `description||cont||${this.searchQuery}`];
    }
    return [];
  }

  removeFilter(filterName?: string, filterValue?: any, sync = true) {
    if (!filterName && !filterValue) {
      this.selectedFilterOptions = [];
    } else if (!filterValue) {
      this.selectedFilterOptions = this.selectedFilterOptions.filter(filter => {
        return filter.filterName !== filterName;
      });
    } else {
      this.selectedFilterOptions = this.selectedFilterOptions.filter(filter => {
        return filter.filterName !== filterName || filter.filterValue !== filterValue;
      });
    }

    if (sync) {
      this._updateResults();
    }
  }

  onKeyDownSearch(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      this._updateResults();
    }
  }

  isSelectedFilter(filterName?: string, filterValue?: any) {
    return !!this.selectedFilterOptions.find(filter => {
      return filter.filterName === filterName && filter.filterValue === filterValue;
    });
  }

  onChangeListingSort($event) {
    this._updateResults();
  }

  initializeAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.inputAddress.nativeElement);
    this.autocompleteListener = google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.removeFilter('distance');
      const place = autocomplete.getPlace();
      if (place.name) {
        const location = place.geometry.location;
        this.addFilter('distance', `${location.lat()},${location.lng()}`, 'eq', place.formatted_address);
      }
    })
  }

  onClickListingTitle(listing: Listing) {
    this.currentlySelectedListing = listing;
    jQuery(this.listingModal.nativeElement).modal().on('hidden.bs.modal', () => {
      this.currentlySelectedListing = null;
      this.location.replaceState(`/listings`);
    });
    this.location.replaceState(`/listings/${listing.id}`);
  }

  onDismissModal() {
    jQuery(this.listingModal.nativeElement).modal('hide');
  }

  ngOnDestroy() {
    this.onDismissModal();
  }

  onSearch(payload: ListingsSearchPayload) {
    this.removeFilter('title');
    this.removeFilter('distance');
    if (payload.query) {
      this.addFilter('title', payload.query, 'cont', `"${payload.query}"`);
    }

    if (payload.address.geolocation) {
      this.sortNearAddress = payload.address;
      this.sortOrder = 'NEAR_ADDRESS';
      this.addFilterNearAddress(payload.address);
    } else {
      this.sortOrder = 'MOST_RECENT';
      this.sortNearAddress = undefined;
    }
    this._updateResults();
  }

  addFilterNearAddress(address: Address) {
    this.removeFilter('distance');
    this.addFilter('distance', `${address.geolocation.lat},${address.geolocation.lng}`, 'eq', address.formattedAddress);
  }

  get sortNearAddressLabel() {
    if (this.sortNearAddress.formattedAddress.length > 20) {
      return this.sortNearAddress.formattedAddress.substr(0, 20) + '...';
    }

    return this.sortNearAddress.formattedAddress;
  }

  onClickChangeViewMode(viewMode: string) {
    this.viewMode = viewMode;
    localStorage.setItem('listingsViewMode', viewMode);
  }
}
