import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormComponent } from 'ngx-schema-form';
import { SwaggerDocOptimizerService } from 'ngx-swag-form';

@Component({
  templateUrl: './form-generic.component.html'
})
export class FormGenericComponent implements OnInit {
  data: any = {};
  isLoading = false;
  schema;
  saved;
  @ViewChild('form') form;
  definitionName;
  widgetName;

  constructor(
    public activatedRoute: ActivatedRoute,
    public swaggerDocOptimizerService: SwaggerDocOptimizerService,
  ) {
    this.schema = this.swaggerDocOptimizerService.getOptimizedSchema(this.definitionName);
    this.schema.widget = this.widgetName || this.schema.widget || 'object';
    this.activatedRoute.params.subscribe(q => {
      if (q.id) {
        this.data.id = q.id;
      }
    });
  }

  get actions() {
    return {
      save: () => {
        if (this.data.id) {
          this.updateData().then(data => {
            this.handleUpdatedData(data);
          })
        } else {
          this.saveData().then(data => {
            this.handleSavedData(data);
          })
        }
      },
      delete: () => {
        this.deleteData().then(data => {
          this.handleDeletedData(data);
        })
      }
    }
  }

  get payload() {
    return this.data;
  }

  ngOnInit() {
    this.addButtons();
    this._fetchData();
  }

  addButtons() {
    Object.assign(this.schema, {
      buttons: [{
        id: 'save',
        label: 'Save'
      },
      {
        id: 'delete',
        label: 'Delete'
      }]
    })
  }

  public pullData() {
    console.log('Fetch server data');
  }

  /**
   * This function defines how data is being pulled from the server if entity exists
   */
  public _fetchData(){
    if (this.data.id) {
      this.pullData();
    }
  };

  public saveData(){
    return new Promise((resolve, reject)=>{
      resolve('saved')
    });
  }

  public updateData(){
    return new Promise((resolve, reject)=>{
      resolve('updated')
    });
  }

  public deleteData(){
    return new Promise((resolve, reject)=>{
      resolve('deleted')
    });
  }

  public handleSavedData(data) {
    console.log('Saved data');
  }

  public handleUpdatedData(data) {
    console.log('Updated data');
  }

  public handleDeletedData(data) {
    console.log('Data deleted');
  }
}